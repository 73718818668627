@import "../sprite";
@import "../misc/bootstrap";

body.coreSiteMode {
	.slider {
		position: relative;
		overflow: hidden;
		left: 50%;
		width: 100vw;
		transform: translateX(-50%);
		margin: 4em 0;
		opacity: 0;
		transition: opaity .1s;
	}
	
	.slider_visible {
		opacity: 1;
	}

	.slider-content {
		position: relative;
		left: 50%;
		width: 100vw;
	}

	.slider-slide {
		position: relative;
		left: 0;
		width: 1000%;
		overflow: hidden;
	}
	
	.slider-item {
		display: none;
		float: left;
		padding: 0 55px;
		max-width: 80vw;

		@include media-max(md) {
			max-width: 90vw;
			padding: 0 30px;
		}
		
		@include media-max(sm) {
			max-width: 100vw;
			padding: 0 10px;
		}
		
		&:nth-of-type(1),
		&:nth-of-type(2),
		&:nth-of-type(3),
		&:nth-of-type(4),
		&:nth-of-type(5) {
			display: block;
		}
	}
	
	.slider-ctrl {
		position: absolute;
		left: 50%;
		top: 0;
		height: 100%;
		transform: translateX(-50%);
	}

	.slider-left, .slider-right {
		position: absolute;
		top: 0;
		border: none;
		background: none;
		cursor: pointer;
		//background: red;
		height: 100%;
		width: 100%;
		
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			@include sprite($arrow-gray)
		}
	}
	
	.slider-left {
		&:after {
			right: 20px;
			transform: translateY(-50%) rotate(180deg);
		}
		left: 40px;
		transform: translateX(-100%);
		@include media-max(sm) {
			left: 60px;	
		}
	}
	
	.slider-right {
		&:after {
			left: 20px;
			transform: translateY(-50%);
		}
		right: 40px;
		transform: translateX(100%);
		@include media-max(sm) {
			right: 60px;	
		}
	}
	
}


body.coreEditorMode {
	.slider {
	
	}

	.slider-content {
		width: 100%;
		overflow: auto;
		white-space: nowrap;
	}

	.slider-item {
		display: inline-block;
		width: 400px;
		img {
			max-width: 100%;
		}
		
		+.slider-item {
			margin-left: 10px;
		}
	}
}