@import "../misc/bootstrap";

.articleGrid {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 80px;

	&:after {
		content: '';
		height: 0;
		width: 32%;
		display: block;
	}

	@include media-max(md) {
		&:after {
			width: 49%;
		}
	}

	@include media-max(sm) {
		&:after {
			width: 100%;
		}
	}
}

.articleGrid-item {
	width: 32%;
	margin-bottom: 70px;
	text-align: center;

	a {
		text-decoration: none;
	}

	@include media-max(md) {
		width: 49%;
	}

	@include media-max(sm) {
		width: 100%;
	}

	@media (pointer: fine) {
		transition: .12s;
		transform: scale(1) translateY(0);
		&:hover {
			transform: scale(1.05) translateY(-2.5%);
		}
		
		img {
			box-shadow: 0 0 0 rgba(#000, 0);
			transition: .12s;
		}
		
		&:hover {
			img {
				box-shadow: 0 5px 10px rgba(#000, .3);
			}	
		}
	}
}

.articleGrid-header {
	width: 100%;
}


.articleGrid-img {
	position: relative;

	img {
		max-width: 100%;
	}
}

.articleGrid-imgTitle {
	position: absolute;
	width: 92%;
	max-width: 190px;
	text-align: center;
	left: 50%;
	transform: translateX(-50%);
	bottom: 47px;
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 26px / 16px * 1rem;
	line-height: 30px / 16px * 1rem;
	text-decoration-line: underline;
	color: #ffffff;
}

.articleGrid-annotation {
	display: block;
	max-width: 245px;
	margin: 0 auto;

	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px / 16px * 1rem;
	line-height: 20px / 16px * 1rem;
	text-align: center;
	color: #808080;
	padding: 18px 0 5px;

	+ .articleGrid-title {
		padding-top: 0;
	}
}

.articleGrid-title {
	display: block;
	color: #6a6a6a;
	font-size: 26px / 16px * 1rem;
	line-height: 30px / 16px * 1rem;
	padding: 18px 0 15px;
}

.articleGrid-squareFrame {
	position: relative;
	padding-top: 100%;
	text-align: left;
}

.articleGrid-squareFrame-content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	background: #ffffff;
	box-shadow: 0 0 40px rgba(#000, .1);
	padding: 23px 42px;
	display: flex;
	align-items: center;

	:first-child {
		margin-top: 0;
	}

	:last-child {
		margin-bottom: 0;
	}
}


.articleGrid-btn {

}