.narrowBlock-0 {
	max-width: 480px;
}

.narrowBlock-1 {
	max-width: 580px;
}

.narrowBlock-2 {
	max-width: 780px;
}

.narrowBlock-3 {
	max-width: 980px;
}