@import "../misc/bootstrap";

.photoGalleryList {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 80px;
	
	&:after {
		content: '';
		height: 0;
		width: 32%;
		display: block;
	}

	@include media-max(md) {
		&:after {
			width: 49%;
		}
	}

	@include media-max(sm) {
		&:after {
			width: 100%;
		}
	}
}

.photoGalleryList-item {
	width: 32%;

	@include media-max(md) {
		width: 49%;
	}

	@include media-max(sm) {
		width: 100%;
	}

	@media (pointer: fine) {
		transition: .12s;
		transform: scale(1) translateY(0);
		&:hover {
			transform: scale(1.05) translateY(-2.5%);
		}

		img {
			box-shadow: 0 0 0 rgba(#000, 0);
			transition: .12s;
		}

		&:hover {
			img {
				box-shadow: 0 5px 10px rgba(#000, .3);
			}
		}
	}
}

.photoGalleryList-img {
	img {
		max-width: 100%;
	}
	a {
		text-decoration: none;
	}
}

.photoGalleryList-title {
	text-align: center;
	display: block;
	color: #6A6A6A;
	font-size: 26px;
	line-height: 30px;
	padding: 18px 0 61px;
}
