@import "../misc/bootstrap";
@import "../sprite";

.popupForm {
	padding: 10px;
	position: relative;

	input {
		//pointer-events: none;
	}

	.h1 {
		line-height: 1.3;
	}
}

.popupForm-form {
	margin-top: 56px;
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
}

.popup .popupForm {
	overflow: auto;
	max-height: 100vh;
}

.popupForm_busy {
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		//background: rgba(#fff, .8) url('/img/loader.gif') center center no-repeat;
	}
}


.popupForm-title {
	margin: 65px / 16px * 1rem auto 19px / 16px * 1rem;
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 42px / 16px * 1rem;
	line-height: 54px / 16px * 1rem;
	text-align: center;
	color: #2B2B2B;
}

.popupForm-text {
	font-family: 'Open Sans', sans-serif;
	font-size: 19px / 16px * 1rem;
	line-height: 28px / 16px * 1rem;
	text-align: center;
	color: #2B2B2B;
}


.popupForm-field {
	display: block;
	max-width: 440px;
	margin: 0 auto 19px;
	position: relative;
}

div.popupForm-field {
	text-align: center;
	padding-top: 11px;
}

.popupForm-checkbox {
	* {
		color: #333 !important;
	}
}

.popupForm-label {
	position: absolute;
	left: -12px;
	top: 12px;
	transform: translateX(-100%);
	color: #999999;
	font-size: 16px;

	@include media-max(md) {
		position: static;
	}

}

.popupForm-input {
	.input {
		color: #EEEDED;
	}
}

.popupForm-urgent {
	.popupForm-label,
	.input-label {
		&:after {
			content: ' *'
		}
	}
}


.popupForm-error {
	.popupForm-input, .checkbox-content::before {
		border: 1px solid red;
		animation: {
			name: shake;
			duration: .1s;
			iteration-count: 3;
		}
	}
}

.popupForm-state_success {
	display: none;
	padding: 20px 0 60px;

	i {
		margin-bottom: 10px;
	}

	.popupForm-title {
		font-weight: 300;
		line-height: 1.6;
		text-transform: none;
	}
}


.popupForm-footer {
	font-size: 13px;
	color: #999999;
	text-align: center;
	margin: 51px 0 18px;

	a {
		color: inherit
	}
}
