@import "../sprite";

.blockIcon {
	position: relative;
	display: flex;
	align-items: center;

	&:before {
		content: '';
		width: 100px;
		height: 100px;
		display: block;
		margin-right: 20px;
		min-width: 100px;
		background: #a0d185;
		border-radius: 50%;
	}

	&:after {
		position: absolute;
		content: '';
		z-index: 1;
		left: 50px;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
}

.blockIcon_violet {
	&:before {
		background: #787C9B;
	}
}	

.blockIcon-bed {
	@extend .blockIcon;

	&:after {
		@include retina-sprite($bed-group);
	}
}

.blockIcon-tableware {
	@extend .blockIcon;

	&:after {
		@include retina-sprite($tableware-group);
	}
}

.blockIcon-poll,
.blockIcon-pool {
	@extend .blockIcon;

	&:after {
		@include retina-sprite($poll-group);
	}
}

.blockIcon-zen {
	@extend .blockIcon;

	&:after {
		@include retina-sprite($zen-group);
	}
}

.blockIcon-medal {
	@extend .blockIcon;

	&:after {
		@include retina-sprite($medal-group);
	}
}

.blockIcon-wifi {
	@extend .blockIcon;

	&:after {
		@include retina-sprite($wifi-group);
	}
}

.blockIcon-gym {
	@extend .blockIcon;

	&:after {
		@include retina-sprite($gym-group);
	}
}
