@import "../sprite";

.spoiler {
		
}

.spoiler-ctrl {
	position: relative;
	display: inline-block;
	font-family: 'Open Sans', sans-serif;
	font-size: 19px / 16px * 1rem;
	line-height: 28px / 16px * 1rem;
	color: #6584A4;
	cursor: pointer;
	&:after {
		content: '';
		position: absolute;
		right: -15px;
		top: 50%;
		@include retina-sprite($drop-arrow-group);
	}
	
	span {
		
		&:nth-of-type(1) {
	
		}
		&:nth-of-type(2) {
			display: none;
		}
	}
}

.spoiler-content {
	display: none;
	grid-row: 2;
	padding: 0 15px;
	margin-top: 20px;
}

body.coreEditorMode {
	.spoiler-content {
		display: block;
		border: 1px solid red;
	}
}

.spoiler-ctrl_open {
	&:after {
		transform: rotate(180deg);
	}
	span {
		&:nth-of-type(1) {
			display: none;
		}
		&:nth-of-type(2) {
			display: block;
		}
	}
} 