@import "../sprite";

.btn1 {
	color: #fff;
	background: #7DB260;
	border-radius: 30px;
	height: 44px;
	min-height: 44px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	text-decoration: none;
	padding: 0 27px;
	letter-spacing: .01em;
	font-family: 'Roboto Condensed', sans-serif;
	border: none;

	@media (pointer: fine) {
		transition: .1s;
		&:hover {
			background: #a9cf8d;
		}
	}
}

.btn1_violet {
	background: #5E6280;
	@media (pointer: fine) {
		&:hover {
			background: #8a8ca2;
		}
	}
}

.btn1_white {
	background: #fff;
	color: #808080 !important;
	@media (pointer: fine) {
		&:hover {
			background: #E5E5E5;
			color: #333 !important;
		}
	}
}

.btn1_whiteBorder {
	background: transparent;
	border: 1px solid #fff;
	color: #fff !important;
	@media (pointer: fine) {
		&:hover {
			background: transparent;
			color: #333 !important;
		}
	}
}

.btn1_blue {
	background: #6584A4;
	@media (pointer: fine) {
		&:hover {
			background: #8fa5bd;
		}
	}
}

.btn1_red {
	background: #E26360;
	@media (pointer: fine) {
		&:hover {
			background: #ea8d8b;
		}
	}
}

.btn1_gray {
	background: #BFBFBF;
	@media (pointer: fine) {
		&:hover {
			background: #d0d0d0;
		}
	}
}

.btn1_bell {
	padding-left: 18px;
	&:before {
		margin: 0 15px 0 0;
		content: '';
		@include retina-sprite($bell-group);
		transition: .1s;
	}
	
	@media (pointer: fine) {
		&:hover {
			&:before {
				transform: rotate(33deg);
			}
		}
	}
}

.btn1_whiteStar {
	padding-left: 18px;
	&:before {
		margin: 0 5px 0 0;
		content: '';
		@include retina-sprite($white-star-group);
		transform: scale(.70);
	}
}

.btn1_whitePrice {
	padding-left: 18px;
	&:before {
		margin: 0 5px 0 0;
		content: '';
		@include retina-sprite($white-price-group);
		transform: scale(.70);
	}
}

.btn1_grill {
	padding-left: 19px;
	&:before {
		margin: 0 7px 0 0;
		content: '';
		@include retina-sprite($grill-group);
		transform: scale(.70);
	}
}

.btn1_back {
	padding-left: 18px;
	&:before {
		margin: 0 15px 0 0;
		content: '';
		@include retina-sprite($back-arrow-group);
	}
}