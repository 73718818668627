@import "../sprite";

.blockIcon2 {
	position: relative;
	display: flex;
	align-items: center;

	&:before {
		content: '';
		width: 70px;
		height: 70px;
		display: block;
		margin-right: 20px;
		min-width: 70px;
		background: #787C9B;
		border-radius: 50%;
	}

	&:after {
		position: absolute;
		content: '';
		z-index: 1;
		left: 35px;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
}

.blockIcon2-bed {
	@extend .blockIcon2;

	&:after {
		@include retina-sprite($bed-group);
		transform: translateX(-50%) translateY(-50%) scale(.7);
	}
}

.blockIcon2-tableware {
	@extend .blockIcon2;

	&:after {
		@include retina-sprite($tableware-group);
		transform: translateX(-50%) translateY(-50%) scale(.7);
	}
}

.blockIcon2-poll,
.blockIcon2-pool {
	@extend .blockIcon2;

	&:after {
		@include retina-sprite($poll-group);
		transform: translateX(-50%) translateY(-50%) scale(.7);
	}
}

.blockIcon2-zen {
	@extend .blockIcon2;

	&:after {
		@include retina-sprite($zen-group);
	}
}

.blockIcon2-medal {
	@extend .blockIcon2;

	&:after {
		@include retina-sprite($medal-group);
		transform: translateX(-50%) translateY(-50%) scale(.7);
	}
}

.blockIcon2-wifi {
	@extend .blockIcon2;

	&:after {
		@include retina-sprite($wifi-group);
	}
}

.blockIcon2-gym {
	@extend .blockIcon2;

	&:after {
		@include retina-sprite($gym-group);
	}
}
