.event {
	p {
		font-size: 16px / 16px * 1rem;
		line-height: 26px / 16px * 1rem;
	}
}

.event-date {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px / 16px * 1rem;
	line-height: 20px / 16px * 1rem;
	color: #7DB260;
	text-transform: uppercase;
}