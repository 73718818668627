@import "../misc/bootstrap";
@import "../sprite";

.slider3 {
	background: rgba(#000, .2);
	backdrop-filter: blur(20px);
	max-width: 580px;
	width: 100%;
	height: 83%;

	:first-child {
		margin-top: 0;
	}
	
	:last-child {
		margin-bottom: 0;
	}
	
	h2, p {
		color: #fff;
	}
	
	@include media-max(md) {
		height: auto;
	}
}

.slider3-content {
	padding: 0 70px;
	display: flex;
	justify-content: center;
	height: 100%;
	flex-direction: column;

	@include media-max(sm) {
		padding: 0 20px;
	}
}

.slider3-item {
	display: none;
}

.slider3-text {
	padding-bottom: 52px;
}

.slider3-ctrl {
	position: absolute;
	z-index: 1;
	left: 0;
	right: 0;
	bottom: 10px;
	text-align: center;
}

.slider3-btn {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 0 7px;
	
	&:after {
		content: '';
		position: absolute;
		width: 12px;
		height: 12px;
		left: 50%;
		top: 50%;
		border-radius: 50%;
		background: rgba(#fff, .3);
		transform: translateX(-50%) translateY(-50%);
		transition: .2s;
	}
	
	&.slider3-btn_current {
		&:after {
			width: 20px;
			height: 20px;
			background: rgba(#fff, 1);
		}
	}
}

.slider3-right {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateX(50%) translateY(-50%);
	width: 110px;
	height: 110px;
	background: rgba(#fff, 1);
	border-radius: 50%;

	@include media-max(md) {
		transform: translateY(-50%) scale(.5);
		transform-origin: right center;
	}
	
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		@include retina-sprite($arrow-gray-group);
	}
}








