.colorSquare {
	width: 20px;
	height: 20px;
}

.colorSquare_blue {
	background: #6584A4;
}

.colorSquare_green {
	background: #C3D6B1;
}

.colorSquare_red {
	background: #F08D8B;
}

.colorSquare_brown {
	background: #94877D;
}
