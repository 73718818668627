@import "../misc/bootstrap";

.breadCrumbs {
	margin: 33px 0 41px;

	@include media-max(sm) {
		margin-bottom: 20px;
	}
	
	a {
		display: inline-block;
		color: #808080;
		text-decoration: none;
		font-family: 'Open Sans', sans-serif;
		font-size: 16px;

		@include media-max(sm) {
			font-size: 12px;
		}
		
		+a {
			margin-left: 47px;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				width: 26px;
				height: 1px;
				background: #808080;
				left: -39px;
				top: 50%;
			}
			
			@include media-max(sm) {
				margin-left: 16px;
				&:before {
					width: 4px;
					left: -11px;
					transform: translateY(-50%);
					height: 4px;
					border-radius: 2px;
				}
			}
		}
	}
	
	a:last-child {
		color: #2b2b2b;
	}
}