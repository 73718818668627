@import "../misc/bootstrap";

.numSquare {
	display: inline-block;
	width: 200px;
	height: 200px;
	position: relative;
	overflow: hidden;

	@include media-max(sm) {
		display: none;
	}
}

.numSquare_blue {
	background: #a7c0da;
}

.numSquare_green {
	background: #d5e8c4;
}

.numSquare_red {
	background: #f8c6c5;
}

.numSquare-content {
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	font-size: 130px / 16px * 1rem;
	color: #fff;
	position: absolute;
	right: -16px;
	bottom: 19px;
}
