@import "../sprite";
@import "../misc/bootstrap";

.slider6 {
	position: relative;
}

.slider6-content {
	overflow: hidden;
}

.slider6-car {
	width: 10000%;
	overflow: hidden;
}

.slider6-item {
	width: 1%;
	float: left;
}

.slider6-left, .slider6-right {
	position: absolute;
	top: 0;
	border: none;
	background: none;
	cursor: pointer;
	height: 100%;
	width: 62px;
	z-index: 2;

	@include media-max(md) {
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 80px;
			height: 80px;
			border-radius: 50%;
			background: rgba(#000, .2);
		}
	}
	
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		@include retina-sprite($arrow-white-group);
	}

	@include media-max(sm) {
		transform: scale(.8);
	}
}

.slider6-left {
	&:after {
		transform: translateX(-50%) translateY(-50%) rotate(180deg);
	}
	left: -80px;

	@include media-max(xl) {
		left: -52px;
	}

	@include media-max(md) {
		left: -33px;
	}
}

.slider6-right {
	&:after {
		transform: translateX(-50%) translateY(-50%);
	}
	right: -80px;

	@include media-max(xl) {
		right: -52px;
	}

	@include media-max(md) {
		right: -33px;
	}
}