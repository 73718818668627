.menuCtrl {
	width: 52px;
	height: 52px;
	//background: #FFFFFF;
	border: 1px solid rgba(#E26360, .5);
	position: relative;
	border-radius: 50%;
	
	span {
		width: 19px;
		height: 2px;
		position: absolute;
		left: 50%;
		top: 50%;
		background: rgba(#E26360, .5);
		transform: translateX(-50%) rotate(0);
		transition: background-color .2s, opacity .2s, top .1s  .1s ease-out, margin-top .1s .1s ease-out, transform .1s ease-out;

		&:nth-child(1) {
			top: 32%;	
		}
		
		&:nth-child(2) {
			width: 25px;
			margin-top: -1px;
			margin-left: 3px;
			opacity: 1;
		}
		
		&:nth-child(3) {
			top: 63%;
		}
	}

	@media (pointer: fine) {
		span {
			transition: .1s;
		}
		&:hover {
			span {
				&:nth-child(1),
				&:nth-child(3) {
					width: 25px;
				}
				&:nth-child(2) {
					width: 19px;
					margin-left: -3px;
				}
			}
		}
	}
}

.header {
	.menuCtrl {
		@media (max-width: 900px) {
			position: fixed;
			right: 2%;
			top: 25px;
			z-index: 1000;
			background: #ffffff;
			box-shadow: 0 0 0 4px #ffffff;
		}

		@media (max-width: 600px) {
			top: 16px;
		}
	}
}

.menuCtrl_white {
	border-color: #7990A8;
	opacity: 1;
	span {
		background: #fff;
	}
}
.menuCtrl_close {
	span {
		transition: background-color .2s, opacity .2s, top .1s ease-out, margin-top .1s ease-out, transform .1s .1s ease-out;
		
		&:nth-of-type(1) {
			top: 50%;
			width: 25px;
			margin-top: -1px;
			transform: translateX(-50%) rotate(45deg);
		}

		&:nth-of-type(2) {
			top: 50%;
			margin-top: -1px;
			opacity: 0;
		}

		&:nth-of-type(3) {
			top: 50%;
			width: 25px;
			margin-top: -1px;
			transform: translateX(-50%) rotate(-45deg);
		}
	}
}