@import "misc/bootstrap";
@import "misc/bootstrap-init";
//@import "misc/fonts";
@import "mixins/font";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Roboto+Condensed:wght@400;700&display=swap');

html, body {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	margin: 0;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 16px;
	line-height: 1.2;
	color: #2B2B2B;

	@include media-max(md) {
		font-size: 15px;
	}

	@include media-max(sm) {
		font-size: 12px;
	}
}

* {
	box-sizing: border-box;
}

*, *:active, *:focus {
	outline: 0 !important;
	-moz-outline-style: none !important;
}


//button:hover,
//button:focus {
//	border: none;
//	outline: 0 !important;
//	box-shadow: none !important;
//	-moz-outline-style: none !important;
//}
//
//button:active {
//	transform: scale(0.90);
//}

a[href^="tel"] {
	white-space: nowrap;
}

:not(.page-content) {
	a[href^="tel"] {
		color: inherit;
		text-decoration: inherit;
	}
}

a {
	color: #4c6885
}

@import "misc/button";
@import "misc/text";
@import "misc/animations";
@import "mixins/reset";

@import "block/content";
@import "block/page";
@import "block/header";
@import "block/mainMenu";
@import "block/menuCtrl";
@import "block/footer";
@import "block/footerMenu";
@import "block/btn1";
@import "block/btn2";
@import "block/btn3";
@import "block/btn4";
@import "block/btn5";
@import "block/icons";
@import "block/topDropDown";
@import "block/topMenu";
@import "block/slider";
@import "block/slider2";
@import "block/slider3";
@import "block/slider4";
@import "block/slider5";
@import "block/slider6";
@import "block/healthCenter";
@import "block/pageImage";
@import "block/numSquare";
@import "block/numRound";
@import "block/breadCrumbs";
@import "block/subMenu";
@import "block/narrowBlock";
@import "block/squareFrame";
@import "block/frame";
@import "block/leavesList";
@import "block/eventsList";
@import "block/event";
@import "block/photoGallery";
@import "block/photoGalleryList";
@import "block/gallery";
@import "block/actionsList";
@import "block/actions";
@import "block/pagination";
@import "block/staffList";
@import "block/showdowFrame";
@import "block/imageToRight";
@import "block/imageToLeft";
@import "block/articleGrid";
@import "block/headerDecor";
@import "block/xlsLink";
@import "block/docLink";
@import "block/pdfLink";
@import "block/spoiler";
@import "block/autoSpoiler";
@import "block/afishaList";
@import "block/information";
@import "block/blockIcon";
@import "block/blockIcon2";
@import "block/list1";
@import "block/colorSquare";
@import "block/shadowFrame2";
@import "block/quote";
@import "block/blockPlus";
@import "block/blockMinus";
@import "block/proceduresList";
@import "block/price";
@import "block/instaList";
@import "block/forms";
@import "block/reviews";
@import "block/kbox";
@import "misc/aos";
@import "block/popup";
@import "block/popupForm";
@import "block/foxMenuBlock";
@import "block/videoFrame";
@import "block/priceTable";
@import "block/bookingForm";
@import "block/booking";
@import "block/pineCoins";
@import "block/leaves";
@import "block/mirCard";
@import "block/beam";
@import "block/tabsContent";
@import "block/formsTg";
@import "block/itcss";