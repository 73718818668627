@import "../misc/bootstrap";

.afishaList {
}

.afishaList-item {
	display: flex;
	@include media-max(sm) {
		display: block;
	}
}

.afishaList-time {
	white-space: nowrap;
	padding-right: 58px;
}

.afishaList-content, .afishaList-content p {
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
}

.afishaList-annotation {
	
}
