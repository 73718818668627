@import "../misc/bootstrap";

.formsTg{
  position: relative;
  height: auto;
  display: flex;
  align-items: flex-start;
  max-width: 1000px;
  justify-content: space-between;
}

.formsTg-left{
  margin-right: 50px;
  position: relative;
  top: 50px;
  @media screen and (max-width: 820px){
   top: 0;
  }

  p{
    max-width: 380px;
    margin: 0;
  }

  h1{
    margin-bottom: 20px;
  }
}

.formsTg-right{
  position: relative;
  height: auto;
  top: 4px;

  @media screen and (max-width: 820px){
    top: 60px;
  }
  @media screen and (max-width: 720px){
    display: none;
  }
}

.formsTg-frame{
  padding: 3.7em 0 0;

  @media screen and (max-width: 820px){
    padding: 3.7em 0;
  }

}

.formsTg-btn{
  margin-top: 40px;
  font-size: 18px;
  color: white;
  background: #5698E5;
  height: 44px;
  width: 211px;
  border-radius: 30px;

  @media screen and (max-width: 576px){
    margin-top: 30px;
    font-size: 14px;
    width: 165px;
  }

  a{
    text-decoration: none;
  }
}