@import "../mixins/reset";

.subMenu {
	margin: 41px 0 62px;
	max-width: 900px;
	
	@include reset;
	li {
		@include reset;
		display: inline-block;
	}
	
	a {
		display: inline-block;
		flex-direction: row;
		padding: 11px 24px;
		border: 1px solid #C3D6B1;
		box-sizing: border-box;
		border-radius: 30px;
		color: #272727;
		text-decoration: none;
		font-size: 18px / 16px * 1rem;
		font-family: 'Roboto Condensed', sans-serif;
		letter-spacing: .01em;
		margin-right: 5px;
		margin-bottom: 18px;
		transition: .1s ease;
		
		&:hover {
			background: #C3D6B1;
		}
		
		@media (max-width: 600px) {
			padding: 4px 12px;
			border-radius: 20px;
			font-size: 16px / 16px * 1rem;
			margin-bottom: 9px;
		}
	}
}

.subMenu-item_current {
	a {
		background: #c3d6b1;
		color: #fff;
	}
}