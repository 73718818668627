@import "../sprite";

.information {
	display: flex;	
	align-items: center;
	&:before {
		content: '';
		display: block;
		margin-right: 20px;
		@include retina-sprite($i-icon-group);
		min-width: $i-icon-width;
		max-width: $i-icon-width;
	}
}

.information_top {
	align-items: flex-start;
}

a.information {
	display: flex;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 19px / 16px * 1rem;
	line-height: 28px / 16px * 1rem;
	text-decoration-line: underline;
	color: #4C6885;
}