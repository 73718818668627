@import "../sprite";
@import "../misc/bootstrap";

body.coreSiteMode {
	.slider4 {
		position: relative;
		overflow: hidden;
		width: 100%;
		//min-width: 630px;
		padding-top: 100%;
		
		//@include media-max(md) {
		//	min-width: 320px;
		//}
	}
	
	.slider4-content {
		position: absolute;
		left: 0;
		top: 0;
		width: 1000%;
	}
	
	.slider4-item {
		display: none;
		float: left;
		width: 10%;
		img {
			display: block;
			width: 100% !important;
			height: auto !important;
		}

		&:nth-of-type(1),
		&:nth-of-type(2),
		&:nth-of-type(3),
		&:nth-of-type(4) {
			display: block;
		}
	}

	//.slider4-ctrl {
	//	position: absolute;
	//	left: 0;
	//	top: 0;
	//	width: 100%;
	//	height: 100%;
	//}

	.slider4-left, .slider4-right {
		position: absolute;
		top: 0;
		border: none;
		background: none;
		cursor: pointer;
		height: 100%;
		width: 62px;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			@include retina-sprite($arrow-gray-group);
		}
	}

	.slider4-left {
		&:after {
			transform: translateX(-50%) translateY(-50%) rotate(180deg);
		}
		left: 0;
	}

	.slider4-right {
		&:after {
			transform: translateX(-50%) translateY(-50%);
		}
		right: 0;
	}

	.slider4-pages {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 45px;
		text-align: center;
	}
	
	.slider4-page {
		background: #fff;
		opacity: .3;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		display: inline-block;
		margin: 0 20px;
		padding: 0;
	}

	.slider4-page_current {
		opacity: 1;
		transform: scale(1.6);
	}
}

body.coreEditorMode {
	.slider4 {
		max-width: 320px;
	}

	.slider4-content {
		width: 100%;
		overflow: auto;
		white-space: nowrap;
	}

	.slider4-item {
		display: inline-block;
		width: 300px;
		img {
			max-width: 100%;
		}

		+.slider4-item {
			margin-left: 10px;
		}
	}
}