@import "../mixins/reset";
@import "../misc/bootstrap";

.topMenu {
	@include reset;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 700px;
	margin: 0 -1rem -1rem 0;

	@include media-max(lg) {
		max-height: 900px;
	}

	@include media-max(md) {
		padding-bottom: 40px;
	}

}

.topMenu-item {
	padding-right: 45px;
	padding-bottom: 35px;
	max-width: 300px;
	@include media-max(md) {
		max-width: 200px;
		padding-right: 0;
		margin: 0 1rem 1rem 0;
	}
	@media (max-width: 960px) {
		.topMenu-item {
			max-width: 200px;
			padding-right: 0;
			margin: 0 1rem 1rem 0; }
	}
	@media (max-width: 900px) {
		.topMenu-item {
			padding-bottom: 10px; }
		.topMenu-item a {
			font-size: 20px !important; }
	}
}

.topMenu-title {
	color: #fff;
	text-decoration: none;
	font: {
		size: 26px / 16px * 1rem;
		family: 'Roboto Condensed';
		weight: bold;
	}
}

.topMenu-menu {
	@include reset;


	li {
		margin: 20px 0;

		a {
			color: #fff;
			text-decoration: none;
			font: {
				size: 19px / 16px * 1rem;
				family: 'Open Sans';
			}
		}
	}
}

@media (max-width: 992px){
	.topMenu-menu{
		display: none;
	}

	.topMenu-item{
		padding-bottom: 5px;
	}

	.topMenu-item a{
		font-size: 22px !important;
	}
}

@media (max-width: 900px) {
	.topMenu-title {
		font-size: 24px / 16px * 1rem;
	}
	.topMenu-menu {
		li {
			margin: 10px 0;

			a {
				font: {
					size: 16px / 16px * 1rem !important;
				}
			}
		}
	}
}

@media (max-width: 600px) {
	.topMenu-menu {
		li {
			margin: 5px 0;
		}
	}
}