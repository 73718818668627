@import "../misc/bootstrap";

.pageImage {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: 100vw;
	max-width: 1920px;
	overflow: hidden;

	img {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		max-height: 60vh;
		min-height: 300px;
		width: 100% !important;
		object-fit: cover;
	}

	video {
		position: absolute;
		left: 50%;
		top: 0;
		min-height: 300px;
		transform: translateX(-50%);
		display: block;
		max-height: 60vh;
		width: 100% !important;
		object-fit: cover;
	}
}

.pageImage_mdToContent { // Сужается по контенту ставя картинку фоном
	@include media-max(md) {
		height: auto !important;
		min-height: auto;
		padding: 1px;
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transform: none;
			object-fit: cover;
		}
	}
}

.pageImage-text {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateY(-50%) translateX(100px);

	&:before {
		content: '';
		position: absolute;
		width: 120px;
		height: 24px;
		left: 0;
		top: 0;
		background: #e26360;
		transform: translateX(-100%);
	}

	* {
		color: #000;
	}
}

.pageImage-leftText {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	max-width: 1180px;
	transform: translateY(-50%) translateX(-50%);
	text-align: left;

	@include media-max(xl) {
		padding-left: 20px;
	}


	* {
		color: #fff;
	}
}

.pageImage_fullHeight {
	img {
		max-height: none;
		width: auto !important;
	}
}

.pageImage-slider3 {
	position: absolute;
	right: 44.3%;
	top: 50%;
	transform: translateY(-50%);

	@include media-max(md) {
		position: relative;
		left: 0 !important;
		top: 0 !important;
		height: auto;
		width: 92%;
		transform: none;
		margin: 40px auto !important;
		padding: 36px 23px !important;
	}
}

.pageImage-framedText {
	position: absolute;
	left: 46.4%;
	top: 50%;
	width: 560px;
	max-width: 100%;
	height: 560px;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 58px 0 100px;

	&:before {
		content: '';
		position: absolute;
		background: rgba(#000, .3);
		backdrop-filter: blur(20px);
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: -1;
	}
	
	@include media-max(xl) {
		left: auto;
		right: 20px;
	}

	@include media-max(md) {
		position: relative;
		left: 0 !important;
		top: 0 !important;
		height: auto;
		width: 92%;
		transform: none;
		margin: 40px auto !important;
		padding: 36px 23px !important;
	}
}

.pageImage-framedText_left {
	left: 50%;
	margin-left: -590px;

	@include media-max(xl) {
		left: auto;
		right: 20px;
		margin-left: 0;
	}

	@include media-max(sm) {
		left: 0;
		right: 0;
		padding: 0 58px 0 20px;
	}
}

body.coreEditorMode {
	.pageImage {
		width: 100%;
	}
}