.btn5 {
	display: inline-flex;
	vertical-align: middle;
	height: 80px / 16px * 1rem;
	background: #ececec;
	border-radius: 96px;
	font-family: 'Open Sans', sans-serif;
	font-size: 19px / 16px * 1rem;
	line-height: 28px / 16px * 1rem;
	color: #2B2B2B;
	text-decoration: none;
	padding: 0 48px / 16px * 1rem;
	align-items: center;
}

.btn5_red {
	background: #e26360;
	color: #fff;
	@media (pointer: fine) {
		&:hover {
			background: #ea8d8b;
		}
	}
}