.content {
	width: 92%;
	max-width: 1180px;
	margin: 0 auto;
	
	img {
		width: auto !important;
		height: auto !important;
		max-width: 100%;
	}
}