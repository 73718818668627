#tl-booking-form,
#tl-anchor {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
}

#block-search {
	background: transparent;
	//-webkit-backdrop-filter: blur(20px);
	//backdrop-filter: blur(20px);
	//margin-top: -90px;
}

.tl-container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}

//s

.searchPopup {
	background: #7db260 url(/img/leaves-green.jpg) -122px -317px;
	border-radius: 20px;
}