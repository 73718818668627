@import "../sprite";
// It's like .btn1 but bigger
.btn3 {
	color: #fff;
	background: #7DB260;
	border-radius: 30px;
	height: 54px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 22px / 16px * 1rem;
	text-decoration: none;
	padding: 0 30px;
	letter-spacing: .01em;
}

.btn3_blue {
	background: #6584A4;
}

.btn3_whiteStar {
	padding-left: 18px;
	&:before {
		margin: 0 10px 0 0;
		content: '';
		@include retina-sprite($white-star-group);
	}
}

.btn3_grill {
	padding-left: 19px;
	&:before {
		margin: 0 17px 0 0;
		content: '';
		@include retina-sprite($grill-group);
	}
}