@import "../misc/bootstrap";

.footer {
	max-width: 1180px;
	width: 92%;
	margin: 0 auto;
}

.footer-logo {
	img {
		width: 180px;
		@include media-max(sm) {
			width: 153px;
		}
	}
}

.footer-top {
	margin-bottom: 33px;
	padding-top: 54px;
	
	@include media-max(sm) {
		padding-top: 39px;
	}
}

.footer-bottom {
	color: #808080;
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	padding-bottom: 1px;
}

.footer-copy {
	font-size: 13px;
	display: inline-block;
	margin-bottom: 16px;
}

.footer-idea {
	color: #A1A1A1;
	font-size: 13px;
	a {
		color: inherit;
		text-decoration: none;
	}
}

.footer-phone {
	color: #2B2B2B;
	font-size: 26px / 16px * 1rem;
	font-weight: bold;
	margin-bottom: 9px;
}

.footer-address {
	color: #808080;
	font-size: 18px / 16px * 1rem;
	margin-bottom: 9px;
}

.footer-email {
	margin-bottom: 33px;
	a {
		color: #4C6885;
		font-size: 17px / 16px * 1rem;
	}
}

.footer-socialIcon {
	+ .footer-socialIcon {
		margin-left: 5px;	
	}
}