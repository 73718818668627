.gallery {
	
}

.gallery-content {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	
	&:after {
		content: '';
		height: 0;
		width: 32%;
		display: block;
	}
}

.gallery-item {
	width: 32%;
	margin-bottom: 1.933%;
	img {
		display: block;
		max-width: 100%;
	}
}