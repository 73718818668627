@import "../sprite";
@import "../misc/bootstrap";

.header {
	width: 96%;
	max-width: 1180px;
	margin: 0 auto;
}

.header-wa {
	display: inline-block;
	margin-right: 10px;
	@include retina-sprite($whatsapp-group);
	position: relative;
	top: -1px;
}

.header-right_block {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.header-ws{
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.header-logo {
	display: flex;
	align-items: center;
	padding-top: 1.6%;
	padding-right: 49px;
	img {
		width: 235px;
	}
}

.header-links {
	padding-top: 12px;
}

.header-phone {
	font-size: 16px;
	font-weight: 400;
	color: #2B2B2B;
}

.header-callback {
	margin: 0 33px 0 13px;
	transform: translateY(-4px);
}

.header-top {
	padding-top: 15px;
}

.header-bottom {
	display: flex;
	padding-top: 32px;
}

.header-menu {
	flex-grow: 1;
}

.header-menuCtrl {
	margin-left: 34px;
	margin-top: -17px;
}

@media (max-width: 1150px) {
	.header-logo {
		padding-right: 20px;
		img {
			width: 200px;
		}
	}
	.header-phone {
		font-size: 20px / 16px * 1rem;
	}
}

@media (max-width: 900px) {
	.page-header {
		padding: 16px 0;	
	}
	.header-logo {
		padding-top: 0;
	}
	.header-top, .header-menu {
		display: none;
	}
	.header-bottom {
		padding: 0;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.header-menuCtrl {
		margin: 0;
	}
}

@media (max-width: 600px) {
	.header-logo {
		img {
			width: 140px;
		}
	}
}