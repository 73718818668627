.instaList {
	display: flex;
	justify-content: space-between;
	width: 92%;
	max-width: 1180px;
	margin: 4.05em auto;
	
	img {
		width: 23.7%;
		display: block;
	}
}