@import "../misc/bootstrap";

.foxMenuBlock {
	position: relative;
	left: 50%;
	width: 100vw;
	transform: translateX(-50%);
	display: flex;
	margin: 4em 0;
	flex-wrap: wrap;
}

.foxMenuBlock-content {
	width: 50%;
	@include media-max(md) {
		width: 65%;
	}
	@include media-max(sm) {
		width: 100%;
	}
	
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background: url('/img/brown-pattern.jpg') center center;
	background-size: cover;
}

.foxMenuBlock-text {
	max-width: 480px;
	margin: 0 110px;
	color: #fff;
	@include media-max(xl) {
		margin: 0 40px;
	}
	@include media-max(sm) {
		margin: 0 20px;	
	}
	p {
		margin: 30px / 16px  * 1rem;
		color: inherit !important;
	}
}

.foxMenuBlock-img {
	width: 50%;
	@include media-max(md) {
		width: 35%;
	}
	@include media-max(sm) {
		width: 100%;
	}
	img {
		display: block;
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
	}
}