@import "../misc/bootstrap";
@import "../sprite";

.carousel-cell {
  height: 662px;

  img{
    height: 662px;
    width: 100%;
    object-fit: cover;

    @media (max-width: 1023px){
      height: 331px;
    }
    @media (max-width: 639px){
      height: 255px;
    }

  }

  @media (max-width: 1023px){
    height: 331px;
  }

  @media (max-width: 639px){
    height: 255px;
  }
}
.flickity-viewport{
  height: 662px;

  @media (max-width: 1023px){
    height: 331px;
  }

  @media (max-width: 639px){
    height: 255px;
  }
}

.flickity-prev-next-button {
  width: 110px !important;
  height: 110px !important;
  border-radius: 50% !important;
  background: transparent !important;
  border: 1px solid white !important;

  &:active, &:focus{
    outline: none !important;
    box-shadow: none !important;
  }

  @media (max-width: 1023px){
    width: 60px !important;
    height: 60px !important;
  }
}

.flickity-prev-next-button .flickity-button-icon {
  color: white !important;
}


.flickity-prev-next-button.previous {
  left: 18vw !important;

  @media (max-width: 1400px){
    left: 10px !important;
  }
}
.flickity-prev-next-button.next {
  right: 18vw !important;
  @media (max-width: 1400px){
    right: 10px !important;
  }
}
