.page {
	display: flex;
	min-height: 100%;
	flex-direction: column;
	overflow: hidden;
	box-shadow: 0 0 30px rgba(#000, .2), 0 0 5px rgba(#000, .2);
	max-width: 1920px;
	margin: 0 auto;
}

.page_noBottomPadding {
	.page-content {
		padding-bottom: 0;
	}
}

.page-header {
	padding-bottom: 16px;
}

.page-content {
	flex-grow: 2;
	padding-bottom: 6.4rem;
}

.page-footer {
	padding: 1px;
	background: #F7F7F7;
}
