.mainMenu {
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;
	justify-content: space-between;

	li {
		display: block;
		list-style: none;
		padding: 0;
		margin: 0;

		a {
			text-transform: uppercase;
			text-decoration: none;
			font-size: 20px;
			font-family: 'Roboto Condensed', sans-serif;
			color: #2b2b2b;
			letter-spacing: .01em;
			transition: .12s;
			
			&:hover {
				color: #7DB260;
			}
		}
	}
}

@media (max-width: 1150px) {
	.mainMenu {
		li {
			a {
				font-size: 18px;
			}
		}
	}
}

@media (max-width: 1100px) {
	.mainMenu {
		li {
			a {
				font-size: 16px;
			}
		}
	}
}

@media (max-width: 900px) {
	
}