@import "../misc/bootstrap";

.pineCoins {
	position: relative;
	overflow: hidden;
	
	&:after {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		width: 1920px;
		height: 396px;
		background: url('/img/main/addons/pine-cones.png') left bottom no-repeat;
		transform-origin: left bottom;
		pointer-events: none;
		
		@media (max-width: 1600px) {
			width: 100%;
			transform: translateX(-200px) scale(.8);
		}
		@media (max-width: 1300px) {
			width: 300%;
			right: auto;
			left: 0;
			transform: translateX(-200px) scale(.5);
		}
		@include media-max(sm) {
			transform: translateX(-90px) scale(.3);
		}
	}
}