@import "../misc/bootstrap";

.topDropDown {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1000002;
	background: url("/img/leaves-dark-blue.jpg");
	transform: translateY(-100%);
	transition: .2s;
	box-shadow: 0 0 20px rgba(#000, 0);
}

.topDropDown_visible {
	transform: translateY(0);
	box-shadow: 0 0 20px rgba(#000, .9);
}

.topDown-scroll {
	height: 100%;
	overflow: auto;
}

.topDropDown-content {
	width: 92%;
	max-width: 1180px;
	margin: 0 auto;
}

.topDropDown-topRow {
	padding: 73px 0 67px;
}

.topDropDown-btn {
	margin-right: 16px;
	vertical-align: bottom;
	margin-bottom: 15px;
}

.topDropDown-menu {
	
}

.topDropDown-close {
	position: absolute;
	right: 4%;
	top: 69px;
	
	@include media-max(dm) {
		top: 27px;
	}
}

.topDropDown-mobileTop {
	margin-bottom: 40px;
	display: none;
}

.topDropDown-phone {
	color: #fff;
	font-size: 25px / 16px * 1rem;
	font-weight: bold;
	margin-bottom: 8px;
	display: inline-block;
}

.topDropDown-callBack {
	color: #fff;
	font-size: 16px / 16px * 1rem;
}


@media (max-width: 900px) {
	.topDropDown-mobileTop {
		display: block;
	}
	
	.topDropDown-close {
		top: 30px;
		right: 7%;
	}

	.topDropDown-topRow {
		padding: 30px 0;
	}
}

@media (max-width:600px) {
	
}


