@import "../sprite";

.iconVk {
	display: inline-block;
	@include retina-sprite($vk-group);
}

.iconFb {
	display: inline-block;
	@include retina-sprite($fb-group);
}

.iconInst {
	display: inline-block;
	@include retina-sprite($inst-group);
}

.iconYou {
	display: inline-block;
	@include retina-sprite($you-group);
}

.fox-logo {
	display: inline-block;
	@include retina-sprite($fox-brown-group);
	margin-top: 25px / 16px * 1rem;
	margin-bottom: 15px / 16px * 1rem;
}