@import "../sprite";

.blockPlus {
	position: relative;
	padding-left: 100px;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: -20px;
		display: block;
		@include retina-sprite($plus-group);
	}
}