[data-aos="fade-down"] {
	opacity: 0;
	transition: 0s !important;
	transform: translateY(-20px) !important;
	@for $i from 1 through 16 {
		&:nth-child(#{$i}) {
			transition-delay: $i * .03s;
		}
	}
}

[data-aos="fade-down"].aos-animate {
	opacity: 1 !important;
	transform: translateY(0)  !important;;
	transition: .4s !important;
}

[data-aos="fade"] {
	opacity: 0;
	transition: 0s !important;
	@for $i from 1 through 16 {
		&:nth-child(#{$i}) {
			transition-delay: $i * .03s;
		}
	}
}

[data-aos="fade"].aos-animate {
	opacity: 1 !important;
	transition: .4s !important;
}

[data-aos="myFade"] {
	opacity: 0;
	transition: 0s !important;
}
[data-aos="myFade"].aos-animate {
	opacity: 1 !important;
	transition: .4s !important;
}


body[data-admin="0"] {
	.pageImage, img,
	.articleGrid-item, h1, h2, h3, .h1, .h2, .h3, p, li {
		opacity: 0;
	}
	
	.topMenu li {
		opacity: 1;
	}
}
