@import "../misc/bootstrap";

.squareFrame {
	position: relative;
	z-index: 1;
	width: 480px;
	display: block;
	background: #fff;
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 26px / 16px * 1rem;
	line-height: 36px / 16px * 1rem;
	color: #000;
	text-align: center;
	padding: 0 40px;
	transform: translateY(134px);
	
	&:before {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		height: 480px;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
	}

	@include media-max(sm) {
		width: auto;
		transform: translateY(0);
		padding: 20px 40px;
		&:before {
			height: 100%;
		}
	}
}

.squareFrame_green {
	color: #fff;
	&:before {
		background: #7db260 url('/img/leaves-green.jpg') -122px -317px;
	}
}

.squareFrame_blue {
	color: #fff;
	&:before {
		background: #6584A4 url('/img/leaves-blue.jpg') -122px -317px;
	}
}