@import "../sprite";
@import "../misc/bootstrap";

.slider2 {
	position: relative;
	//height: 490px;
	//@include media-max(sm) {
	//	height: 750px;
	//}
}

.slider2-item {
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: .45s;
	
	&:nth-of-type(1) {
		position: relative;
	}

	@include media-max(md) {
		display: flex;
		flex-direction: column;
	}
}

.slider2-item_visible {
	opacity: 1;
	z-index: 1;
}

.slider2-dots {
	position: absolute;
	left: 0;
	top: 50%;
	height: 400px;
	width: 500px;
	background: rgba(red, .4);
	z-index: 3;
	transform: translateY(-50%);
	display: flex;
	align-items: flex-end;
	justify-content: center;

	pointer-events: none;
	display: none;
}

.slider2-dot {
	position: relative;
	width: 20px;
	height: 20px;
	margin: 0 7px;
	pointer-events: unset;

	&:after {
		content: '';
		position: absolute;
		width: 12px;
		height: 12px;
		left: 50%;
		top: 50%;
		border-radius: 50%;
		background: rgba(#fff, .3);
		transform: translateX(-50%) translateY(-50%);
		transition: .2s;
	}

	&.slider2-dot_current {
		&:after {
			width: 20px;
			height: 20px;
			background: rgba(#fff, 1);
		}
	}
}

.slider2-text {
	position: absolute;
	left: 0;
	top: 50%;
	width: 500px;
	height: 400px;
	transform: translateY(-50%);
	background: url('/img/leaves-violet.jpg');
	color: #fff;
	text-align: center;
	padding: 12px 30px 0;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	@include media-max(md) {
		position: static;
		transform: none;
		order: 1;
		width: 100%;
		height: auto;
	}

	//@include media-max(sm) {
	//	width: 100%;
	//	top: auto;
	//	bottom: 0;
	//	transform: translateY(0);
	//	padding: 12px 40px 0;
	//}
	

	p {
		font-family: 'Roboto Condensed', sans-serif;
		font-style: normal;
		font-weight: 300;
		font-size: 32px / 16px * 1rem;
		line-height: 38px / 16px * 1rem;
	}

	.btn1 {
		margin-top: 2.2em;
		padding: 0 2.2em;
	}
}

.slider2-img {
	display: block;
	float: right;
	img {
		display: block;
		max-width: 100%;
		
		@include media-max(md) {
			width: 100%;
		}
	}
}

.slider2-left, .slider2-right {
	position: absolute;
	display: block;
	height: 100%;
	width: 150px;
	@media (max-width: 1400px) {
		width: 50px;
	}
	z-index: 3;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		@include media-max(md) {
			top: auto;
			bottom: -6px;
		}
		@include sprite($arrow-gray);
	}
}

.slider2-left {
	left: 0;
	transform: translateX(-100%);
	@media (max-width: 1400px) {
		transform: translateX(0);
	}

	&:after {
		right: 50%;
		transform: translateY(-50%) rotate(180deg);
		
		@media (max-width: 1400px) {
			right: 5px;
		}
		@include media-max(md) {
			right: auto;
			left: 5px;
			//transform-origin: left center;
		}
		
		@include media-max(sm) {
			transform: translateY(-50%) rotate(180deg) scale(.6);
		}
	}
}

.slider2-right {
	right: 0;
	transform: translateX(100%);
	@media (max-width: 1400px) {
		transform: translateX(0);
	}

	&:after {
		left: 50px;
		transform: translateY(-50%);
		
		@media (max-width: 1400px) {
			left: 5px;
		}
		@include media-max(md) {
			left: auto;
			right: 5px;
		}
		@include media-max(sm) {
			transform: translateY(-50%) scale(.6);
		}
	}
}

@media (max-width:960px){
	.slider2-bottom-text {
		z-index: 999;
		position: relative;
		right: 25px;
		top: 25px;
	}
}








