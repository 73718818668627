.btn4 {
	display: inline-flex;
	text-decoration: none;
	height: 44px / 16px * 1rem;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 18px / 16px * 1rem;
	line-height: 21px / 16px * 1rem;
	align-items: center;
	text-align: center;
	color: #FFFFFF;
	border: 1px solid #FFFFFF;
	border-radius: 30px;
	padding: 0 24px;
}