@import "../misc/bootstrap";

.leaves {
	z-index: 1;
}

.leaves-leaf1 {
	position: absolute;
	background: url('/img/main/addons/leaf1.png');
	width: 674px;
	height: 304px;
	pointer-events: none;
	left: 270px;
	top: 329px;
	@include media-max(md) {
		display: none;
	}
}

.leaves-leaf2 {
	position: absolute;
	background: url('/img/main/addons/leaf2.png');
	width: 980px;
	height: 476px;
	pointer-events: none;
	left: -274px;
	top: 155px;
	z-index: -2;
	@include media-max(md) {
		display: none;
	}
}

.leaves-leaf3 {
	position: absolute;
	background: url('/img/main/addons/leaf3.png');
	width: 214px;
	height: 193px;
	pointer-events: none;
	left: -120px;
	top: -19px;

	@include media-max(md) {
		transform: scale(0.5);
		left: -90px;
		top: -100px;
	}
}