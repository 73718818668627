.headerDecor {
	position: relative;
	text-align: center;
	
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		top: 50%;
		left: 0;
		background: #BFBFBF;
	}
}

.headerDecor-content {
	position: relative;
	display: inline-block;
	background: #fff;
	padding: 0 37px;
}
