@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Roboto+Condensed:wght@400;700&display=swap");
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 960px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 960px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 960px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.m-8 {
  margin: 6rem !important;
}

.mt-8,
.my-8 {
  margin-top: 6rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 6rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 6rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 6rem !important;
}

.m-9 {
  margin: 7.5rem !important;
}

.mt-9,
.my-9 {
  margin-top: 7.5rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 7.5rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 7.5rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 7.5rem !important;
}

.m-10 {
  margin: 8rem !important;
}

.mt-10,
.my-10 {
  margin-top: 8rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 8rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 8rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 8rem !important;
}

.m-11 {
  margin: 9rem !important;
}

.mt-11,
.my-11 {
  margin-top: 9rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 9rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 9rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 9rem !important;
}

.m-12 {
  margin: 10rem !important;
}

.mt-12,
.my-12 {
  margin-top: 10rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 10rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 10rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.p-8 {
  padding: 6rem !important;
}

.pt-8,
.py-8 {
  padding-top: 6rem !important;
}

.pr-8,
.px-8 {
  padding-right: 6rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 6rem !important;
}

.pl-8,
.px-8 {
  padding-left: 6rem !important;
}

.p-9 {
  padding: 7.5rem !important;
}

.pt-9,
.py-9 {
  padding-top: 7.5rem !important;
}

.pr-9,
.px-9 {
  padding-right: 7.5rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 7.5rem !important;
}

.pl-9,
.px-9 {
  padding-left: 7.5rem !important;
}

.p-10 {
  padding: 8rem !important;
}

.pt-10,
.py-10 {
  padding-top: 8rem !important;
}

.pr-10,
.px-10 {
  padding-right: 8rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 8rem !important;
}

.pl-10,
.px-10 {
  padding-left: 8rem !important;
}

.p-11 {
  padding: 9rem !important;
}

.pt-11,
.py-11 {
  padding-top: 9rem !important;
}

.pr-11,
.px-11 {
  padding-right: 9rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 9rem !important;
}

.pl-11,
.px-11 {
  padding-left: 9rem !important;
}

.p-12 {
  padding: 10rem !important;
}

.pt-12,
.py-12 {
  padding-top: 10rem !important;
}

.pr-12,
.px-12 {
  padding-right: 10rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 10rem !important;
}

.pl-12,
.px-12 {
  padding-left: 10rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important;
}

.m-n7 {
  margin: -5rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -5rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -5rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -5rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -5rem !important;
}

.m-n8 {
  margin: -6rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -6rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -6rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -6rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -6rem !important;
}

.m-n9 {
  margin: -7.5rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -7.5rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -7.5rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -7.5rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -7.5rem !important;
}

.m-n10 {
  margin: -8rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -8rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -8rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -8rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -8rem !important;
}

.m-n11 {
  margin: -9rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -9rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -9rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -9rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -9rem !important;
}

.m-n12 {
  margin: -10rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -10rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -10rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -10rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
.my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
.mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
.my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
.mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .mt-sm-7,
.my-sm-7 {
    margin-top: 5rem !important;
  }
  .mr-sm-7,
.mx-sm-7 {
    margin-right: 5rem !important;
  }
  .mb-sm-7,
.my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-7,
.mx-sm-7 {
    margin-left: 5rem !important;
  }
  .m-sm-8 {
    margin: 6rem !important;
  }
  .mt-sm-8,
.my-sm-8 {
    margin-top: 6rem !important;
  }
  .mr-sm-8,
.mx-sm-8 {
    margin-right: 6rem !important;
  }
  .mb-sm-8,
.my-sm-8 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-8,
.mx-sm-8 {
    margin-left: 6rem !important;
  }
  .m-sm-9 {
    margin: 7.5rem !important;
  }
  .mt-sm-9,
.my-sm-9 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-9,
.mx-sm-9 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-9,
.my-sm-9 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-9,
.mx-sm-9 {
    margin-left: 7.5rem !important;
  }
  .m-sm-10 {
    margin: 8rem !important;
  }
  .mt-sm-10,
.my-sm-10 {
    margin-top: 8rem !important;
  }
  .mr-sm-10,
.mx-sm-10 {
    margin-right: 8rem !important;
  }
  .mb-sm-10,
.my-sm-10 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-10,
.mx-sm-10 {
    margin-left: 8rem !important;
  }
  .m-sm-11 {
    margin: 9rem !important;
  }
  .mt-sm-11,
.my-sm-11 {
    margin-top: 9rem !important;
  }
  .mr-sm-11,
.mx-sm-11 {
    margin-right: 9rem !important;
  }
  .mb-sm-11,
.my-sm-11 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-11,
.mx-sm-11 {
    margin-left: 9rem !important;
  }
  .m-sm-12 {
    margin: 10rem !important;
  }
  .mt-sm-12,
.my-sm-12 {
    margin-top: 10rem !important;
  }
  .mr-sm-12,
.mx-sm-12 {
    margin-right: 10rem !important;
  }
  .mb-sm-12,
.my-sm-12 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-12,
.mx-sm-12 {
    margin-left: 10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
.py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
.px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
.py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
.px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .pt-sm-7,
.py-sm-7 {
    padding-top: 5rem !important;
  }
  .pr-sm-7,
.px-sm-7 {
    padding-right: 5rem !important;
  }
  .pb-sm-7,
.py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-7,
.px-sm-7 {
    padding-left: 5rem !important;
  }
  .p-sm-8 {
    padding: 6rem !important;
  }
  .pt-sm-8,
.py-sm-8 {
    padding-top: 6rem !important;
  }
  .pr-sm-8,
.px-sm-8 {
    padding-right: 6rem !important;
  }
  .pb-sm-8,
.py-sm-8 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-8,
.px-sm-8 {
    padding-left: 6rem !important;
  }
  .p-sm-9 {
    padding: 7.5rem !important;
  }
  .pt-sm-9,
.py-sm-9 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-9,
.px-sm-9 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-9,
.py-sm-9 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-9,
.px-sm-9 {
    padding-left: 7.5rem !important;
  }
  .p-sm-10 {
    padding: 8rem !important;
  }
  .pt-sm-10,
.py-sm-10 {
    padding-top: 8rem !important;
  }
  .pr-sm-10,
.px-sm-10 {
    padding-right: 8rem !important;
  }
  .pb-sm-10,
.py-sm-10 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-10,
.px-sm-10 {
    padding-left: 8rem !important;
  }
  .p-sm-11 {
    padding: 9rem !important;
  }
  .pt-sm-11,
.py-sm-11 {
    padding-top: 9rem !important;
  }
  .pr-sm-11,
.px-sm-11 {
    padding-right: 9rem !important;
  }
  .pb-sm-11,
.py-sm-11 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-11,
.px-sm-11 {
    padding-left: 9rem !important;
  }
  .p-sm-12 {
    padding: 10rem !important;
  }
  .pt-sm-12,
.py-sm-12 {
    padding-top: 10rem !important;
  }
  .pr-sm-12,
.px-sm-12 {
    padding-right: 10rem !important;
  }
  .pb-sm-12,
.py-sm-12 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-12,
.px-sm-12 {
    padding-left: 10rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4rem !important;
  }
  .mt-sm-n6,
.my-sm-n6 {
    margin-top: -4rem !important;
  }
  .mr-sm-n6,
.mx-sm-n6 {
    margin-right: -4rem !important;
  }
  .mb-sm-n6,
.my-sm-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n6,
.mx-sm-n6 {
    margin-left: -4rem !important;
  }
  .m-sm-n7 {
    margin: -5rem !important;
  }
  .mt-sm-n7,
.my-sm-n7 {
    margin-top: -5rem !important;
  }
  .mr-sm-n7,
.mx-sm-n7 {
    margin-right: -5rem !important;
  }
  .mb-sm-n7,
.my-sm-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n7,
.mx-sm-n7 {
    margin-left: -5rem !important;
  }
  .m-sm-n8 {
    margin: -6rem !important;
  }
  .mt-sm-n8,
.my-sm-n8 {
    margin-top: -6rem !important;
  }
  .mr-sm-n8,
.mx-sm-n8 {
    margin-right: -6rem !important;
  }
  .mb-sm-n8,
.my-sm-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n8,
.mx-sm-n8 {
    margin-left: -6rem !important;
  }
  .m-sm-n9 {
    margin: -7.5rem !important;
  }
  .mt-sm-n9,
.my-sm-n9 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n9,
.mx-sm-n9 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n9,
.my-sm-n9 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n9,
.mx-sm-n9 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n10 {
    margin: -8rem !important;
  }
  .mt-sm-n10,
.my-sm-n10 {
    margin-top: -8rem !important;
  }
  .mr-sm-n10,
.mx-sm-n10 {
    margin-right: -8rem !important;
  }
  .mb-sm-n10,
.my-sm-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n10,
.mx-sm-n10 {
    margin-left: -8rem !important;
  }
  .m-sm-n11 {
    margin: -9rem !important;
  }
  .mt-sm-n11,
.my-sm-n11 {
    margin-top: -9rem !important;
  }
  .mr-sm-n11,
.mx-sm-n11 {
    margin-right: -9rem !important;
  }
  .mb-sm-n11,
.my-sm-n11 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n11,
.mx-sm-n11 {
    margin-left: -9rem !important;
  }
  .m-sm-n12 {
    margin: -10rem !important;
  }
  .mt-sm-n12,
.my-sm-n12 {
    margin-top: -10rem !important;
  }
  .mr-sm-n12,
.mx-sm-n12 {
    margin-right: -10rem !important;
  }
  .mb-sm-n12,
.my-sm-n12 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n12,
.mx-sm-n12 {
    margin-left: -10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 960px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
.my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
.mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
.my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
.mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .mt-md-7,
.my-md-7 {
    margin-top: 5rem !important;
  }
  .mr-md-7,
.mx-md-7 {
    margin-right: 5rem !important;
  }
  .mb-md-7,
.my-md-7 {
    margin-bottom: 5rem !important;
  }
  .ml-md-7,
.mx-md-7 {
    margin-left: 5rem !important;
  }
  .m-md-8 {
    margin: 6rem !important;
  }
  .mt-md-8,
.my-md-8 {
    margin-top: 6rem !important;
  }
  .mr-md-8,
.mx-md-8 {
    margin-right: 6rem !important;
  }
  .mb-md-8,
.my-md-8 {
    margin-bottom: 6rem !important;
  }
  .ml-md-8,
.mx-md-8 {
    margin-left: 6rem !important;
  }
  .m-md-9 {
    margin: 7.5rem !important;
  }
  .mt-md-9,
.my-md-9 {
    margin-top: 7.5rem !important;
  }
  .mr-md-9,
.mx-md-9 {
    margin-right: 7.5rem !important;
  }
  .mb-md-9,
.my-md-9 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-9,
.mx-md-9 {
    margin-left: 7.5rem !important;
  }
  .m-md-10 {
    margin: 8rem !important;
  }
  .mt-md-10,
.my-md-10 {
    margin-top: 8rem !important;
  }
  .mr-md-10,
.mx-md-10 {
    margin-right: 8rem !important;
  }
  .mb-md-10,
.my-md-10 {
    margin-bottom: 8rem !important;
  }
  .ml-md-10,
.mx-md-10 {
    margin-left: 8rem !important;
  }
  .m-md-11 {
    margin: 9rem !important;
  }
  .mt-md-11,
.my-md-11 {
    margin-top: 9rem !important;
  }
  .mr-md-11,
.mx-md-11 {
    margin-right: 9rem !important;
  }
  .mb-md-11,
.my-md-11 {
    margin-bottom: 9rem !important;
  }
  .ml-md-11,
.mx-md-11 {
    margin-left: 9rem !important;
  }
  .m-md-12 {
    margin: 10rem !important;
  }
  .mt-md-12,
.my-md-12 {
    margin-top: 10rem !important;
  }
  .mr-md-12,
.mx-md-12 {
    margin-right: 10rem !important;
  }
  .mb-md-12,
.my-md-12 {
    margin-bottom: 10rem !important;
  }
  .ml-md-12,
.mx-md-12 {
    margin-left: 10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
.py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
.px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
.py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
.px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .pt-md-7,
.py-md-7 {
    padding-top: 5rem !important;
  }
  .pr-md-7,
.px-md-7 {
    padding-right: 5rem !important;
  }
  .pb-md-7,
.py-md-7 {
    padding-bottom: 5rem !important;
  }
  .pl-md-7,
.px-md-7 {
    padding-left: 5rem !important;
  }
  .p-md-8 {
    padding: 6rem !important;
  }
  .pt-md-8,
.py-md-8 {
    padding-top: 6rem !important;
  }
  .pr-md-8,
.px-md-8 {
    padding-right: 6rem !important;
  }
  .pb-md-8,
.py-md-8 {
    padding-bottom: 6rem !important;
  }
  .pl-md-8,
.px-md-8 {
    padding-left: 6rem !important;
  }
  .p-md-9 {
    padding: 7.5rem !important;
  }
  .pt-md-9,
.py-md-9 {
    padding-top: 7.5rem !important;
  }
  .pr-md-9,
.px-md-9 {
    padding-right: 7.5rem !important;
  }
  .pb-md-9,
.py-md-9 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-9,
.px-md-9 {
    padding-left: 7.5rem !important;
  }
  .p-md-10 {
    padding: 8rem !important;
  }
  .pt-md-10,
.py-md-10 {
    padding-top: 8rem !important;
  }
  .pr-md-10,
.px-md-10 {
    padding-right: 8rem !important;
  }
  .pb-md-10,
.py-md-10 {
    padding-bottom: 8rem !important;
  }
  .pl-md-10,
.px-md-10 {
    padding-left: 8rem !important;
  }
  .p-md-11 {
    padding: 9rem !important;
  }
  .pt-md-11,
.py-md-11 {
    padding-top: 9rem !important;
  }
  .pr-md-11,
.px-md-11 {
    padding-right: 9rem !important;
  }
  .pb-md-11,
.py-md-11 {
    padding-bottom: 9rem !important;
  }
  .pl-md-11,
.px-md-11 {
    padding-left: 9rem !important;
  }
  .p-md-12 {
    padding: 10rem !important;
  }
  .pt-md-12,
.py-md-12 {
    padding-top: 10rem !important;
  }
  .pr-md-12,
.px-md-12 {
    padding-right: 10rem !important;
  }
  .pb-md-12,
.py-md-12 {
    padding-bottom: 10rem !important;
  }
  .pl-md-12,
.px-md-12 {
    padding-left: 10rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -4rem !important;
  }
  .mt-md-n6,
.my-md-n6 {
    margin-top: -4rem !important;
  }
  .mr-md-n6,
.mx-md-n6 {
    margin-right: -4rem !important;
  }
  .mb-md-n6,
.my-md-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n6,
.mx-md-n6 {
    margin-left: -4rem !important;
  }
  .m-md-n7 {
    margin: -5rem !important;
  }
  .mt-md-n7,
.my-md-n7 {
    margin-top: -5rem !important;
  }
  .mr-md-n7,
.mx-md-n7 {
    margin-right: -5rem !important;
  }
  .mb-md-n7,
.my-md-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n7,
.mx-md-n7 {
    margin-left: -5rem !important;
  }
  .m-md-n8 {
    margin: -6rem !important;
  }
  .mt-md-n8,
.my-md-n8 {
    margin-top: -6rem !important;
  }
  .mr-md-n8,
.mx-md-n8 {
    margin-right: -6rem !important;
  }
  .mb-md-n8,
.my-md-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n8,
.mx-md-n8 {
    margin-left: -6rem !important;
  }
  .m-md-n9 {
    margin: -7.5rem !important;
  }
  .mt-md-n9,
.my-md-n9 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n9,
.mx-md-n9 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n9,
.my-md-n9 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n9,
.mx-md-n9 {
    margin-left: -7.5rem !important;
  }
  .m-md-n10 {
    margin: -8rem !important;
  }
  .mt-md-n10,
.my-md-n10 {
    margin-top: -8rem !important;
  }
  .mr-md-n10,
.mx-md-n10 {
    margin-right: -8rem !important;
  }
  .mb-md-n10,
.my-md-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n10,
.mx-md-n10 {
    margin-left: -8rem !important;
  }
  .m-md-n11 {
    margin: -9rem !important;
  }
  .mt-md-n11,
.my-md-n11 {
    margin-top: -9rem !important;
  }
  .mr-md-n11,
.mx-md-n11 {
    margin-right: -9rem !important;
  }
  .mb-md-n11,
.my-md-n11 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n11,
.mx-md-n11 {
    margin-left: -9rem !important;
  }
  .m-md-n12 {
    margin: -10rem !important;
  }
  .mt-md-n12,
.my-md-n12 {
    margin-top: -10rem !important;
  }
  .mr-md-n12,
.mx-md-n12 {
    margin-right: -10rem !important;
  }
  .mb-md-n12,
.my-md-n12 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n12,
.mx-md-n12 {
    margin-left: -10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
.my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
.mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
.my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
.mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .mt-lg-7,
.my-lg-7 {
    margin-top: 5rem !important;
  }
  .mr-lg-7,
.mx-lg-7 {
    margin-right: 5rem !important;
  }
  .mb-lg-7,
.my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-7,
.mx-lg-7 {
    margin-left: 5rem !important;
  }
  .m-lg-8 {
    margin: 6rem !important;
  }
  .mt-lg-8,
.my-lg-8 {
    margin-top: 6rem !important;
  }
  .mr-lg-8,
.mx-lg-8 {
    margin-right: 6rem !important;
  }
  .mb-lg-8,
.my-lg-8 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-8,
.mx-lg-8 {
    margin-left: 6rem !important;
  }
  .m-lg-9 {
    margin: 7.5rem !important;
  }
  .mt-lg-9,
.my-lg-9 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-9,
.mx-lg-9 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-9,
.my-lg-9 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-9,
.mx-lg-9 {
    margin-left: 7.5rem !important;
  }
  .m-lg-10 {
    margin: 8rem !important;
  }
  .mt-lg-10,
.my-lg-10 {
    margin-top: 8rem !important;
  }
  .mr-lg-10,
.mx-lg-10 {
    margin-right: 8rem !important;
  }
  .mb-lg-10,
.my-lg-10 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-10,
.mx-lg-10 {
    margin-left: 8rem !important;
  }
  .m-lg-11 {
    margin: 9rem !important;
  }
  .mt-lg-11,
.my-lg-11 {
    margin-top: 9rem !important;
  }
  .mr-lg-11,
.mx-lg-11 {
    margin-right: 9rem !important;
  }
  .mb-lg-11,
.my-lg-11 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-11,
.mx-lg-11 {
    margin-left: 9rem !important;
  }
  .m-lg-12 {
    margin: 10rem !important;
  }
  .mt-lg-12,
.my-lg-12 {
    margin-top: 10rem !important;
  }
  .mr-lg-12,
.mx-lg-12 {
    margin-right: 10rem !important;
  }
  .mb-lg-12,
.my-lg-12 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-12,
.mx-lg-12 {
    margin-left: 10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
.py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
.px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
.py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
.px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .pt-lg-7,
.py-lg-7 {
    padding-top: 5rem !important;
  }
  .pr-lg-7,
.px-lg-7 {
    padding-right: 5rem !important;
  }
  .pb-lg-7,
.py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-7,
.px-lg-7 {
    padding-left: 5rem !important;
  }
  .p-lg-8 {
    padding: 6rem !important;
  }
  .pt-lg-8,
.py-lg-8 {
    padding-top: 6rem !important;
  }
  .pr-lg-8,
.px-lg-8 {
    padding-right: 6rem !important;
  }
  .pb-lg-8,
.py-lg-8 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-8,
.px-lg-8 {
    padding-left: 6rem !important;
  }
  .p-lg-9 {
    padding: 7.5rem !important;
  }
  .pt-lg-9,
.py-lg-9 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-9,
.px-lg-9 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-9,
.py-lg-9 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-9,
.px-lg-9 {
    padding-left: 7.5rem !important;
  }
  .p-lg-10 {
    padding: 8rem !important;
  }
  .pt-lg-10,
.py-lg-10 {
    padding-top: 8rem !important;
  }
  .pr-lg-10,
.px-lg-10 {
    padding-right: 8rem !important;
  }
  .pb-lg-10,
.py-lg-10 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-10,
.px-lg-10 {
    padding-left: 8rem !important;
  }
  .p-lg-11 {
    padding: 9rem !important;
  }
  .pt-lg-11,
.py-lg-11 {
    padding-top: 9rem !important;
  }
  .pr-lg-11,
.px-lg-11 {
    padding-right: 9rem !important;
  }
  .pb-lg-11,
.py-lg-11 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-11,
.px-lg-11 {
    padding-left: 9rem !important;
  }
  .p-lg-12 {
    padding: 10rem !important;
  }
  .pt-lg-12,
.py-lg-12 {
    padding-top: 10rem !important;
  }
  .pr-lg-12,
.px-lg-12 {
    padding-right: 10rem !important;
  }
  .pb-lg-12,
.py-lg-12 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-12,
.px-lg-12 {
    padding-left: 10rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4rem !important;
  }
  .mt-lg-n6,
.my-lg-n6 {
    margin-top: -4rem !important;
  }
  .mr-lg-n6,
.mx-lg-n6 {
    margin-right: -4rem !important;
  }
  .mb-lg-n6,
.my-lg-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n6,
.mx-lg-n6 {
    margin-left: -4rem !important;
  }
  .m-lg-n7 {
    margin: -5rem !important;
  }
  .mt-lg-n7,
.my-lg-n7 {
    margin-top: -5rem !important;
  }
  .mr-lg-n7,
.mx-lg-n7 {
    margin-right: -5rem !important;
  }
  .mb-lg-n7,
.my-lg-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n7,
.mx-lg-n7 {
    margin-left: -5rem !important;
  }
  .m-lg-n8 {
    margin: -6rem !important;
  }
  .mt-lg-n8,
.my-lg-n8 {
    margin-top: -6rem !important;
  }
  .mr-lg-n8,
.mx-lg-n8 {
    margin-right: -6rem !important;
  }
  .mb-lg-n8,
.my-lg-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n8,
.mx-lg-n8 {
    margin-left: -6rem !important;
  }
  .m-lg-n9 {
    margin: -7.5rem !important;
  }
  .mt-lg-n9,
.my-lg-n9 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n9,
.mx-lg-n9 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n9,
.my-lg-n9 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n9,
.mx-lg-n9 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n10 {
    margin: -8rem !important;
  }
  .mt-lg-n10,
.my-lg-n10 {
    margin-top: -8rem !important;
  }
  .mr-lg-n10,
.mx-lg-n10 {
    margin-right: -8rem !important;
  }
  .mb-lg-n10,
.my-lg-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n10,
.mx-lg-n10 {
    margin-left: -8rem !important;
  }
  .m-lg-n11 {
    margin: -9rem !important;
  }
  .mt-lg-n11,
.my-lg-n11 {
    margin-top: -9rem !important;
  }
  .mr-lg-n11,
.mx-lg-n11 {
    margin-right: -9rem !important;
  }
  .mb-lg-n11,
.my-lg-n11 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n11,
.mx-lg-n11 {
    margin-left: -9rem !important;
  }
  .m-lg-n12 {
    margin: -10rem !important;
  }
  .mt-lg-n12,
.my-lg-n12 {
    margin-top: -10rem !important;
  }
  .mr-lg-n12,
.mx-lg-n12 {
    margin-right: -10rem !important;
  }
  .mb-lg-n12,
.my-lg-n12 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n12,
.mx-lg-n12 {
    margin-left: -10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
.my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
.mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
.my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
.mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .mt-xl-7,
.my-xl-7 {
    margin-top: 5rem !important;
  }
  .mr-xl-7,
.mx-xl-7 {
    margin-right: 5rem !important;
  }
  .mb-xl-7,
.my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-7,
.mx-xl-7 {
    margin-left: 5rem !important;
  }
  .m-xl-8 {
    margin: 6rem !important;
  }
  .mt-xl-8,
.my-xl-8 {
    margin-top: 6rem !important;
  }
  .mr-xl-8,
.mx-xl-8 {
    margin-right: 6rem !important;
  }
  .mb-xl-8,
.my-xl-8 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-8,
.mx-xl-8 {
    margin-left: 6rem !important;
  }
  .m-xl-9 {
    margin: 7.5rem !important;
  }
  .mt-xl-9,
.my-xl-9 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-9,
.mx-xl-9 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-9,
.my-xl-9 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-9,
.mx-xl-9 {
    margin-left: 7.5rem !important;
  }
  .m-xl-10 {
    margin: 8rem !important;
  }
  .mt-xl-10,
.my-xl-10 {
    margin-top: 8rem !important;
  }
  .mr-xl-10,
.mx-xl-10 {
    margin-right: 8rem !important;
  }
  .mb-xl-10,
.my-xl-10 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-10,
.mx-xl-10 {
    margin-left: 8rem !important;
  }
  .m-xl-11 {
    margin: 9rem !important;
  }
  .mt-xl-11,
.my-xl-11 {
    margin-top: 9rem !important;
  }
  .mr-xl-11,
.mx-xl-11 {
    margin-right: 9rem !important;
  }
  .mb-xl-11,
.my-xl-11 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-11,
.mx-xl-11 {
    margin-left: 9rem !important;
  }
  .m-xl-12 {
    margin: 10rem !important;
  }
  .mt-xl-12,
.my-xl-12 {
    margin-top: 10rem !important;
  }
  .mr-xl-12,
.mx-xl-12 {
    margin-right: 10rem !important;
  }
  .mb-xl-12,
.my-xl-12 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-12,
.mx-xl-12 {
    margin-left: 10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
.py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
.px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
.py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
.px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .pt-xl-7,
.py-xl-7 {
    padding-top: 5rem !important;
  }
  .pr-xl-7,
.px-xl-7 {
    padding-right: 5rem !important;
  }
  .pb-xl-7,
.py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-7,
.px-xl-7 {
    padding-left: 5rem !important;
  }
  .p-xl-8 {
    padding: 6rem !important;
  }
  .pt-xl-8,
.py-xl-8 {
    padding-top: 6rem !important;
  }
  .pr-xl-8,
.px-xl-8 {
    padding-right: 6rem !important;
  }
  .pb-xl-8,
.py-xl-8 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-8,
.px-xl-8 {
    padding-left: 6rem !important;
  }
  .p-xl-9 {
    padding: 7.5rem !important;
  }
  .pt-xl-9,
.py-xl-9 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-9,
.px-xl-9 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-9,
.py-xl-9 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-9,
.px-xl-9 {
    padding-left: 7.5rem !important;
  }
  .p-xl-10 {
    padding: 8rem !important;
  }
  .pt-xl-10,
.py-xl-10 {
    padding-top: 8rem !important;
  }
  .pr-xl-10,
.px-xl-10 {
    padding-right: 8rem !important;
  }
  .pb-xl-10,
.py-xl-10 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-10,
.px-xl-10 {
    padding-left: 8rem !important;
  }
  .p-xl-11 {
    padding: 9rem !important;
  }
  .pt-xl-11,
.py-xl-11 {
    padding-top: 9rem !important;
  }
  .pr-xl-11,
.px-xl-11 {
    padding-right: 9rem !important;
  }
  .pb-xl-11,
.py-xl-11 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-11,
.px-xl-11 {
    padding-left: 9rem !important;
  }
  .p-xl-12 {
    padding: 10rem !important;
  }
  .pt-xl-12,
.py-xl-12 {
    padding-top: 10rem !important;
  }
  .pr-xl-12,
.px-xl-12 {
    padding-right: 10rem !important;
  }
  .pb-xl-12,
.py-xl-12 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-12,
.px-xl-12 {
    padding-left: 10rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4rem !important;
  }
  .mt-xl-n6,
.my-xl-n6 {
    margin-top: -4rem !important;
  }
  .mr-xl-n6,
.mx-xl-n6 {
    margin-right: -4rem !important;
  }
  .mb-xl-n6,
.my-xl-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n6,
.mx-xl-n6 {
    margin-left: -4rem !important;
  }
  .m-xl-n7 {
    margin: -5rem !important;
  }
  .mt-xl-n7,
.my-xl-n7 {
    margin-top: -5rem !important;
  }
  .mr-xl-n7,
.mx-xl-n7 {
    margin-right: -5rem !important;
  }
  .mb-xl-n7,
.my-xl-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n7,
.mx-xl-n7 {
    margin-left: -5rem !important;
  }
  .m-xl-n8 {
    margin: -6rem !important;
  }
  .mt-xl-n8,
.my-xl-n8 {
    margin-top: -6rem !important;
  }
  .mr-xl-n8,
.mx-xl-n8 {
    margin-right: -6rem !important;
  }
  .mb-xl-n8,
.my-xl-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n8,
.mx-xl-n8 {
    margin-left: -6rem !important;
  }
  .m-xl-n9 {
    margin: -7.5rem !important;
  }
  .mt-xl-n9,
.my-xl-n9 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n9,
.mx-xl-n9 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n9,
.my-xl-n9 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n9,
.mx-xl-n9 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n10 {
    margin: -8rem !important;
  }
  .mt-xl-n10,
.my-xl-n10 {
    margin-top: -8rem !important;
  }
  .mr-xl-n10,
.mx-xl-n10 {
    margin-right: -8rem !important;
  }
  .mb-xl-n10,
.my-xl-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n10,
.mx-xl-n10 {
    margin-left: -8rem !important;
  }
  .m-xl-n11 {
    margin: -9rem !important;
  }
  .mt-xl-n11,
.my-xl-n11 {
    margin-top: -9rem !important;
  }
  .mr-xl-n11,
.mx-xl-n11 {
    margin-right: -9rem !important;
  }
  .mb-xl-n11,
.my-xl-n11 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n11,
.mx-xl-n11 {
    margin-left: -9rem !important;
  }
  .m-xl-n12 {
    margin: -10rem !important;
  }
  .mt-xl-n12,
.my-xl-n12 {
    margin-top: -10rem !important;
  }
  .mr-xl-n12,
.mx-xl-n12 {
    margin-right: -10rem !important;
  }
  .mb-xl-n12,
.my-xl-n12 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n12,
.mx-xl-n12 {
    margin-left: -10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 960px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 960px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 960px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.row.gutter-1 {
  margin-left: -5px;
  margin-right: -5px;
}

.row.gutter-1 > .col,
.row.gutter-1 > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.row.gutter-2 {
  margin-left: -30px;
  margin-right: -30px;
}

.row.gutter-2 > .col,
.row.gutter-2 > [class*=col-] {
  padding-right: 30px;
  padding-left: 30px;
}

.rounded-2 {
  border-radius: 20px;
}

html, body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  line-height: 1.2;
  color: #2B2B2B;
}
@media (max-width: 960px) {
  html, body {
    font-size: 15px;
  }
}
@media (max-width: 576px) {
  html, body {
    font-size: 12px;
  }
}

* {
  box-sizing: border-box;
}

*, *:active, *:focus {
  outline: 0 !important;
  -moz-outline-style: none !important;
}

a[href^=tel] {
  white-space: nowrap;
}

:not(.page-content) a[href^=tel] {
  color: inherit;
  text-decoration: inherit;
}

a {
  color: #4c6885;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

h1, .h1, .h1_regular {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.625rem;
  line-height: 3.375rem;
  color: #6a6a6a;
  letter-spacing: 0.0135em;
}
@media (max-width: 600px) {
  h1, .h1, .h1_regular {
    font-size: 1.96875rem;
    line-height: 2.53125rem;
  }
}
h1 a, .h1 a, .h1_regular a {
  color: inherit;
  text-decoration: none;
}

.h1_regular {
  font-weight: normal;
}

h2, .h2 {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.125rem;
  line-height: 2.5rem;
  color: #6a6a6a;
  margin: 1em 0 0.6em;
  letter-spacing: 0.01em;
}
@media (max-width: 600px) {
  h2, .h2 {
    font-size: 1.7rem;
    line-height: 2rem;
  }
}
h2 a, .h2 a {
  color: inherit;
  text-decoration: none;
}

h3, .h3, .h3_bigRedCircle-sub {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.625rem;
  line-height: 1.875rem;
  letter-spacing: 0.01em;
  margin: 0.8em 0;
}
@media (max-width: 600px) {
  h3, .h3, .h3_bigRedCircle-sub {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
}
h3 a, .h3 a, .h3_bigRedCircle-sub a {
  color: inherit;
  text-decoration: none;
}

.h3_circle, .redCircle, .yellowCircle, .blueCircle, .greenCircle, .h3_blueCircle, .h3_bigRedCircle, .h3_redCircle {
  position: relative;
  height: 90px;
  display: flex;
  align-items: center;
}
.h3_circle:before, .redCircle:before, .yellowCircle:before, .blueCircle:before, .greenCircle:before, .h3_blueCircle:before, .h3_bigRedCircle:before, .h3_redCircle:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%) translateX(-33%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #d5e8c4;
}
@media (max-width: 576px) {
  .h3_circle, .redCircle, .yellowCircle, .blueCircle, .greenCircle, .h3_blueCircle, .h3_bigRedCircle, .h3_redCircle {
    height: 67.5px;
  }
  .h3_circle:before, .redCircle:before, .yellowCircle:before, .blueCircle:before, .greenCircle:before, .h3_blueCircle:before, .h3_bigRedCircle:before, .h3_redCircle:before {
    width: 67.5px;
    height: 67.5px;
  }
}

.h3_redCircle:before {
  background: #f8c6c5;
}

.h3_bigRedCircle {
  display: block;
  padding-top: 8px;
}
.h3_bigRedCircle:before {
  background: #f8c6c5;
  width: 110px;
  height: 110px;
  left: -5px;
}

.h3_bigRedCircle-sub {
  display: block;
  padding: 0;
  margin: -2px 0 0 0;
  line-height: 1.1em;
}

.h3_blueCircle:before {
  background: #a7c0da;
}

.blueCircle:before {
  background: #a7c0da;
}

.yellowCircle:before {
  background: #f9e8bd;
}

.redCircle:before {
  background: #f8c6c5;
}

p {
  margin: 0.8em 0 0.8em;
}

.t0 {
  font-size: 0.875rem;
}

p, .t1, li {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1875rem;
  line-height: 1.75rem;
}
@media (max-width: 600px) {
  p, .t1, li {
    font-size: 1.125rem;
  }
}

li {
  margin-bottom: 0.4em;
}

hr {
  height: 1px;
  border: none;
  padding: 0;
  background: #bfbfbf;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.t2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
}

.link {
  font-family: "Roboto Condensed", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 1.0625rem !important;
  line-height: 1.4375rem !important;
  text-decoration-line: underline !important;
  color: #4c6885 !important;
}

.presentationText, .presentation-text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.625rem;
  line-height: 2.25rem;
  color: #2b2b2b;
}
@media (max-width: 960px) {
  .presentationText, .presentation-text {
    font-size: 1.4625rem;
    line-height: 2.025rem;
  }
}

.miniText {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #808080;
}

.lineHeight-0 {
  line-height: 0 !important;
}

.lightgray {
  color: #bfbfbf !important;
}

.gray {
  color: #808080 !important;
}

.darkgray {
  color: #6a6a6a !important;
}

.red {
  color: #e26360 !important;
}

.blue {
  color: #4c6885 !important;
}

.black {
  color: #2b2b2b !important;
}

.flampLink {
  display: inline-flex;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  color: #4C6885;
}
.flampLink:before {
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -104px -224px;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  transform: scale(0.8);
  transform-origin: right center;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .flampLink:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.redPenLink {
  display: inline-flex;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  color: #4C6885;
}
.redPenLink:before {
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -204px -224px;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  transform: scale(0.8);
  transform-origin: right center;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .redPenLink:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.flampLink_white {
  color: #fff;
}
.flampLink_white:before {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -154px -224px;
  width: 48px;
  height: 48px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .flampLink_white:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.redPenLink_white {
  color: #fff;
}
.redPenLink_white:before {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -254px -224px;
  width: 48px;
  height: 48px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .redPenLink_white:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.robotoCondensed {
  font-family: "Roboto Condensed", sans-serif;
}

.openSans {
  font-family: "Open Sans", sans-serif;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes appearFromLeft {
  from {
    transform: translateX(-10%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.content {
  width: 92%;
  max-width: 1180px;
  margin: 0 auto;
}
.content img {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
}

.page {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2);
  max-width: 1920px;
  margin: 0 auto;
}

.page_noBottomPadding .page-content {
  padding-bottom: 0;
}

.page-header {
  padding-bottom: 16px;
}

.page-content {
  flex-grow: 2;
  padding-bottom: 6.4rem;
}

.page-footer {
  padding: 1px;
  background: #F7F7F7;
}

.header {
  width: 96%;
  max-width: 1180px;
  margin: 0 auto;
}

.header-wa {
  display: inline-block;
  margin-right: 10px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -118px -120px;
  width: 18px;
  height: 18px;
  position: relative;
  top: -1px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .header-wa {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.header-right_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-ws {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.header-logo {
  display: flex;
  align-items: center;
  padding-top: 1.6%;
  padding-right: 49px;
}
.header-logo img {
  width: 235px;
}

.header-links {
  padding-top: 12px;
}

.header-phone {
  font-size: 16px;
  font-weight: 400;
  color: #2B2B2B;
}

.header-callback {
  margin: 0 33px 0 13px;
  transform: translateY(-4px);
}

.header-top {
  padding-top: 15px;
}

.header-bottom {
  display: flex;
  padding-top: 32px;
}

.header-menu {
  flex-grow: 1;
}

.header-menuCtrl {
  margin-left: 34px;
  margin-top: -17px;
}

@media (max-width: 1150px) {
  .header-logo {
    padding-right: 20px;
  }
  .header-logo img {
    width: 200px;
  }
  .header-phone {
    font-size: 1.25rem;
  }
}
@media (max-width: 900px) {
  .page-header {
    padding: 16px 0;
  }
  .header-logo {
    padding-top: 0;
  }
  .header-top, .header-menu {
    display: none;
  }
  .header-bottom {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .header-menuCtrl {
    margin: 0;
  }
}
@media (max-width: 600px) {
  .header-logo img {
    width: 140px;
  }
}
.mainMenu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
}
.mainMenu li {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.mainMenu li a {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
  color: #2b2b2b;
  letter-spacing: 0.01em;
  transition: 0.12s;
}
.mainMenu li a:hover {
  color: #7DB260;
}

@media (max-width: 1150px) {
  .mainMenu li a {
    font-size: 18px;
  }
}
@media (max-width: 1100px) {
  .mainMenu li a {
    font-size: 16px;
  }
}
.menuCtrl {
  width: 52px;
  height: 52px;
  border: 1px solid rgba(226, 99, 96, 0.5);
  position: relative;
  border-radius: 50%;
}
.menuCtrl span {
  width: 19px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  background: rgba(226, 99, 96, 0.5);
  transform: translateX(-50%) rotate(0);
  transition: background-color 0.2s, opacity 0.2s, top 0.1s 0.1s ease-out, margin-top 0.1s 0.1s ease-out, transform 0.1s ease-out;
}
.menuCtrl span:nth-child(1) {
  top: 32%;
}
.menuCtrl span:nth-child(2) {
  width: 25px;
  margin-top: -1px;
  margin-left: 3px;
  opacity: 1;
}
.menuCtrl span:nth-child(3) {
  top: 63%;
}
@media (pointer: fine) {
  .menuCtrl span {
    transition: 0.1s;
  }
  .menuCtrl:hover span:nth-child(1), .menuCtrl:hover span:nth-child(3) {
    width: 25px;
  }
  .menuCtrl:hover span:nth-child(2) {
    width: 19px;
    margin-left: -3px;
  }
}

@media (max-width: 900px) {
  .header .menuCtrl {
    position: fixed;
    right: 2%;
    top: 25px;
    z-index: 1000;
    background: #ffffff;
    box-shadow: 0 0 0 4px #ffffff;
  }
}
@media (max-width: 600px) {
  .header .menuCtrl {
    top: 16px;
  }
}

.menuCtrl_white {
  border-color: #7990A8;
  opacity: 1;
}
.menuCtrl_white span {
  background: #fff;
}

.menuCtrl_close span {
  transition: background-color 0.2s, opacity 0.2s, top 0.1s ease-out, margin-top 0.1s ease-out, transform 0.1s 0.1s ease-out;
}
.menuCtrl_close span:nth-of-type(1) {
  top: 50%;
  width: 25px;
  margin-top: -1px;
  transform: translateX(-50%) rotate(45deg);
}
.menuCtrl_close span:nth-of-type(2) {
  top: 50%;
  margin-top: -1px;
  opacity: 0;
}
.menuCtrl_close span:nth-of-type(3) {
  top: 50%;
  width: 25px;
  margin-top: -1px;
  transform: translateX(-50%) rotate(-45deg);
}

.footer {
  max-width: 1180px;
  width: 92%;
  margin: 0 auto;
}

.footer-logo img {
  width: 180px;
}
@media (max-width: 576px) {
  .footer-logo img {
    width: 153px;
  }
}

.footer-top {
  margin-bottom: 33px;
  padding-top: 54px;
}
@media (max-width: 576px) {
  .footer-top {
    padding-top: 39px;
  }
}

.footer-bottom {
  color: #808080;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  padding-bottom: 1px;
}

.footer-copy {
  font-size: 13px;
  display: inline-block;
  margin-bottom: 16px;
}

.footer-idea {
  color: #A1A1A1;
  font-size: 13px;
}
.footer-idea a {
  color: inherit;
  text-decoration: none;
}

.footer-phone {
  color: #2B2B2B;
  font-size: 1.625rem;
  font-weight: bold;
  margin-bottom: 9px;
}

.footer-address {
  color: #808080;
  font-size: 1.125rem;
  margin-bottom: 9px;
}

.footer-email {
  margin-bottom: 33px;
}
.footer-email a {
  color: #4C6885;
  font-size: 1.0625rem;
}

.footer-socialIcon + .footer-socialIcon {
  margin-left: 5px;
}

.footerMenu {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .footerMenu {
    margin: 11px 0 0;
  }
}
.footerMenu li {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0 0 16px;
  width: 47%;
}
@media (max-width: 576px) {
  .footerMenu li {
    margin: 0 0 6px;
  }
}
.footerMenu li a {
  font-size: 1.125rem;
  font-family: "Roboto Condensed", sans-serif;
  color: #2B2B2B;
  text-decoration: none;
}

.btn1 {
  color: #fff;
  background: #7DB260;
  border-radius: 30px;
  height: 44px;
  min-height: 44px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-decoration: none;
  padding: 0 27px;
  letter-spacing: 0.01em;
  font-family: "Roboto Condensed", sans-serif;
  border: none;
}
@media (pointer: fine) {
  .btn1 {
    transition: 0.1s;
  }
  .btn1:hover {
    background: #a9cf8d;
  }
}

.btn1_violet {
  background: #5E6280;
}
@media (pointer: fine) {
  .btn1_violet:hover {
    background: #8a8ca2;
  }
}

.btn1_white {
  background: #fff;
  color: #808080 !important;
}
@media (pointer: fine) {
  .btn1_white:hover {
    background: #E5E5E5;
    color: #333 !important;
  }
}

.btn1_whiteBorder {
  background: transparent;
  border: 1px solid #fff;
  color: #fff !important;
}
@media (pointer: fine) {
  .btn1_whiteBorder:hover {
    background: transparent;
    color: #333 !important;
  }
}

.btn1_blue {
  background: #6584A4;
}
@media (pointer: fine) {
  .btn1_blue:hover {
    background: #8fa5bd;
  }
}

.btn1_red {
  background: #E26360;
}
@media (pointer: fine) {
  .btn1_red:hover {
    background: #ea8d8b;
  }
}

.btn1_gray {
  background: #BFBFBF;
}
@media (pointer: fine) {
  .btn1_gray:hover {
    background: #d0d0d0;
  }
}

.btn1_bell {
  padding-left: 18px;
}
.btn1_bell:before {
  margin: 0 15px 0 0;
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -99px -120px;
  width: 17px;
  height: 20px;
  transition: 0.1s;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn1_bell:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
@media (pointer: fine) {
  .btn1_bell:hover:before {
    transform: rotate(33deg);
  }
}

.btn1_whiteStar {
  padding-left: 18px;
}
.btn1_whiteStar:before {
  margin: 0 5px 0 0;
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -246px -176px;
  width: 29px;
  height: 28px;
  transform: scale(0.7);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn1_whiteStar:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn1_whitePrice {
  padding-left: 18px;
}
.btn1_whitePrice:before {
  margin: 0 5px 0 0;
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -272px -278px;
  width: 26px;
  height: 26px;
  transform: scale(0.7);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn1_whitePrice:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn1_grill {
  padding-left: 19px;
}
.btn1_grill:before {
  margin: 0 7px 0 0;
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -300px -278px;
  width: 24px;
  height: 26px;
  transform: scale(0.7);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn1_grill:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn1_back {
  padding-left: 18px;
}
.btn1_back:before {
  margin: 0 15px 0 0;
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -153px -104px;
  width: 22px;
  height: 8px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn1_back:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn2 {
  position: relative;
  display: inline-block;
  color: #4C6885;
  text-decoration: none;
  letter-spacing: 0.01em;
}
.btn2:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: rgba(76, 104, 133, 0.2);
  transition: 0.12s;
}
.btn2:hover:after {
  background: #4c6885;
}
@media (max-width: 960px) {
  .btn2 {
    font-size: 16px;
  }
}

.btn2_leaf, .btn2_leaf-green {
  margin-left: 31px;
}
.btn2_leaf:before, .btn2_leaf-green:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -31px;
  top: -1px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: 0px -316px;
  width: 22px;
  height: 22px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn2_leaf:before, .btn2_leaf-green:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn2_leaf-green:before {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -326px -278px;
  width: 22px;
  height: 22px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn2_leaf-green:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn2_star, .btn2_star-yellow {
  margin-left: 31px;
}
@media (max-width: 988px) {
  .btn2_star, .btn2_star-yellow {
    margin-left: 39px;
    margin-top: 5px;
  }
}
.btn2_star:before, .btn2_star-yellow:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -31px;
  top: -1px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -72px -316px;
  width: 22px;
  height: 22px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn2_star:before, .btn2_star-yellow:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn2_star-yellow:before {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -48px -316px;
  width: 22px;
  height: 22px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn2_star-yellow:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn2_price, .btn2_price-red {
  margin-left: 31px;
}
@media (max-width: 988px) {
  .btn2_price, .btn2_price-red {
    margin-left: 39px;
    margin-top: 5px;
  }
}
.btn2_price:before, .btn2_price-red:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -31px;
  top: -1px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -24px -316px;
  width: 22px;
  height: 22px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn2_price:before, .btn2_price-red:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn2_price-red:before {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -24px -316px;
  width: 22px;
  height: 22px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn2_price-red:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn3 {
  color: #fff;
  background: #7DB260;
  border-radius: 30px;
  height: 54px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.375rem;
  text-decoration: none;
  padding: 0 30px;
  letter-spacing: 0.01em;
}

.btn3_blue {
  background: #6584A4;
}

.btn3_whiteStar {
  padding-left: 18px;
}
.btn3_whiteStar:before {
  margin: 0 10px 0 0;
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -246px -176px;
  width: 29px;
  height: 28px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn3_whiteStar:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn3_grill {
  padding-left: 19px;
}
.btn3_grill:before {
  margin: 0 17px 0 0;
  content: "";
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -300px -278px;
  width: 24px;
  height: 26px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .btn3_grill:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.btn4 {
  display: inline-flex;
  text-decoration: none;
  height: 2.75rem;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  padding: 0 24px;
}

.btn5 {
  display: inline-flex;
  vertical-align: middle;
  height: 5rem;
  background: #ececec;
  border-radius: 96px;
  font-family: "Open Sans", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  color: #2B2B2B;
  text-decoration: none;
  padding: 0 3rem;
  align-items: center;
}

.btn5_red {
  background: #e26360;
  color: #fff;
}
@media (pointer: fine) {
  .btn5_red:hover {
    background: #ea8d8b;
  }
}

.iconVk {
  display: inline-block;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -114px -278px;
  width: 36px;
  height: 36px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iconVk {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.iconFb {
  display: inline-block;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: 0px -278px;
  width: 36px;
  height: 36px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iconFb {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.iconInst {
  display: inline-block;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -76px -278px;
  width: 36px;
  height: 36px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iconInst {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.iconYou {
  display: inline-block;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -152px -278px;
  width: 36px;
  height: 36px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iconYou {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.fox-logo {
  display: inline-block;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -99px 0px;
  width: 100px;
  height: 64px;
  margin-top: 1.5625rem;
  margin-bottom: 0.9375rem;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .fox-logo {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.topDropDown {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000002;
  background: url("/img/leaves-dark-blue.jpg");
  transform: translateY(-100%);
  transition: 0.2s;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0);
}

.topDropDown_visible {
  transform: translateY(0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
}

.topDown-scroll {
  height: 100%;
  overflow: auto;
}

.topDropDown-content {
  width: 92%;
  max-width: 1180px;
  margin: 0 auto;
}

.topDropDown-topRow {
  padding: 73px 0 67px;
}

.topDropDown-btn {
  margin-right: 16px;
  vertical-align: bottom;
  margin-bottom: 15px;
}

.topDropDown-close {
  position: absolute;
  right: 4%;
  top: 69px;
}
@media (max-width: ) {
  .topDropDown-close {
    top: 27px;
  }
}

.topDropDown-mobileTop {
  margin-bottom: 40px;
  display: none;
}

.topDropDown-phone {
  color: #fff;
  font-size: 1.5625rem;
  font-weight: bold;
  margin-bottom: 8px;
  display: inline-block;
}

.topDropDown-callBack {
  color: #fff;
  font-size: 1rem;
}

@media (max-width: 900px) {
  .topDropDown-mobileTop {
    display: block;
  }
  .topDropDown-close {
    top: 30px;
    right: 7%;
  }
  .topDropDown-topRow {
    padding: 30px 0;
  }
}
.topMenu {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 700px;
  margin: 0 -1rem -1rem 0;
}
@media (max-width: 992px) {
  .topMenu {
    max-height: 900px;
  }
}
@media (max-width: 960px) {
  .topMenu {
    padding-bottom: 40px;
  }
}

.topMenu-item {
  padding-right: 45px;
  padding-bottom: 35px;
  max-width: 300px;
}
@media (max-width: 960px) {
  .topMenu-item {
    max-width: 200px;
    padding-right: 0;
    margin: 0 1rem 1rem 0;
  }
}
@media (max-width: 960px) {
  .topMenu-item .topMenu-item {
    max-width: 200px;
    padding-right: 0;
    margin: 0 1rem 1rem 0;
  }
}
@media (max-width: 900px) {
  .topMenu-item .topMenu-item {
    padding-bottom: 10px;
  }
  .topMenu-item .topMenu-item a {
    font-size: 20px !important;
  }
}

.topMenu-title {
  color: #fff;
  text-decoration: none;
  font-size: 1.625rem;
  font-family: "Roboto Condensed";
  font-weight: bold;
}

.topMenu-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  background: none;
}
.topMenu-menu li {
  margin: 20px 0;
}
.topMenu-menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1875rem;
  font-family: "Open Sans";
}

@media (max-width: 992px) {
  .topMenu-menu {
    display: none;
  }
  .topMenu-item {
    padding-bottom: 5px;
  }
  .topMenu-item a {
    font-size: 22px !important;
  }
}
@media (max-width: 900px) {
  .topMenu-title {
    font-size: 1.5rem;
  }
  .topMenu-menu li {
    margin: 10px 0;
  }
  .topMenu-menu li a {
    font-size: 1rem !important;
  }
}
@media (max-width: 600px) {
  .topMenu-menu li {
    margin: 5px 0;
  }
}
body.coreSiteMode .slider {
  position: relative;
  overflow: hidden;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  margin: 4em 0;
  opacity: 0;
  transition: opaity 0.1s;
}
body.coreSiteMode .slider_visible {
  opacity: 1;
}
body.coreSiteMode .slider-content {
  position: relative;
  left: 50%;
  width: 100vw;
}
body.coreSiteMode .slider-slide {
  position: relative;
  left: 0;
  width: 1000%;
  overflow: hidden;
}
body.coreSiteMode .slider-item {
  display: none;
  float: left;
  padding: 0 55px;
  max-width: 80vw;
}
@media (max-width: 960px) {
  body.coreSiteMode .slider-item {
    max-width: 90vw;
    padding: 0 30px;
  }
}
@media (max-width: 576px) {
  body.coreSiteMode .slider-item {
    max-width: 100vw;
    padding: 0 10px;
  }
}
body.coreSiteMode .slider-item:nth-of-type(1), body.coreSiteMode .slider-item:nth-of-type(2), body.coreSiteMode .slider-item:nth-of-type(3), body.coreSiteMode .slider-item:nth-of-type(4), body.coreSiteMode .slider-item:nth-of-type(5) {
  display: block;
}
body.coreSiteMode .slider-ctrl {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  transform: translateX(-50%);
}
body.coreSiteMode .slider-left, body.coreSiteMode .slider-right {
  position: absolute;
  top: 0;
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
body.coreSiteMode .slider-left:after, body.coreSiteMode .slider-right:after {
  content: "";
  position: absolute;
  top: 50%;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -337px -164px;
  width: 22px;
  height: 48px;
}
body.coreSiteMode .slider-left {
  left: 40px;
  transform: translateX(-100%);
}
body.coreSiteMode .slider-left:after {
  right: 20px;
  transform: translateY(-50%) rotate(180deg);
}
@media (max-width: 576px) {
  body.coreSiteMode .slider-left {
    left: 60px;
  }
}
body.coreSiteMode .slider-right {
  right: 40px;
  transform: translateX(100%);
}
body.coreSiteMode .slider-right:after {
  left: 20px;
  transform: translateY(-50%);
}
@media (max-width: 576px) {
  body.coreSiteMode .slider-right {
    right: 60px;
  }
}

body.coreEditorMode .slider-content {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}
body.coreEditorMode .slider-item {
  display: inline-block;
  width: 400px;
}
body.coreEditorMode .slider-item img {
  max-width: 100%;
}
body.coreEditorMode .slider-item + .slider-item {
  margin-left: 10px;
}

.slider2 {
  position: relative;
}

.slider2-item {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.45s;
}
.slider2-item:nth-of-type(1) {
  position: relative;
}
@media (max-width: 960px) {
  .slider2-item {
    display: flex;
    flex-direction: column;
  }
}

.slider2-item_visible {
  opacity: 1;
  z-index: 1;
}

.slider2-dots {
  position: absolute;
  left: 0;
  top: 50%;
  height: 400px;
  width: 500px;
  background: rgba(255, 0, 0, 0.4);
  z-index: 3;
  transform: translateY(-50%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  pointer-events: none;
  display: none;
}

.slider2-dot {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 7px;
  pointer-events: unset;
}
.slider2-dot:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateX(-50%) translateY(-50%);
  transition: 0.2s;
}
.slider2-dot.slider2-dot_current:after {
  width: 20px;
  height: 20px;
  background: white;
}

.slider2-text {
  position: absolute;
  left: 0;
  top: 50%;
  width: 500px;
  height: 400px;
  transform: translateY(-50%);
  background: url("/img/leaves-violet.jpg");
  color: #fff;
  text-align: center;
  padding: 12px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 960px) {
  .slider2-text {
    position: static;
    transform: none;
    order: 1;
    width: 100%;
    height: auto;
  }
}
.slider2-text p {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.375rem;
}
.slider2-text .btn1 {
  margin-top: 2.2em;
  padding: 0 2.2em;
}

.slider2-img {
  display: block;
  float: right;
}
.slider2-img img {
  display: block;
  max-width: 100%;
}
@media (max-width: 960px) {
  .slider2-img img {
    width: 100%;
  }
}

.slider2-left, .slider2-right {
  position: absolute;
  display: block;
  height: 100%;
  width: 150px;
  z-index: 3;
}
@media (max-width: 1400px) {
  .slider2-left, .slider2-right {
    width: 50px;
  }
}
.slider2-left:after, .slider2-right:after {
  content: "";
  position: absolute;
  top: 50%;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -337px -164px;
  width: 22px;
  height: 48px;
}
@media (max-width: 960px) {
  .slider2-left:after, .slider2-right:after {
    top: auto;
    bottom: -6px;
  }
}

.slider2-left {
  left: 0;
  transform: translateX(-100%);
}
@media (max-width: 1400px) {
  .slider2-left {
    transform: translateX(0);
  }
}
.slider2-left:after {
  right: 50%;
  transform: translateY(-50%) rotate(180deg);
}
@media (max-width: 1400px) {
  .slider2-left:after {
    right: 5px;
  }
}
@media (max-width: 960px) {
  .slider2-left:after {
    right: auto;
    left: 5px;
  }
}
@media (max-width: 576px) {
  .slider2-left:after {
    transform: translateY(-50%) rotate(180deg) scale(0.6);
  }
}

.slider2-right {
  right: 0;
  transform: translateX(100%);
}
@media (max-width: 1400px) {
  .slider2-right {
    transform: translateX(0);
  }
}
.slider2-right:after {
  left: 50px;
  transform: translateY(-50%);
}
@media (max-width: 1400px) {
  .slider2-right:after {
    left: 5px;
  }
}
@media (max-width: 960px) {
  .slider2-right:after {
    left: auto;
    right: 5px;
  }
}
@media (max-width: 576px) {
  .slider2-right:after {
    transform: translateY(-50%) scale(0.6);
  }
}

@media (max-width: 960px) {
  .slider2-bottom-text {
    z-index: 999;
    position: relative;
    right: 25px;
    top: 25px;
  }
}
.slider3 {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  max-width: 580px;
  width: 100%;
  height: 83%;
}
.slider3 :first-child {
  margin-top: 0;
}
.slider3 :last-child {
  margin-bottom: 0;
}
.slider3 h2, .slider3 p {
  color: #fff;
}
@media (max-width: 960px) {
  .slider3 {
    height: auto;
  }
}

.slider3-content {
  padding: 0 70px;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
@media (max-width: 576px) {
  .slider3-content {
    padding: 0 20px;
  }
}

.slider3-item {
  display: none;
}

.slider3-text {
  padding-bottom: 52px;
}

.slider3-ctrl {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 10px;
  text-align: center;
}

.slider3-btn {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 7px;
}
.slider3-btn:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  transform: translateX(-50%) translateY(-50%);
  transition: 0.2s;
}
.slider3-btn.slider3-btn_current:after {
  width: 20px;
  height: 20px;
  background: white;
}

.slider3-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(50%) translateY(-50%);
  width: 110px;
  height: 110px;
  background: white;
  border-radius: 50%;
}
@media (max-width: 960px) {
  .slider3-right {
    transform: translateY(-50%) scale(0.5);
    transform-origin: right center;
  }
}
.slider3-right:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -337px -164px;
  width: 22px;
  height: 48px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .slider3-right:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

body.coreSiteMode .slider4 {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
}
body.coreSiteMode .slider4-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 1000%;
}
body.coreSiteMode .slider4-item {
  display: none;
  float: left;
  width: 10%;
}
body.coreSiteMode .slider4-item img {
  display: block;
  width: 100% !important;
  height: auto !important;
}
body.coreSiteMode .slider4-item:nth-of-type(1), body.coreSiteMode .slider4-item:nth-of-type(2), body.coreSiteMode .slider4-item:nth-of-type(3), body.coreSiteMode .slider4-item:nth-of-type(4) {
  display: block;
}
body.coreSiteMode .slider4-left, body.coreSiteMode .slider4-right {
  position: absolute;
  top: 0;
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  width: 62px;
}
body.coreSiteMode .slider4-left:after, body.coreSiteMode .slider4-right:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -337px -164px;
  width: 22px;
  height: 48px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.coreSiteMode .slider4-left:after, body.coreSiteMode .slider4-right:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
body.coreSiteMode .slider4-left {
  left: 0;
}
body.coreSiteMode .slider4-left:after {
  transform: translateX(-50%) translateY(-50%) rotate(180deg);
}
body.coreSiteMode .slider4-right {
  right: 0;
}
body.coreSiteMode .slider4-right:after {
  transform: translateX(-50%) translateY(-50%);
}
body.coreSiteMode .slider4-pages {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 45px;
  text-align: center;
}
body.coreSiteMode .slider4-page {
  background: #fff;
  opacity: 0.3;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 20px;
  padding: 0;
}
body.coreSiteMode .slider4-page_current {
  opacity: 1;
  transform: scale(1.6);
}

body.coreEditorMode .slider4 {
  max-width: 320px;
}
body.coreEditorMode .slider4-content {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}
body.coreEditorMode .slider4-item {
  display: inline-block;
  width: 300px;
}
body.coreEditorMode .slider4-item img {
  max-width: 100%;
}
body.coreEditorMode .slider4-item + .slider4-item {
  margin-left: 10px;
}

.slider5 {
  visibility: hidden;
  margin: 4.6em 0;
}
.slider5 h2, .slider5 .h2 {
  margin-bottom: 13px;
  margin-top: 17px;
}

.slider5_visible {
  visibility: visible;
}

.slider5-content {
  position: relative;
  width: 92%;
  max-width: 1180px;
  margin: 0 auto;
}
@media (max-width: 960px) {
  .slider5-content {
    width: 100%;
  }
}

.slider5-car {
  width: 10000%;
  position: relative;
  left: calc(-100% - 210px);
  display: flex;
  align-items: center;
}

.slider5-item {
  width: 1%;
  display: inline-flex;
  margin: 0 70px;
  opacity: 0.2;
}
.slider5-item:nth-of-type(2) {
  opacity: 1;
}
.slider5-item .slider5-img {
  transform: translateX(0);
  transition: 0.2s;
}
.slider5-item:nth-of-type(3) .slider5-img {
  transform: translateX(-120%);
  transition: 0.2s 0.2s;
}
@media (max-width: 960px) {
  .slider5-item:nth-of-type(3) .slider5-img {
    transform: translateX(0);
  }
}
@media (max-width: 960px) {
  .slider5-item {
    flex-wrap: wrap;
    max-height: 200px;
    transition: 0.4s;
  }
  .slider5-item:nth-of-type(2) {
    max-height: 1400px;
  }
}

.slider5_movingRight .slider5-item:nth-of-type(2) {
  opacity: 0.2;
}
.slider5_movingRight .slider5-item:nth-of-type(3) {
  opacity: 1;
  max-height: 1000px;
}
.slider5_movingRight .slider5-item:nth-of-type(3) .slider5-img {
  transform: translateX(0);
  transition: 0.2s;
}

.slider5-text {
  width: 59.2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 55px;
}
@media (max-width: 1200px) {
  .slider5-text {
    padding-left: 70px;
  }
}
@media (max-width: 960px) {
  .slider5-text {
    background: #7db260 url("/img/leaves-green.jpg") -122px -317px;
    width: 100%;
    color: #fff;
    padding-bottom: 20px;
  }
  .slider5-text p, .slider5-text h1, .slider5-text h2, .slider5-text h3 {
    color: inherit;
  }
}

.slider5-img {
  width: 50.8%;
}
@media (max-width: 1200px) {
  .slider5-img {
    width: 40.8%;
  }
}
@media (max-width: 960px) {
  .slider5-img {
    width: 100%;
    order: -1;
  }
}
.slider5-img img {
  width: 100%;
  display: block;
}

.slider5-left, .slider5-right {
  position: absolute;
  top: 0;
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  width: 62px;
  z-index: 2;
}
.slider5-left:after, .slider5-right:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -337px -164px;
  width: 22px;
  height: 48px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .slider5-left:after, .slider5-right:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.slider5-left {
  left: -90px;
}
.slider5-left:after {
  transform: translateX(-50%) translateY(-50%) rotate(180deg);
}
@media (max-width: 1200px) {
  .slider5-left {
    left: 0;
  }
}

.slider5-right {
  right: -31px;
}
.slider5-right:after {
  transform: translateX(-50%) translateY(-50%);
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -255px -82px;
  width: 22px;
  height: 48px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .slider5-right:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
.slider5-right:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: #C3D6B1;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
}
@media (max-width: 1200px) {
  .slider5-right {
    right: 0;
  }
}
@media (max-width: 960px) {
  .slider5-right:after {
    background-image: url(../img/sprite.png?t1685543367348);
    background-position: -337px -164px;
    width: 22px;
    height: 48px;
  }
}
@media (max-width: 960px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 960px) and (min-resolution: 192dpi) {
  .slider5-right:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
@media (max-width: 960px) {
  .slider5-right:before {
    display: none;
  }
}

.slider6 {
  position: relative;
}

.slider6-content {
  overflow: hidden;
}

.slider6-car {
  width: 10000%;
  overflow: hidden;
}

.slider6-item {
  width: 1%;
  float: left;
}

.slider6-left, .slider6-right {
  position: absolute;
  top: 0;
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  width: 62px;
  z-index: 2;
}
@media (max-width: 960px) {
  .slider6-left:before, .slider6-right:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
  }
}
.slider6-left:after, .slider6-right:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -255px -82px;
  width: 22px;
  height: 48px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .slider6-left:after, .slider6-right:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
@media (max-width: 576px) {
  .slider6-left, .slider6-right {
    transform: scale(0.8);
  }
}

.slider6-left {
  left: -80px;
}
.slider6-left:after {
  transform: translateX(-50%) translateY(-50%) rotate(180deg);
}
@media (max-width: 1200px) {
  .slider6-left {
    left: -52px;
  }
}
@media (max-width: 960px) {
  .slider6-left {
    left: -33px;
  }
}

.slider6-right {
  right: -80px;
}
.slider6-right:after {
  transform: translateX(-50%) translateY(-50%);
}
@media (max-width: 1200px) {
  .slider6-right {
    right: -52px;
  }
}
@media (max-width: 960px) {
  .slider6-right {
    right: -33px;
  }
}

.healthCenter-slider {
  max-width: 1180px;
  margin: 63px auto;
}
@media (max-width: 600px) {
  .healthCenter-slider {
    margin: 30px auto;
  }
}

.pageImage {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: 1920px;
  overflow: hidden;
}
.pageImage img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  max-height: 60vh;
  min-height: 300px;
  width: 100% !important;
  object-fit: cover;
}
.pageImage video {
  position: absolute;
  left: 50%;
  top: 0;
  min-height: 300px;
  transform: translateX(-50%);
  display: block;
  max-height: 60vh;
  width: 100% !important;
  object-fit: cover;
}

@media (max-width: 960px) {
  .pageImage_mdToContent {
    height: auto !important;
    min-height: auto;
    padding: 1px;
  }
  .pageImage_mdToContent img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    object-fit: cover;
  }
}

.pageImage-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(100px);
}
.pageImage-text:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 24px;
  left: 0;
  top: 0;
  background: #e26360;
  transform: translateX(-100%);
}
.pageImage-text * {
  color: #000;
}

.pageImage-leftText {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 1180px;
  transform: translateY(-50%) translateX(-50%);
  text-align: left;
}
@media (max-width: 1200px) {
  .pageImage-leftText {
    padding-left: 20px;
  }
}
.pageImage-leftText * {
  color: #fff;
}

.pageImage_fullHeight img {
  max-height: none;
  width: auto !important;
}

.pageImage-slider3 {
  position: absolute;
  right: 44.3%;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 960px) {
  .pageImage-slider3 {
    position: relative;
    left: 0 !important;
    top: 0 !important;
    height: auto;
    width: 92%;
    transform: none;
    margin: 40px auto !important;
    padding: 36px 23px !important;
  }
}

.pageImage-framedText {
  position: absolute;
  left: 46.4%;
  top: 50%;
  width: 560px;
  max-width: 100%;
  height: 560px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 58px 0 100px;
}
.pageImage-framedText:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
@media (max-width: 1200px) {
  .pageImage-framedText {
    left: auto;
    right: 20px;
  }
}
@media (max-width: 960px) {
  .pageImage-framedText {
    position: relative;
    left: 0 !important;
    top: 0 !important;
    height: auto;
    width: 92%;
    transform: none;
    margin: 40px auto !important;
    padding: 36px 23px !important;
  }
}

.pageImage-framedText_left {
  left: 50%;
  margin-left: -590px;
}
@media (max-width: 1200px) {
  .pageImage-framedText_left {
    left: auto;
    right: 20px;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .pageImage-framedText_left {
    left: 0;
    right: 0;
    padding: 0 58px 0 20px;
  }
}

body.coreEditorMode .pageImage {
  width: 100%;
}

.numSquare {
  display: inline-block;
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 576px) {
  .numSquare {
    display: none;
  }
}

.numSquare_blue {
  background: #a7c0da;
}

.numSquare_green {
  background: #d5e8c4;
}

.numSquare_red {
  background: #f8c6c5;
}

.numSquare-content {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 8.125rem;
  color: #fff;
  position: absolute;
  right: -16px;
  bottom: 19px;
}

.numRound {
  width: 90px;
  height: 90px;
  background: #F8C6C5;
  border-radius: 50px;
  color: #fff;
  display: flex;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.625rem;
}
@media (max-width: 960px) {
  .numRound {
    width: 81px;
    height: 81px;
  }
}
@media (max-width: 576px) {
  .numRound {
    width: 67.5px;
    height: 67.5px;
  }
}

.numRound_blue {
  background: #A7C0DA;
}

.breadCrumbs {
  margin: 33px 0 41px;
}
@media (max-width: 576px) {
  .breadCrumbs {
    margin-bottom: 20px;
  }
}
.breadCrumbs a {
  display: inline-block;
  color: #808080;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
@media (max-width: 576px) {
  .breadCrumbs a {
    font-size: 12px;
  }
}
.breadCrumbs a + a {
  margin-left: 47px;
  position: relative;
}
.breadCrumbs a + a:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 1px;
  background: #808080;
  left: -39px;
  top: 50%;
}
@media (max-width: 576px) {
  .breadCrumbs a + a {
    margin-left: 16px;
  }
  .breadCrumbs a + a:before {
    width: 4px;
    left: -11px;
    transform: translateY(-50%);
    height: 4px;
    border-radius: 2px;
  }
}
.breadCrumbs a:last-child {
  color: #2b2b2b;
}

.subMenu {
  margin: 41px 0 62px;
  max-width: 900px;
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  background: none;
}
.subMenu li {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  background: none;
  display: inline-block;
}
.subMenu a {
  display: inline-block;
  flex-direction: row;
  padding: 11px 24px;
  border: 1px solid #C3D6B1;
  box-sizing: border-box;
  border-radius: 30px;
  color: #272727;
  text-decoration: none;
  font-size: 1.125rem;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.01em;
  margin-right: 5px;
  margin-bottom: 18px;
  transition: 0.1s ease;
}
.subMenu a:hover {
  background: #C3D6B1;
}
@media (max-width: 600px) {
  .subMenu a {
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 1rem;
    margin-bottom: 9px;
  }
}

.subMenu-item_current a {
  background: #c3d6b1;
  color: #fff;
}

.narrowBlock-0 {
  max-width: 480px;
}

.narrowBlock-1 {
  max-width: 580px;
}

.narrowBlock-2 {
  max-width: 780px;
}

.narrowBlock-3 {
  max-width: 980px;
}

.squareFrame {
  position: relative;
  z-index: 1;
  width: 480px;
  display: block;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.625rem;
  line-height: 2.25rem;
  color: #000;
  text-align: center;
  padding: 0 40px;
  transform: translateY(134px);
}
.squareFrame:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 480px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
@media (max-width: 576px) {
  .squareFrame {
    width: auto;
    transform: translateY(0);
    padding: 20px 40px;
  }
  .squareFrame:before {
    height: 100%;
  }
}

.squareFrame_green {
  color: #fff;
}
.squareFrame_green:before {
  background: #7db260 url("/img/leaves-green.jpg") -122px -317px;
}

.squareFrame_blue {
  color: #fff;
}
.squareFrame_blue:before {
  background: #6584A4 url("/img/leaves-blue.jpg") -122px -317px;
}

.frame {
  position: relative;
  z-index: 1;
  margin: 4em auto;
  padding: 3.7em 0;
}
.frame:before {
  content: "";
  position: absolute;
  left: -100%;
  width: 300%;
  top: 0;
  bottom: 0;
  z-index: -1;
}
@media (max-width: 600px) {
  .frame {
    padding: 2em 0;
  }
}

body.coreEditorMode .frame:before {
  width: 100%;
  left: 0;
}

.frame_green {
  color: #fff;
}
.frame_green:before {
  background: #7db260 url("/img/leaves-green.jpg") -122px -317px;
}
.frame_green * {
  color: #fff;
}

.frame_plainGreen {
  color: #fff;
}
.frame_plainGreen:before {
  background: linear-gradient(138.47deg, #7db260 14.89%, #568e37 76.51%);
}
.frame_plainGreen * {
  color: #fff;
}

.frame_blue {
  color: #fff;
}
.frame_blue:before {
  background: #6584A4 url("/img/leaves-blue.jpg") -122px -317px;
}
.frame_blue * {
  color: #fff;
}

.frame_violet {
  color: #fff;
}
.frame_violet:before {
  background: #787C9B url("/img/leaves-violet.jpg") -122px -317px;
}
.frame_violet * {
  color: #fff;
}

.frame_brown {
  color: #fff;
}
.frame_brown:before {
  background: #79695C url("/img/leaves-brown.jpg") -122px -317px;
}
.frame_brown * {
  color: #fff;
}

.leavesList {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  background: none;
  margin: 3.9em 0;
}
.leavesList li {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  background: none;
  font-size: 1.1875rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.75rem;
  position: relative;
  margin-top: 1.45em;
}
.leavesList li:before {
  content: "";
  position: absolute;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -190px -278px;
  width: 40px;
  height: 29px;
  left: -53px;
  top: -1px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .leavesList li:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
.leavesList h1, .leavesList h2, .leavesList h3, .leavesList h4, .leavesList h5 {
  margin-top: 0;
}

.leavesList_2cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.leavesList_2cols li {
  padding-left: 52px;
  width: 48%;
}
.leavesList_2cols li:before {
  left: 0;
}
@media (max-width: 576px) {
  .leavesList_2cols li {
    width: 100%;
  }
}

.leavesList_3cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.leavesList_3cols:after {
  content: "";
  height: 0;
  width: 31%;
  display: block;
}
@media (max-width: 576px) {
  .leavesList_3cols:after {
    width: 100%;
  }
}
.leavesList_3cols li {
  padding-left: 52px;
  width: 31%;
}
@media (max-width: 576px) {
  .leavesList_3cols li {
    width: 100%;
  }
}
.leavesList_3cols li:before {
  left: 0;
}

.leavesList_4cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.leavesList_4cols:after {
  content: "";
  height: 0;
  width: 23%;
  display: block;
}
@media (max-width: 960px) {
  .leavesList_4cols:after {
    width: 48%;
  }
}
@media (max-width: 576px) {
  .leavesList_4cols:after {
    width: 100%;
  }
}
.leavesList_4cols li {
  width: 23%;
}
@media (max-width: 960px) {
  .leavesList_4cols li {
    width: 48%;
  }
}
@media (max-width: 576px) {
  .leavesList_4cols li {
    width: 100%;
  }
}
.leavesList_4cols li:before {
  position: static;
  display: block;
}

.leavesList_smallText {
  font-size: 1rem;
}

.eventsList {
  margin-top: 80px;
}

.eventsList-item {
  display: flex;
}
.eventsList-item + .eventsList-item {
  margin-top: 56px;
}
@media (max-width: 959.98px) {
  .eventsList-item {
    display: block;
  }
}

.eventsList-img img {
  max-width: none;
}
@media (max-width: 959.98px) {
  .eventsList-img img {
    width: 100% !important;
  }
}

.eventsList-content {
  flex-grow: 1;
  padding-left: 20px;
}
.eventsList-content p {
  font-size: 1rem;
  line-height: 1.625rem;
}
@media (max-width: 959.98px) {
  .eventsList-content {
    padding-left: 0;
  }
}

.eventsList-date {
  margin-bottom: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #7DB260;
  text-transform: uppercase;
}
@media (max-width: 959.98px) {
  .eventsList-date {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.eventsList-title {
  margin-bottom: -5px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.625rem;
  line-height: 1.875rem;
}
.eventsList-title a {
  color: #808080;
  text-decoration: none;
}

.event p {
  font-size: 1rem;
  line-height: 1.625rem;
}

.event-date {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #7DB260;
  text-transform: uppercase;
}

.photoGalleryList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
}
.photoGalleryList:after {
  content: "";
  height: 0;
  width: 32%;
  display: block;
}
@media (max-width: 960px) {
  .photoGalleryList:after {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .photoGalleryList:after {
    width: 100%;
  }
}

.photoGalleryList-item {
  width: 32%;
}
@media (max-width: 960px) {
  .photoGalleryList-item {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .photoGalleryList-item {
    width: 100%;
  }
}
@media (pointer: fine) {
  .photoGalleryList-item {
    transition: 0.12s;
    transform: scale(1) translateY(0);
  }
  .photoGalleryList-item:hover {
    transform: scale(1.05) translateY(-2.5%);
  }
  .photoGalleryList-item img {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: 0.12s;
  }
  .photoGalleryList-item:hover img {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
}

.photoGalleryList-img img {
  max-width: 100%;
}
.photoGalleryList-img a {
  text-decoration: none;
}

.photoGalleryList-title {
  text-align: center;
  display: block;
  color: #6A6A6A;
  font-size: 26px;
  line-height: 30px;
  padding: 18px 0 61px;
}

.gallery-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.gallery-content:after {
  content: "";
  height: 0;
  width: 32%;
  display: block;
}

.gallery-item {
  width: 32%;
  margin-bottom: 1.933%;
}
.gallery-item img {
  display: block;
  max-width: 100%;
}

.actionsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
}
.actionsList:after {
  content: "";
  height: 0;
  width: 32%;
  display: block;
}
@media (max-width: 960px) {
  .actionsList:after {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .actionsList:after {
    width: 100%;
  }
}

.actionsList-item {
  width: 32%;
  margin-bottom: 70px;
  text-align: center;
}
@media (max-width: 960px) {
  .actionsList-item {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .actionsList-item {
    width: 100%;
  }
}
@media (pointer: fine) {
  .actionsList-item {
    transition: 0.12s;
    transform: scale(1) translateY(0);
  }
  .actionsList-item:hover {
    transform: scale(1.05) translateY(-2.5%);
  }
  .actionsList-item img {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: 0.12s;
  }
  .actionsList-item:hover img {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
}

.actionsList-img img {
  max-width: 100%;
}
.actionsList-img a {
  text-decoration: none;
}

.actionsList-title {
  display: block;
  color: #6A6A6A;
  font-size: 1.625rem;
  line-height: 1.875rem;
  padding: 18px 0 15px;
}

.pagination {
  margin-top: 50px;
  text-align: center;
  clear: both;
}

.pagination-item {
  display: inline-block;
  text-decoration: none;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  text-align: center;
  color: #BFBFBF;
  padding: 16px;
}

.pagination-item_current {
  font-weight: bold;
  color: #5e6280;
}

.staffList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
}
.staffList:after {
  content: "";
  height: 0;
  width: 32%;
  display: block;
}
@media (max-width: 960px) {
  .staffList:after {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .staffList:after {
    width: 100%;
  }
}

.staffList-item {
  width: 32%;
  margin-bottom: 70px;
  text-align: center;
}
@media (max-width: 960px) {
  .staffList-item {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .staffList-item {
    width: 100%;
  }
}
@media (pointer: fine) {
  .staffList-item {
    transition: 0.12s;
    transform: scale(1) translateY(0);
  }
  .staffList-item:hover {
    transform: scale(1.05) translateY(-2.5%);
  }
  .staffList-item img {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: 0.12s;
  }
  .staffList-item:hover img {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
}

.staffList-img img {
  max-width: 100%;
}
.staffList-img a {
  text-decoration: none;
}

.staffList-title {
  display: block;
  color: #6A6A6A;
  font-size: 26px;
  line-height: 30px;
  padding: 18px 0 5px;
}

.staffList-annotation {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #808080;
  display: block;
  padding-bottom: 15px;
}

.shadowFrame {
  background: #FFFFFF;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1.25rem 2.625rem 1.4375rem 2.625rem;
}

.imageToRight {
  position: relative;
}
.imageToRight img {
  display: block;
  max-width: none;
  max-height: 80vh;
}
@media (max-width: 960px) {
  .imageToRight img {
    width: 100% !important;
    height: 400px !important;
    object-fit: cover;
  }
}
@media (max-width: 576px) {
  .imageToRight img {
    height: 200px !important;
  }
}

.imageToLeft {
  position: relative;
}
.imageToLeft img {
  display: block;
  max-width: none;
  float: right;
  max-height: 80vh;
}
@media (max-width: 960px) {
  .imageToLeft img {
    width: 100% !important;
    height: 400px !important;
    object-fit: cover;
  }
}
@media (max-width: 576px) {
  .imageToLeft img {
    height: 200px !important;
  }
}

.articleGrid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
}
.articleGrid:after {
  content: "";
  height: 0;
  width: 32%;
  display: block;
}
@media (max-width: 960px) {
  .articleGrid:after {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .articleGrid:after {
    width: 100%;
  }
}

.articleGrid-item {
  width: 32%;
  margin-bottom: 70px;
  text-align: center;
}
.articleGrid-item a {
  text-decoration: none;
}
@media (max-width: 960px) {
  .articleGrid-item {
    width: 49%;
  }
}
@media (max-width: 576px) {
  .articleGrid-item {
    width: 100%;
  }
}
@media (pointer: fine) {
  .articleGrid-item {
    transition: 0.12s;
    transform: scale(1) translateY(0);
  }
  .articleGrid-item:hover {
    transform: scale(1.05) translateY(-2.5%);
  }
  .articleGrid-item img {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: 0.12s;
  }
  .articleGrid-item:hover img {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
}

.articleGrid-header {
  width: 100%;
}

.articleGrid-img {
  position: relative;
}
.articleGrid-img img {
  max-width: 100%;
}

.articleGrid-imgTitle {
  position: absolute;
  width: 92%;
  max-width: 190px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: 47px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.625rem;
  line-height: 1.875rem;
  text-decoration-line: underline;
  color: #ffffff;
}

.articleGrid-annotation {
  display: block;
  max-width: 245px;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  color: #808080;
  padding: 18px 0 5px;
}
.articleGrid-annotation + .articleGrid-title {
  padding-top: 0;
}

.articleGrid-title {
  display: block;
  color: #6a6a6a;
  font-size: 1.625rem;
  line-height: 1.875rem;
  padding: 18px 0 15px;
}

.articleGrid-squareFrame {
  position: relative;
  padding-top: 100%;
  text-align: left;
}

.articleGrid-squareFrame-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  padding: 23px 42px;
  display: flex;
  align-items: center;
}
.articleGrid-squareFrame-content :first-child {
  margin-top: 0;
}
.articleGrid-squareFrame-content :last-child {
  margin-bottom: 0;
}

.headerDecor {
  position: relative;
  text-align: center;
}
.headerDecor:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  background: #BFBFBF;
}

.headerDecor-content {
  position: relative;
  display: inline-block;
  background: #fff;
  padding: 0 37px;
}

.xlsLink {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  text-decoration-line: underline;
  color: #4C6885;
}
.xlsLink:before {
  content: "";
  display: block;
  margin-right: 20px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -283px -82px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  max-width: 80px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .xlsLink:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.docLink {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1875rem;
  line-height: 1.75rem;
}
.docLink:before {
  content: "";
  display: block;
  margin-right: 20px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -283px 0px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  max-width: 80px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .docLink:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.pdfLink {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1875rem;
  line-height: 1.75rem;
}
.pdfLink:before {
  content: "";
  display: block;
  margin-right: 20px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -82px -142px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  max-width: 80px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .pdfLink:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.spoiler-ctrl {
  position: relative;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  color: #6584A4;
  cursor: pointer;
}
.spoiler-ctrl:after {
  content: "";
  position: absolute;
  right: -15px;
  top: 50%;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -177px -104px;
  width: 10px;
  height: 6px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .spoiler-ctrl:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
.spoiler-ctrl span:nth-of-type(2) {
  display: none;
}

.spoiler-content {
  display: none;
  grid-row: 2;
  padding: 0 15px;
  margin-top: 20px;
}

body.coreEditorMode .spoiler-content {
  display: block;
  border: 1px solid red;
}

.spoiler-ctrl_open:after {
  transform: rotate(180deg);
}
.spoiler-ctrl_open span:nth-of-type(1) {
  display: none;
}
.spoiler-ctrl_open span:nth-of-type(2) {
  display: block;
}

body.coreSiteMode .autoSpoiler {
  max-height: 80px;
  overflow: hidden;
  transition: 0.2s;
}
body.coreSiteMode .autoSpoiler p:last-child {
  margin-bottom: 0;
}
body.coreSiteMode .autoSpoiler_open {
  max-height: 1000px;
}
body.coreSiteMode .autoSpoiler-ctrl {
  position: relative;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #6584a4;
  cursor: pointer;
}
body.coreSiteMode .autoSpoiler-ctrl:after {
  content: "";
  position: absolute;
  right: -15px;
  top: 50%;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -177px -104px;
  width: 10px;
  height: 6px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.coreSiteMode .autoSpoiler-ctrl:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
body.coreSiteMode .autoSpoiler-ctrl span:nth-of-type(2) {
  display: none;
}
body.coreSiteMode .autoSpoiler-ctrl_open:after {
  transform: rotate(180deg);
}
body.coreSiteMode .autoSpoiler-ctrl_open span:nth-of-type(1) {
  display: none;
}
body.coreSiteMode .autoSpoiler-ctrl_open span:nth-of-type(2) {
  display: block;
}
body.coreSiteMode .autoSpoiler-ctrl_hidden {
  display: none;
}

body.coreEditorMode .autoSpoiler {
  border: 1px dashed red;
}

.afishaList-item {
  display: flex;
}
@media (max-width: 576px) {
  .afishaList-item {
    display: block;
  }
}

.afishaList-time {
  white-space: nowrap;
  padding-right: 58px;
}

.afishaList-content, .afishaList-content p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}

.information {
  display: flex;
  align-items: center;
}
.information:before {
  content: "";
  display: block;
  margin-right: 20px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -201px 0px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  max-width: 80px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .information:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.information_top {
  align-items: flex-start;
}

a.information {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  text-decoration-line: underline;
  color: #4C6885;
}

.blockIcon, .blockIcon-gym, .blockIcon-wifi, .blockIcon-medal, .blockIcon-zen, .blockIcon-poll,
.blockIcon-pool, .blockIcon-tableware, .blockIcon-bed {
  position: relative;
  display: flex;
  align-items: center;
}
.blockIcon:before, .blockIcon-gym:before, .blockIcon-wifi:before, .blockIcon-medal:before, .blockIcon-zen:before, .blockIcon-poll:before,
.blockIcon-pool:before, .blockIcon-tableware:before, .blockIcon-bed:before {
  content: "";
  width: 100px;
  height: 100px;
  display: block;
  margin-right: 20px;
  min-width: 100px;
  background: #a0d185;
  border-radius: 50%;
}
.blockIcon:after, .blockIcon-gym:after, .blockIcon-wifi:after, .blockIcon-medal:after, .blockIcon-zen:after, .blockIcon-poll:after,
.blockIcon-pool:after, .blockIcon-tableware:after, .blockIcon-bed:after {
  position: absolute;
  content: "";
  z-index: 1;
  left: 50px;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.blockIcon_violet:before {
  background: #787C9B;
}

.blockIcon-bed:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -283px -164px;
  width: 52px;
  height: 52px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon-bed:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon-tableware:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -99px -66px;
  width: 52px;
  height: 52px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon-tableware:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon-poll:after,
.blockIcon-pool:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -201px -82px;
  width: 52px;
  height: 52px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon-poll:after,
.blockIcon-pool:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon-zen:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: 0px -224px;
  width: 52px;
  height: 52px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon-zen:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon-medal:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -54px -224px;
  width: 48px;
  height: 52px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon-medal:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon-wifi:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -232px -278px;
  width: 38px;
  height: 28px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon-wifi:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon-gym:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -153px -66px;
  width: 37px;
  height: 36px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon-gym:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon2, .blockIcon2-gym, .blockIcon2-wifi, .blockIcon2-medal, .blockIcon2-zen, .blockIcon2-poll,
.blockIcon2-pool, .blockIcon2-tableware, .blockIcon2-bed {
  position: relative;
  display: flex;
  align-items: center;
}
.blockIcon2:before, .blockIcon2-gym:before, .blockIcon2-wifi:before, .blockIcon2-medal:before, .blockIcon2-zen:before, .blockIcon2-poll:before,
.blockIcon2-pool:before, .blockIcon2-tableware:before, .blockIcon2-bed:before {
  content: "";
  width: 70px;
  height: 70px;
  display: block;
  margin-right: 20px;
  min-width: 70px;
  background: #787C9B;
  border-radius: 50%;
}
.blockIcon2:after, .blockIcon2-gym:after, .blockIcon2-wifi:after, .blockIcon2-medal:after, .blockIcon2-zen:after, .blockIcon2-poll:after,
.blockIcon2-pool:after, .blockIcon2-tableware:after, .blockIcon2-bed:after {
  position: absolute;
  content: "";
  z-index: 1;
  left: 35px;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.blockIcon2-bed:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -283px -164px;
  width: 52px;
  height: 52px;
  transform: translateX(-50%) translateY(-50%) scale(0.7);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon2-bed:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon2-tableware:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -99px -66px;
  width: 52px;
  height: 52px;
  transform: translateX(-50%) translateY(-50%) scale(0.7);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon2-tableware:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon2-poll:after,
.blockIcon2-pool:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -201px -82px;
  width: 52px;
  height: 52px;
  transform: translateX(-50%) translateY(-50%) scale(0.7);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon2-poll:after,
.blockIcon2-pool:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon2-zen:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: 0px -224px;
  width: 52px;
  height: 52px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon2-zen:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon2-medal:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -54px -224px;
  width: 48px;
  height: 52px;
  transform: translateX(-50%) translateY(-50%) scale(0.7);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon2-medal:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon2-wifi:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -232px -278px;
  width: 38px;
  height: 28px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon2-wifi:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockIcon2-gym:after {
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -153px -66px;
  width: 37px;
  height: 36px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockIcon2-gym:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.list1 {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  background: none;
  margin: 3.9em 0;
}
.list1 li {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  background: none;
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  margin-bottom: 28px;
}
.list1 li:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 2px;
  left: 0;
  top: 13px;
  background: #C3D6B1;
}
.list1 h1, .list1 h2, .list1 h3, .list1 h4, .list1 h5 {
  margin-top: 0;
}

.list1_2cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.list1_2cols li {
  padding-left: 41px;
  width: 48%;
}
@media (max-width: 576px) {
  .list1_2cols li {
    width: 100%;
  }
}

.colorSquare {
  width: 20px;
  height: 20px;
}

.colorSquare_blue {
  background: #6584A4;
}

.colorSquare_green {
  background: #C3D6B1;
}

.colorSquare_red {
  background: #F08D8B;
}

.colorSquare_brown {
  background: #94877D;
}

.shadowFrame2 {
  position: relative;
  max-width: 1103px;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(73, 73, 73, 0.2);
  border-radius: 20px;
  padding: 66px 220px;
  margin: 0 auto;
}
.shadowFrame2 * {
  color: #808080 !important;
}
.shadowFrame2 :first-child {
  margin-top: 0;
}
.shadowFrame2 :last-child {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .shadowFrame2 {
    padding: 20px 40px;
  }
}

.shadowFrame2-img {
  position: absolute;
  left: -76px;
  top: -20px;
  border-radius: 50%;
  overflow: hidden;
}
.shadowFrame2-img img {
  display: block;
  width: 100% !important;
}
@media (max-width: 960px) {
  .shadowFrame2-img {
    border-radius: 4px;
    position: static;
    width: 230px;
    height: 230px;
    margin-top: -100px;
  }
}

body.coreEditorMode .shadowFrame2-img {
  border-radius: 0;
}

.quote {
  position: relative;
}
.quote:before, .quote:after {
  content: "";
  position: absolute;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -304px -224px;
  width: 50px;
  height: 40px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .quote:before, .quote:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
@media (max-width: 960px) {
  .quote:before, .quote:after {
    transform: scale(0.5);
    transform-origin: right top;
  }
}
.quote:before {
  left: -58px;
  top: 6px;
}
.quote:after {
  right: -58px;
  bottom: 6px;
  transform: rotate(180deg) scale(0.5);
  transform-origin: left bottom;
}

.blockPlus {
  position: relative;
  padding-left: 100px;
}
.blockPlus:before {
  content: "";
  position: absolute;
  left: 0;
  top: -20px;
  display: block;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -164px -142px;
  width: 80px;
  height: 80px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockPlus:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.blockMinus {
  position: relative;
  padding-left: 100px;
}
.blockMinus:before {
  content: "";
  position: absolute;
  left: 0;
  top: -20px;
  display: block;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: 0px -142px;
  width: 80px;
  height: 80px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .blockMinus:before {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.proceduresList-item {
  overflow: hidden;
  margin-top: 56px;
}
.proceduresList-item a {
  text-decoration: none;
}

.proceduresList-img {
  float: left;
  margin-right: 20px;
}
@media (max-width: 960px) {
  .proceduresList-img {
    float: none;
    margin-right: 0;
  }
}

.proceduresList-title {
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.625rem;
  line-height: 1.875rem;
  color: #808080;
  padding-top: 0.5em;
}

.proceduresList-annotation {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #2B2B2B;
}
.proceduresList-annotation * {
  font-size: 1rem;
}

.proceduresList-title_fee:after {
  content: "";
  display: inline-block;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -38px -278px;
  width: 36px;
  height: 36px;
  vertical-align: middle;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .proceduresList-title_fee:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.price-line {
  position: relative;
}
.price-line:after {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  height: 1px;
  background: #fff;
  opacity: 0.5;
}

.price2-button {
  position: relative;
  margin-top: 150px;
  margin-bottom: 120px;
  text-align: center;
}
.price2-button a {
  text-decoration: none;
}
@media (max-width: 576px) {
  .price2-button {
    margin: 80px 0;
  }
}

.priceBtn {
  background: #C3D6B1;
  border-radius: 96px;
  height: 80px;
  padding: 30px 50px;
  text-align: center;
  color: #2B2B2B;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.tabs > .price_content {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
  margin-top: 15px !important;
}

.price_tabs .tabs-overflow label span {
  font-size: 18px;
  color: #272727 !important;
  font-weight: 400 !important;
  flex-wrap: wrap;
  background: #FFFFFF;
  border: 1px solid #C3D6B1;
  border-radius: 30px;
  height: auto !important;
  padding: 15px 25px !important;
  white-space: break-spaces !important;
}
@media (max-width: 576px) {
  .price_tabs .tabs-overflow label span {
    font-size: 14px !important;
    padding: 10px 15px !important;
  }
}

.price_tabs .tabs-overflow > label {
  width: auto !important;
  padding-left: 0 !important;
  margin-right: 15px;
  height: auto !important;
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .price_tabs .tabs-overflow > label {
    opacity: 1 !important;
    width: auto !important;
    padding: 0 !important;
  }
}
@media (max-width: 576px) {
  .price_tabs .tabs-overflow > label {
    margin-bottom: 10px !important;
  }
}

.price_tabs .tabs-overflow {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
}

.price_tabs .tabs-overflow > input[type=radio]:checked + label span {
  background: #C3D6B1;
  border: 1px solid #C3D6B1;
  color: white !important;
}

.price_tabs .tabs-overflow > input[type=radio]:checked + label {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.price_content .priceTable td, .priceTable th {
  padding: 10px 30px !important;
}

.price_tabs .tabs-overflow > input[type=radio]:checked + label::before {
  display: block !important;
  background: transparent !important;
  height: auto !important;
  position: absolute !important;
  bottom: 0 !important;
  width: auto !important;
}

.price_content {
  width: 100%;
  overflow: auto;
}

.price2 {
  margin-top: 150px;
}
@media (max-width: 960px) {
  .price2 {
    margin-top: 70px;
  }
}

.priceNew-button {
  margin-top: 60px;
}

.instaList {
  display: flex;
  justify-content: space-between;
  width: 92%;
  max-width: 1180px;
  margin: 4.05em auto;
}
.instaList img {
  width: 23.7%;
  display: block;
}

.form1 {
  width: 92%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 67px 100px 40px;
  background: #fff;
  border-radius: 20px;
}
@media (max-width: 576px) {
  .form1 {
    padding: 30px 20px 20px;
  }
}

.input, .textarea, .select {
  font-family: "Open Sans", sans-serif;
  background: #eeeded;
  height: 40px;
  border: none;
  border-radius: 6px;
  padding: 0 14px;
  font-size: 14px;
  color: #808080;
  margin-bottom: 29px;
  width: 100%;
}
@media (max-width: 576px) {
  .input, .textarea, .select {
    font-size: 16px;
  }
}

.textarea {
  padding: 12px 14px;
  height: 140px;
}

.select {
  appearance: none;
}
.select option {
  color: #808080;
}

.select-wrap {
  position: relative;
  margin-bottom: 29px;
}
.select-wrap .select {
  margin-bottom: 0;
}
.select-wrap:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -189px -104px;
  width: 10px;
  height: 5px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .select-wrap:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.checkbox {
  position: relative;
  display: block;
  margin-bottom: 29px;
}

.checkbox-content {
  font-family: "Open Sans", sans-serif;
  color: #2b2b2b;
  padding-left: 34px;
  display: block;
  user-select: none;
}
.checkbox-content a {
  color: #5e6280;
}
.checkbox-content:before {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 24px;
  height: 24px;
  background: #EEEDED;
  border-radius: 6px;
}

.checkbox-input {
  display: none;
}
.checkbox-input:checked + .checkbox-content:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 5px;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -138px -120px;
  width: 16px;
  height: 11px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .checkbox-input:checked + .checkbox-content:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.button {
  margin-bottom: 29px;
}

.reviews-item {
  background: #ffffff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 40px 41px 20px;
}

.reviews-date {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #7db260;
  text-transform: uppercase;
  margin-bottom: 9px;
}

.reviews-name {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.625rem;
  line-height: 1.875rem;
  color: #808080;
  margin-bottom: 30px;
}

.reviews-text {
  color: #2B2B2B;
}
.reviews-text p {
  color: #2B2B2B !important;
}
.reviews-text .autoSpoiler-ctrl:after {
  display: none;
}
.reviews-text .autoSpoiler-ctrl span {
  color: #e26360;
}

.kbox {
  background: rgba(191, 191, 191, 0.9);
}
@media (max-width: 500px) {
  .kbox {
    padding: 2%;
  }
}

.kbox-contents {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.kbox-contents img {
  opacity: 1 !important;
}

.kbox-leftCtrl:before, .kbox-rightCtrl:before {
  content: "";
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.9);
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.kbox-leftCtrl:after, .kbox-rightCtrl:after {
  content: "";
  background: transparent;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -350px -278px;
  width: 11px;
  height: 22px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .kbox-leftCtrl:after, .kbox-rightCtrl:after {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}

.kbox-leftCtrl {
  left: 0;
}
.kbox-leftCtrl:after {
  transform: translateY(-50%) translateX(-50%) rotate(180deg);
}

.kbox-rightCtrl {
  right: 0;
}

.kbox-closeCtrl {
  right: 20px;
  top: 20px;
  background: transparent;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -246px -142px;
  width: 32px;
  height: 32px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .kbox-closeCtrl {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
.kbox-closeCtrl:before, .kbox-closeCtrl:after {
  display: none;
}

[data-aos=fade-down] {
  opacity: 0;
  transition: 0s !important;
  transform: translateY(-20px) !important;
}
[data-aos=fade-down]:nth-child(1) {
  transition-delay: 0.03s;
}
[data-aos=fade-down]:nth-child(2) {
  transition-delay: 0.06s;
}
[data-aos=fade-down]:nth-child(3) {
  transition-delay: 0.09s;
}
[data-aos=fade-down]:nth-child(4) {
  transition-delay: 0.12s;
}
[data-aos=fade-down]:nth-child(5) {
  transition-delay: 0.15s;
}
[data-aos=fade-down]:nth-child(6) {
  transition-delay: 0.18s;
}
[data-aos=fade-down]:nth-child(7) {
  transition-delay: 0.21s;
}
[data-aos=fade-down]:nth-child(8) {
  transition-delay: 0.24s;
}
[data-aos=fade-down]:nth-child(9) {
  transition-delay: 0.27s;
}
[data-aos=fade-down]:nth-child(10) {
  transition-delay: 0.3s;
}
[data-aos=fade-down]:nth-child(11) {
  transition-delay: 0.33s;
}
[data-aos=fade-down]:nth-child(12) {
  transition-delay: 0.36s;
}
[data-aos=fade-down]:nth-child(13) {
  transition-delay: 0.39s;
}
[data-aos=fade-down]:nth-child(14) {
  transition-delay: 0.42s;
}
[data-aos=fade-down]:nth-child(15) {
  transition-delay: 0.45s;
}
[data-aos=fade-down]:nth-child(16) {
  transition-delay: 0.48s;
}

[data-aos=fade-down].aos-animate {
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: 0.4s !important;
}

[data-aos=fade] {
  opacity: 0;
  transition: 0s !important;
}
[data-aos=fade]:nth-child(1) {
  transition-delay: 0.03s;
}
[data-aos=fade]:nth-child(2) {
  transition-delay: 0.06s;
}
[data-aos=fade]:nth-child(3) {
  transition-delay: 0.09s;
}
[data-aos=fade]:nth-child(4) {
  transition-delay: 0.12s;
}
[data-aos=fade]:nth-child(5) {
  transition-delay: 0.15s;
}
[data-aos=fade]:nth-child(6) {
  transition-delay: 0.18s;
}
[data-aos=fade]:nth-child(7) {
  transition-delay: 0.21s;
}
[data-aos=fade]:nth-child(8) {
  transition-delay: 0.24s;
}
[data-aos=fade]:nth-child(9) {
  transition-delay: 0.27s;
}
[data-aos=fade]:nth-child(10) {
  transition-delay: 0.3s;
}
[data-aos=fade]:nth-child(11) {
  transition-delay: 0.33s;
}
[data-aos=fade]:nth-child(12) {
  transition-delay: 0.36s;
}
[data-aos=fade]:nth-child(13) {
  transition-delay: 0.39s;
}
[data-aos=fade]:nth-child(14) {
  transition-delay: 0.42s;
}
[data-aos=fade]:nth-child(15) {
  transition-delay: 0.45s;
}
[data-aos=fade]:nth-child(16) {
  transition-delay: 0.48s;
}

[data-aos=fade].aos-animate {
  opacity: 1 !important;
  transition: 0.4s !important;
}

[data-aos=myFade] {
  opacity: 0;
  transition: 0s !important;
}

[data-aos=myFade].aos-animate {
  opacity: 1 !important;
  transition: 0.4s !important;
}

body[data-admin="0"] .pageImage, body[data-admin="0"] img,
body[data-admin="0"] .articleGrid-item, body[data-admin="0"] h1, body[data-admin="0"] h2, body[data-admin="0"] h3, body[data-admin="0"] .h1, body[data-admin="0"] .h1_regular, body[data-admin="0"] .h2, body[data-admin="0"] .h3, body[data-admin="0"] .h3_bigRedCircle-sub, body[data-admin="0"] p, body[data-admin="0"] li {
  opacity: 0;
}
body[data-admin="0"] .topMenu li {
  opacity: 1;
}

.popup {
  background: rgba(191, 191, 191, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20002;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.popup_visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.popup-box {
  width: 584px;
  max-width: 96%;
  background: #fff;
  position: relative;
  max-height: 100%;
  border-radius: 20px;
}
@media (max-width: 960px) {
  .popup-box {
    width: 100%;
    max-width: none;
    min-height: 100%;
    border-radius: 0;
  }
}

.popup-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 20;
  background-image: url(../img/sprite.png?t1685543367348);
  background-position: -246px -142px;
  width: 32px;
  height: 32px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .popup-close {
    background-image: url(../img/sprite@2x.png?t1685543367348);
    background-size: 363px 338px;
  }
}
@media (max-width: 960px) {
  .popup-close {
    right: 10px;
  }
}

.popupForm {
  padding: 10px;
  position: relative;
}
.popupForm .h1, .popupForm .h1_regular {
  line-height: 1.3;
}

.popupForm-form {
  margin-top: 56px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.popup .popupForm {
  overflow: auto;
  max-height: 100vh;
}

.popupForm_busy:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.popupForm-title {
  margin: 4.0625rem auto 1.1875rem;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.625rem;
  line-height: 3.375rem;
  text-align: center;
  color: #2B2B2B;
}

.popupForm-text {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  text-align: center;
  color: #2B2B2B;
}

.popupForm-field {
  display: block;
  max-width: 440px;
  margin: 0 auto 19px;
  position: relative;
}

div.popupForm-field {
  text-align: center;
  padding-top: 11px;
}

.popupForm-checkbox * {
  color: #333 !important;
}

.popupForm-label {
  position: absolute;
  left: -12px;
  top: 12px;
  transform: translateX(-100%);
  color: #999999;
  font-size: 16px;
}
@media (max-width: 960px) {
  .popupForm-label {
    position: static;
  }
}

.popupForm-input .input {
  color: #EEEDED;
}

.popupForm-urgent .popupForm-label:after,
.popupForm-urgent .input-label:after {
  content: " *";
}

.popupForm-error .popupForm-input, .popupForm-error .checkbox-content::before {
  border: 1px solid red;
  animation-name: shake;
  animation-duration: 0.1s;
  animation-iteration-count: 3;
}

.popupForm-state_success {
  display: none;
  padding: 20px 0 60px;
}
.popupForm-state_success i {
  margin-bottom: 10px;
}
.popupForm-state_success .popupForm-title {
  font-weight: 300;
  line-height: 1.6;
  text-transform: none;
}

.popupForm-footer {
  font-size: 13px;
  color: #999999;
  text-align: center;
  margin: 51px 0 18px;
}
.popupForm-footer a {
  color: inherit;
}

.foxMenuBlock {
  position: relative;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  display: flex;
  margin: 4em 0;
  flex-wrap: wrap;
}

.foxMenuBlock-content {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: url("/img/brown-pattern.jpg") center center;
  background-size: cover;
}
@media (max-width: 960px) {
  .foxMenuBlock-content {
    width: 65%;
  }
}
@media (max-width: 576px) {
  .foxMenuBlock-content {
    width: 100%;
  }
}

.foxMenuBlock-text {
  max-width: 480px;
  margin: 0 110px;
  color: #fff;
}
@media (max-width: 1200px) {
  .foxMenuBlock-text {
    margin: 0 40px;
  }
}
@media (max-width: 576px) {
  .foxMenuBlock-text {
    margin: 0 20px;
  }
}
.foxMenuBlock-text p {
  margin: 1.875rem;
  color: inherit !important;
}

.foxMenuBlock-img {
  width: 50%;
}
@media (max-width: 960px) {
  .foxMenuBlock-img {
    width: 35%;
  }
}
@media (max-width: 576px) {
  .foxMenuBlock-img {
    width: 100%;
  }
}
.foxMenuBlock-img img {
  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

body.coreSiteMode .videoFrame {
  position: relative;
  padding-top: 56.25%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}
body.coreSiteMode .videoFrame iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.priceTable {
  border-collapse: collapse;
  width: 100%;
}
.priceTable td, .priceTable th {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #6A6A6A;
  padding: 17px 30px;
}
@media (max-width: 960px) {
  .priceTable td, .priceTable th {
    padding: 8px 16px;
  }
}
@media (max-width: 576px) {
  .priceTable td, .priceTable th {
    padding: 1px 2px;
  }
}
.priceTable td {
  background: #F2F2F2;
}
.priceTable tr:nth-of-type(2n + 2) td {
  background: #fff;
}
.priceTable th {
  color: #fff;
  background: #4C6885;
}

#tl-booking-form,
#tl-anchor {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

#block-search {
  background: transparent;
}

.tl-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.searchPopup {
  background: #7db260 url(/img/leaves-green.jpg) -122px -317px;
  border-radius: 20px;
}

.pineCoins {
  position: relative;
  overflow: hidden;
}
.pineCoins:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1920px;
  height: 396px;
  background: url("/img/main/addons/pine-cones.png") left bottom no-repeat;
  transform-origin: left bottom;
  pointer-events: none;
}
@media (max-width: 1600px) {
  .pineCoins:after {
    width: 100%;
    transform: translateX(-200px) scale(0.8);
  }
}
@media (max-width: 1300px) {
  .pineCoins:after {
    width: 300%;
    right: auto;
    left: 0;
    transform: translateX(-200px) scale(0.5);
  }
}
@media (max-width: 576px) {
  .pineCoins:after {
    transform: translateX(-90px) scale(0.3);
  }
}

.leaves {
  z-index: 1;
}

.leaves-leaf1 {
  position: absolute;
  background: url("/img/main/addons/leaf1.png");
  width: 674px;
  height: 304px;
  pointer-events: none;
  left: 270px;
  top: 329px;
}
@media (max-width: 960px) {
  .leaves-leaf1 {
    display: none;
  }
}

.leaves-leaf2 {
  position: absolute;
  background: url("/img/main/addons/leaf2.png");
  width: 980px;
  height: 476px;
  pointer-events: none;
  left: -274px;
  top: 155px;
  z-index: -2;
}
@media (max-width: 960px) {
  .leaves-leaf2 {
    display: none;
  }
}

.leaves-leaf3 {
  position: absolute;
  background: url("/img/main/addons/leaf3.png");
  width: 214px;
  height: 193px;
  pointer-events: none;
  left: -120px;
  top: -19px;
}
@media (max-width: 960px) {
  .leaves-leaf3 {
    transform: scale(0.5);
    left: -90px;
    top: -100px;
  }
}

.mirCard {
  text-align: right;
}
@media (max-width: 960px) {
  .mirCard {
    text-align: left;
  }
}

.mirCard .mirCard-img {
  margin-top: -27px;
  transform: translateY(-30px) translateX(20px) rotate(-21.4deg);
}
@media (max-width: 1200px) {
  .mirCard .mirCard-img {
    max-width: 90% !important;
  }
}
@media (max-width: 960px) {
  .mirCard .mirCard-img {
    transform: none;
    margin-top: 40px;
    margin-bottom: -40px;
    margin-left: -4.6948356808%;
  }
}
@media (max-width: 500px) {
  .mirCard .mirCard-img {
    margin-top: 20px;
    margin-bottom: -10px;
    max-width: none !important;
    width: 112.3630672926% !important;
  }
}

.beam-content {
  margin-top: 55px;
  position: relative;
  margin-bottom: 100px;
}
@media (max-width: 960px) {
  .beam-content {
    margin-top: 100px;
  }
}

.beam-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  display: none;
  -webkit-overflow-scrolling: touch;
}
.beam-scroll::-webkit-scrollbar {
  height: 8px;
  background-color: #DDDDDD;
  border-radius: 9em;
  border: 1px solid #DDDDDD;
}
.beam-scroll::-webkit-scrollbar-thumb {
  background-color: #BFBFBF;
  border-radius: 9em;
}
@media screen and (max-width: 1015px) {
  .beam-scroll {
    display: block;
    margin-bottom: 10px;
  }
}

.beam-visible {
  width: 942px;
  height: 8px;
}

.beam-table {
  overflow: auto;
  padding-bottom: 10px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
.beam-table::-webkit-scrollbar {
  width: 0 !important;
}
@media screen and (max-width: 1015px) {
  .beam-table table {
    white-space: nowrap;
  }
}
.beam-table td {
  padding: 10px 20px;
  text-align: left;
  font-size: 16px;
}
.beam-table td span {
  display: block;
  margin-bottom: 5px;
}
.beam-table td span:last-child {
  margin-bottom: 0;
}
.beam-table td p {
  font-size: 16px;
}
.beam-table h2 {
  margin-bottom: 25px;
}

.tabs {
  font-size: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (max-width: 1200px) {
  .tabs-overflow {
    white-space: nowrap;
    overflow: auto;
    width: 100%;
  }
}
.tabs-overflow::-webkit-scrollbar {
  width: 0;
}

.tabs .tabs-overflow > input[type=radio] {
  display: none;
}

.tabs > .tabs-overflow ~ div {
  /* скрыть контент по умолчанию */
  display: none;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 10px;
}

.tab-width {
  max-width: 1050px;
  margin: 0 auto;
  padding: 70px 0;
}
@media (max-width: 992px) {
  .tab-width {
    padding: 30px 0;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .tab-width {
    padding: 0px 0 30px 0;
  }
}

.tabs .tabs-overflow {
  display: block;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

.tab-content[show=no] {
  display: none;
}

.tab-content[show=yes] {
  display: block !important;
}

.tabs .tabs-overflow > label {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  width: 25%;
  padding-left: 20px;
  font-size: 19px;
  line-height: 1.2;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  top: 1px;
  height: 80px;
  font-weight: bold;
  font-family: "Open Sans";
  color: #6A6A6A;
}
@media (max-width: 1200px) {
  .tabs .tabs-overflow > label {
    opacity: 0.5;
    width: auto;
    padding: 0 57px 0 20px;
  }
}
@media (max-width: 992px) {
  .tabs .tabs-overflow > label {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .tabs .tabs-overflow > label {
    padding: 0 20px 0 20px;
  }
}
.tabs .tabs-overflow > label span {
  display: flex;
  height: 100%;
  align-items: center;
}

.tabs .tabs-overflow > label:not(:first-of-type) {
  border-left: none;
  border-right: none;
  border-top: none;
}

.tabs .tabs-overflow > input[type=radio]:checked + label {
  background-color: #fff;
  box-shadow: 0 -5px 9px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 1;
}
@media (max-width: 1200px) {
  .tabs .tabs-overflow > input[type=radio]:checked + label {
    box-shadow: none;
  }
}
.tabs .tabs-overflow > input[type=radio]:checked + label::before {
  content: "";
}
@media (max-width: 1200px) {
  .tabs .tabs-overflow > input[type=radio]:checked + label::before {
    display: block;
    background: #5c6280;
    height: 5px;
    position: absolute;
    bottom: 0;
    width: 75%;
  }
}

.tabs .tabs-overflow > input[type=radio]:checked + label:nth-of-type(4) ~ div {
  border-top-right-radius: 0;
}

.tabs .tabs-overflow > input[type=radio]:checked + label:nth-of-type(4) ~ div,
.tabs .tabs-overflow > input[type=radio]:checked + label:nth-of-type(3) ~ div,
.tabs .tabs-overflow > input[type=radio]:checked + label:nth-of-type(2) ~ div {
  border-top-left-radius: 10px;
}

.tab-item {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
@media screen and (max-width: 767px) {
  .tab-item {
    flex-direction: column-reverse;
  }
}

.tab-right {
  margin-left: 40px;
  min-width: 285px;
}
@media screen and (max-width: 767px) {
  .tab-right {
    margin-left: 0;
    max-width: 100%;
    min-width: auto;
  }
}
@media screen and (max-width: 767px) {
  .tab-right img {
    width: 100% !important;
    object-fit: cover;
  }
}

.tab-left {
  max-width: 450px;
}
@media screen and (max-width: 767px) {
  .tab-left {
    margin-top: 30px;
  }
}
@media (max-width: 576px) {
  .tab-left {
    max-width: 100%;
  }
}
.tab-left h4 {
  font-size: 19px;
  font-family: "Open Sans";
  color: #6A6A6A;
  font-weight: bold;
  line-height: 28px;
  margin-top: 0;
}
@media (max-width: 576px) {
  .tab-left h4 {
    font-size: 16px;
  }
}
.tab-left h4 span {
  display: block;
}
.tab-left ul {
  padding-left: 0;
  list-style: none;
}
.tab-left ul li {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #6A6A6A;
  margin-bottom: 0;
}
.tab-left p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #6A6A6A;
  margin-bottom: -10px;
}

.tab-link {
  margin-top: 30px;
}
.tab-link a {
  color: #4C6885;
  font-size: 17px;
  display: block;
}
.tab-link a + a {
  margin-top: 5px;
}

.tab-button {
  margin-top: 40px;
  display: block;
  text-decoration: none;
}

.tab_button {
  padding: 0 45px;
}

.tab-link-flex {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .tab-link-flex {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .tab-link-flex-right {
    margin-top: 5px;
  }
}

.formsTg {
  position: relative;
  height: auto;
  display: flex;
  align-items: flex-start;
  max-width: 1000px;
  justify-content: space-between;
}

.formsTg-left {
  margin-right: 50px;
  position: relative;
  top: 50px;
}
@media screen and (max-width: 820px) {
  .formsTg-left {
    top: 0;
  }
}
.formsTg-left p {
  max-width: 380px;
  margin: 0;
}
.formsTg-left h1 {
  margin-bottom: 20px;
}

.formsTg-right {
  position: relative;
  height: auto;
  top: 4px;
}
@media screen and (max-width: 820px) {
  .formsTg-right {
    top: 60px;
  }
}
@media screen and (max-width: 720px) {
  .formsTg-right {
    display: none;
  }
}

.formsTg-frame {
  padding: 3.7em 0 0;
}
@media screen and (max-width: 820px) {
  .formsTg-frame {
    padding: 3.7em 0;
  }
}

.formsTg-btn {
  margin-top: 40px;
  font-size: 18px;
  color: white;
  background: #5698E5;
  height: 44px;
  width: 211px;
  border-radius: 30px;
}
@media screen and (max-width: 576px) {
  .formsTg-btn {
    margin-top: 30px;
    font-size: 14px;
    width: 165px;
  }
}
.formsTg-btn a {
  text-decoration: none;
}

.carousel-cell {
  height: 662px;
}
.carousel-cell img {
  height: 662px;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 1023px) {
  .carousel-cell img {
    height: 331px;
  }
}
@media (max-width: 639px) {
  .carousel-cell img {
    height: 255px;
  }
}
@media (max-width: 1023px) {
  .carousel-cell {
    height: 331px;
  }
}
@media (max-width: 639px) {
  .carousel-cell {
    height: 255px;
  }
}

.flickity-viewport {
  height: 662px;
}
@media (max-width: 1023px) {
  .flickity-viewport {
    height: 331px;
  }
}
@media (max-width: 639px) {
  .flickity-viewport {
    height: 255px;
  }
}

.flickity-prev-next-button {
  width: 110px !important;
  height: 110px !important;
  border-radius: 50% !important;
  background: transparent !important;
  border: 1px solid white !important;
}
.flickity-prev-next-button:active, .flickity-prev-next-button:focus {
  outline: none !important;
  box-shadow: none !important;
}
@media (max-width: 1023px) {
  .flickity-prev-next-button {
    width: 60px !important;
    height: 60px !important;
  }
}

.flickity-prev-next-button .flickity-button-icon {
  color: white !important;
}

.flickity-prev-next-button.previous {
  left: 18vw !important;
}
@media (max-width: 1400px) {
  .flickity-prev-next-button.previous {
    left: 10px !important;
  }
}

.flickity-prev-next-button.next {
  right: 18vw !important;
}
@media (max-width: 1400px) {
  .flickity-prev-next-button.next {
    right: 10px !important;
  }
}