.healthCenter {
	
}

.healthCenter-slider {
	max-width: 1180px;
	margin: 63px auto;
	
	@media (max-width: 600px) {
		margin: 30px auto;		
	}
}
