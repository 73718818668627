@import "../sprite";
@import "../misc/bootstrap";

.slider5 {
	visibility: hidden;
	margin: 4.6em 0;
	
	h2, .h2 {
		margin-bottom: 13px;
		margin-top: 17px;
	}
}

.slider5_visible {
	visibility: visible;
}

.slider5-content {
	position: relative;
	width: 92%;
	max-width: 1180px;
	margin: 0 auto;
	
	@include media-max(md) {
		width: 100%;
	}
}

.slider5-car {
	width: 10000%;
	position: relative;
	left: calc(-100% - 210px);
	display: flex;
	align-items: center;
	//margin-left: calc(-150% - 210px);
}

.slider5-item {
	width: 1%;
	display: inline-flex;
	margin: 0 70px;
	opacity: .2;
	&:nth-of-type(2) {
		opacity: 1;	
	}

	.slider5-img {
		transform: translateX(0);
		transition: .2s;
	}
	
	&:nth-of-type(3) {
		.slider5-img {
			transform: translateX(-120%);
			transition: .2s .2s;
			@include media-max(md) {
				transform: translateX(0);
			}
		}
	}

	@include media-max(md) {
		flex-wrap: wrap;
		max-height: 200px;
		transition: .4s;
		
		&:nth-of-type(2) {
			max-height: 1400px;
		}
	}
}

.slider5_movingRight {
	.slider5-item {
		&:nth-of-type(2) {
			opacity: .2;
		}
		&:nth-of-type(3) {
			opacity: 1;
			max-height: 1000px;
			
			.slider5-img {
				transform: translateX(0);
				transition: .2s;
			}
		}
	}
}


.slider5-text {
	width: 59.2%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-right: 55px;
	@include media-max(xl) {
		padding-left: 70px;
	}
	@include media-max(md) {
		background: #7db260 url('/img/leaves-green.jpg') -122px -317px;
		width: 100%;
		color: #fff;
		p,h1,h2,h3 {
			color: inherit;
		}
		padding-bottom: 20px
	}
}

.slider5-img {
	width: 50.8%;
	@include media-max(xl) {
		width: 40.8%;	
	}
	@include media-max(md) {
		width: 100%;
		order: -1;
	}
	img {
		width: 100%;
		display: block;
	}
}

.slider5-left, .slider5-right {
	position: absolute;
	top: 0;
	border: none;
	background: none;
	cursor: pointer;
	height: 100%;
	width: 62px;
	z-index: 2;
	

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		@include retina-sprite($arrow-gray-group);
	}
}

.slider5-left {
	&:after {
		transform: translateX(-50%) translateY(-50%) rotate(180deg);
	}
	left: -90px;
	@include media-max(xl) {
		left: 0;
	}
}

.slider5-right {
	&:after {
		transform: translateX(-50%) translateY(-50%);
		@include retina-sprite($arrow-white-group);
	}
	
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100px;
		height: 100px;
		background: #C3D6B1;
		border-radius: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
	right: -31px;

	@include media-max(xl) {
		right: 0;	
	}

	@include media-max(md) {
		&:after {
			@include retina-sprite($arrow-gray-group);
		}
		&:before {
			display: none;
		}
	}
}