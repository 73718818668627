// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow-down-name: 'arrow-down';
$arrow-down-x: 189px;
$arrow-down-y: 104px;
$arrow-down-offset-x: -189px;
$arrow-down-offset-y: -104px;
$arrow-down-width: 10px;
$arrow-down-height: 5px;
$arrow-down-total-width: 363px;
$arrow-down-total-height: 338px;
$arrow-down-image: '../img/sprite.png?t1685543367348';
$arrow-down: (189px, 104px, -189px, -104px, 10px, 5px, 363px, 338px, '../img/sprite.png?t1685543367348', 'arrow-down', );
$arrow-gray-name: 'arrow-gray';
$arrow-gray-x: 337px;
$arrow-gray-y: 164px;
$arrow-gray-offset-x: -337px;
$arrow-gray-offset-y: -164px;
$arrow-gray-width: 22px;
$arrow-gray-height: 48px;
$arrow-gray-total-width: 363px;
$arrow-gray-total-height: 338px;
$arrow-gray-image: '../img/sprite.png?t1685543367348';
$arrow-gray: (337px, 164px, -337px, -164px, 22px, 48px, 363px, 338px, '../img/sprite.png?t1685543367348', 'arrow-gray', );
$arrow-small-name: 'arrow-small';
$arrow-small-x: 350px;
$arrow-small-y: 278px;
$arrow-small-offset-x: -350px;
$arrow-small-offset-y: -278px;
$arrow-small-width: 11px;
$arrow-small-height: 22px;
$arrow-small-total-width: 363px;
$arrow-small-total-height: 338px;
$arrow-small-image: '../img/sprite.png?t1685543367348';
$arrow-small: (350px, 278px, -350px, -278px, 11px, 22px, 363px, 338px, '../img/sprite.png?t1685543367348', 'arrow-small', );
$arrow-white-name: 'arrow-white';
$arrow-white-x: 255px;
$arrow-white-y: 82px;
$arrow-white-offset-x: -255px;
$arrow-white-offset-y: -82px;
$arrow-white-width: 22px;
$arrow-white-height: 48px;
$arrow-white-total-width: 363px;
$arrow-white-total-height: 338px;
$arrow-white-image: '../img/sprite.png?t1685543367348';
$arrow-white: (255px, 82px, -255px, -82px, 22px, 48px, 363px, 338px, '../img/sprite.png?t1685543367348', 'arrow-white', );
$back-arrow-name: 'back-arrow';
$back-arrow-x: 153px;
$back-arrow-y: 104px;
$back-arrow-offset-x: -153px;
$back-arrow-offset-y: -104px;
$back-arrow-width: 22px;
$back-arrow-height: 8px;
$back-arrow-total-width: 363px;
$back-arrow-total-height: 338px;
$back-arrow-image: '../img/sprite.png?t1685543367348';
$back-arrow: (153px, 104px, -153px, -104px, 22px, 8px, 363px, 338px, '../img/sprite.png?t1685543367348', 'back-arrow', );
$bed-name: 'bed';
$bed-x: 283px;
$bed-y: 164px;
$bed-offset-x: -283px;
$bed-offset-y: -164px;
$bed-width: 52px;
$bed-height: 52px;
$bed-total-width: 363px;
$bed-total-height: 338px;
$bed-image: '../img/sprite.png?t1685543367348';
$bed: (283px, 164px, -283px, -164px, 52px, 52px, 363px, 338px, '../img/sprite.png?t1685543367348', 'bed', );
$bell-name: 'bell';
$bell-x: 99px;
$bell-y: 120px;
$bell-offset-x: -99px;
$bell-offset-y: -120px;
$bell-width: 17px;
$bell-height: 20px;
$bell-total-width: 363px;
$bell-total-height: 338px;
$bell-image: '../img/sprite.png?t1685543367348';
$bell: (99px, 120px, -99px, -120px, 17px, 20px, 363px, 338px, '../img/sprite.png?t1685543367348', 'bell', );
$close-name: 'close';
$close-x: 246px;
$close-y: 142px;
$close-offset-x: -246px;
$close-offset-y: -142px;
$close-width: 32px;
$close-height: 32px;
$close-total-width: 363px;
$close-total-height: 338px;
$close-image: '../img/sprite.png?t1685543367348';
$close: (246px, 142px, -246px, -142px, 32px, 32px, 363px, 338px, '../img/sprite.png?t1685543367348', 'close', );
$drop-arrow-name: 'drop-arrow';
$drop-arrow-x: 177px;
$drop-arrow-y: 104px;
$drop-arrow-offset-x: -177px;
$drop-arrow-offset-y: -104px;
$drop-arrow-width: 10px;
$drop-arrow-height: 6px;
$drop-arrow-total-width: 363px;
$drop-arrow-total-height: 338px;
$drop-arrow-image: '../img/sprite.png?t1685543367348';
$drop-arrow: (177px, 104px, -177px, -104px, 10px, 6px, 363px, 338px, '../img/sprite.png?t1685543367348', 'drop-arrow', );
$fb-name: 'fb';
$fb-x: 0px;
$fb-y: 278px;
$fb-offset-x: 0px;
$fb-offset-y: -278px;
$fb-width: 36px;
$fb-height: 36px;
$fb-total-width: 363px;
$fb-total-height: 338px;
$fb-image: '../img/sprite.png?t1685543367348';
$fb: (0px, 278px, 0px, -278px, 36px, 36px, 363px, 338px, '../img/sprite.png?t1685543367348', 'fb', );
$fee-name: 'fee';
$fee-x: 38px;
$fee-y: 278px;
$fee-offset-x: -38px;
$fee-offset-y: -278px;
$fee-width: 36px;
$fee-height: 36px;
$fee-total-width: 363px;
$fee-total-height: 338px;
$fee-image: '../img/sprite.png?t1685543367348';
$fee: (38px, 278px, -38px, -278px, 36px, 36px, 363px, 338px, '../img/sprite.png?t1685543367348', 'fee', );
$flamp-blue-name: 'flamp-blue';
$flamp-blue-x: 104px;
$flamp-blue-y: 224px;
$flamp-blue-offset-x: -104px;
$flamp-blue-offset-y: -224px;
$flamp-blue-width: 48px;
$flamp-blue-height: 48px;
$flamp-blue-total-width: 363px;
$flamp-blue-total-height: 338px;
$flamp-blue-image: '../img/sprite.png?t1685543367348';
$flamp-blue: (104px, 224px, -104px, -224px, 48px, 48px, 363px, 338px, '../img/sprite.png?t1685543367348', 'flamp-blue', );
$flamp-name: 'flamp';
$flamp-x: 154px;
$flamp-y: 224px;
$flamp-offset-x: -154px;
$flamp-offset-y: -224px;
$flamp-width: 48px;
$flamp-height: 48px;
$flamp-total-width: 363px;
$flamp-total-height: 338px;
$flamp-image: '../img/sprite.png?t1685543367348';
$flamp: (154px, 224px, -154px, -224px, 48px, 48px, 363px, 338px, '../img/sprite.png?t1685543367348', 'flamp', );
$fox-brown-name: 'fox-brown';
$fox-brown-x: 99px;
$fox-brown-y: 0px;
$fox-brown-offset-x: -99px;
$fox-brown-offset-y: 0px;
$fox-brown-width: 100px;
$fox-brown-height: 64px;
$fox-brown-total-width: 363px;
$fox-brown-total-height: 338px;
$fox-brown-image: '../img/sprite.png?t1685543367348';
$fox-brown: (99px, 0px, -99px, 0px, 100px, 64px, 363px, 338px, '../img/sprite.png?t1685543367348', 'fox-brown', );
$girl-name: 'girl';
$girl-x: 0px;
$girl-y: 0px;
$girl-offset-x: 0px;
$girl-offset-y: 0px;
$girl-width: 97px;
$girl-height: 140px;
$girl-total-width: 363px;
$girl-total-height: 338px;
$girl-image: '../img/sprite.png?t1685543367348';
$girl: (0px, 0px, 0px, 0px, 97px, 140px, 363px, 338px, '../img/sprite.png?t1685543367348', 'girl', );
$grill-name: 'grill';
$grill-x: 300px;
$grill-y: 278px;
$grill-offset-x: -300px;
$grill-offset-y: -278px;
$grill-width: 24px;
$grill-height: 26px;
$grill-total-width: 363px;
$grill-total-height: 338px;
$grill-image: '../img/sprite.png?t1685543367348';
$grill: (300px, 278px, -300px, -278px, 24px, 26px, 363px, 338px, '../img/sprite.png?t1685543367348', 'grill', );
$gym-name: 'gym';
$gym-x: 153px;
$gym-y: 66px;
$gym-offset-x: -153px;
$gym-offset-y: -66px;
$gym-width: 37px;
$gym-height: 36px;
$gym-total-width: 363px;
$gym-total-height: 338px;
$gym-image: '../img/sprite.png?t1685543367348';
$gym: (153px, 66px, -153px, -66px, 37px, 36px, 363px, 338px, '../img/sprite.png?t1685543367348', 'gym', );
$i-icon-name: 'i-icon';
$i-icon-x: 201px;
$i-icon-y: 0px;
$i-icon-offset-x: -201px;
$i-icon-offset-y: 0px;
$i-icon-width: 80px;
$i-icon-height: 80px;
$i-icon-total-width: 363px;
$i-icon-total-height: 338px;
$i-icon-image: '../img/sprite.png?t1685543367348';
$i-icon: (201px, 0px, -201px, 0px, 80px, 80px, 363px, 338px, '../img/sprite.png?t1685543367348', 'i-icon', );
$inst-name: 'inst';
$inst-x: 76px;
$inst-y: 278px;
$inst-offset-x: -76px;
$inst-offset-y: -278px;
$inst-width: 36px;
$inst-height: 36px;
$inst-total-width: 363px;
$inst-total-height: 338px;
$inst-image: '../img/sprite.png?t1685543367348';
$inst: (76px, 278px, -76px, -278px, 36px, 36px, 363px, 338px, '../img/sprite.png?t1685543367348', 'inst', );
$leaf-fill-green-name: 'leaf-fill-green';
$leaf-fill-green-x: 190px;
$leaf-fill-green-y: 278px;
$leaf-fill-green-offset-x: -190px;
$leaf-fill-green-offset-y: -278px;
$leaf-fill-green-width: 40px;
$leaf-fill-green-height: 29px;
$leaf-fill-green-total-width: 363px;
$leaf-fill-green-total-height: 338px;
$leaf-fill-green-image: '../img/sprite.png?t1685543367348';
$leaf-fill-green: (190px, 278px, -190px, -278px, 40px, 29px, 363px, 338px, '../img/sprite.png?t1685543367348', 'leaf-fill-green', );
$leaf-green-name: 'leaf-green';
$leaf-green-x: 326px;
$leaf-green-y: 278px;
$leaf-green-offset-x: -326px;
$leaf-green-offset-y: -278px;
$leaf-green-width: 22px;
$leaf-green-height: 22px;
$leaf-green-total-width: 363px;
$leaf-green-total-height: 338px;
$leaf-green-image: '../img/sprite.png?t1685543367348';
$leaf-green: (326px, 278px, -326px, -278px, 22px, 22px, 363px, 338px, '../img/sprite.png?t1685543367348', 'leaf-green', );
$leaf-name: 'leaf';
$leaf-x: 0px;
$leaf-y: 316px;
$leaf-offset-x: 0px;
$leaf-offset-y: -316px;
$leaf-width: 22px;
$leaf-height: 22px;
$leaf-total-width: 363px;
$leaf-total-height: 338px;
$leaf-image: '../img/sprite.png?t1685543367348';
$leaf: (0px, 316px, 0px, -316px, 22px, 22px, 363px, 338px, '../img/sprite.png?t1685543367348', 'leaf', );
$medal-name: 'medal';
$medal-x: 54px;
$medal-y: 224px;
$medal-offset-x: -54px;
$medal-offset-y: -224px;
$medal-width: 48px;
$medal-height: 52px;
$medal-total-width: 363px;
$medal-total-height: 338px;
$medal-image: '../img/sprite.png?t1685543367348';
$medal: (54px, 224px, -54px, -224px, 48px, 52px, 363px, 338px, '../img/sprite.png?t1685543367348', 'medal', );
$minus-name: 'minus';
$minus-x: 0px;
$minus-y: 142px;
$minus-offset-x: 0px;
$minus-offset-y: -142px;
$minus-width: 80px;
$minus-height: 80px;
$minus-total-width: 363px;
$minus-total-height: 338px;
$minus-image: '../img/sprite.png?t1685543367348';
$minus: (0px, 142px, 0px, -142px, 80px, 80px, 363px, 338px, '../img/sprite.png?t1685543367348', 'minus', );
$pdf-name: 'pdf';
$pdf-x: 82px;
$pdf-y: 142px;
$pdf-offset-x: -82px;
$pdf-offset-y: -142px;
$pdf-width: 80px;
$pdf-height: 80px;
$pdf-total-width: 363px;
$pdf-total-height: 338px;
$pdf-image: '../img/sprite.png?t1685543367348';
$pdf: (82px, 142px, -82px, -142px, 80px, 80px, 363px, 338px, '../img/sprite.png?t1685543367348', 'pdf', );
$pen-red-name: 'pen-red';
$pen-red-x: 204px;
$pen-red-y: 224px;
$pen-red-offset-x: -204px;
$pen-red-offset-y: -224px;
$pen-red-width: 48px;
$pen-red-height: 48px;
$pen-red-total-width: 363px;
$pen-red-total-height: 338px;
$pen-red-image: '../img/sprite.png?t1685543367348';
$pen-red: (204px, 224px, -204px, -224px, 48px, 48px, 363px, 338px, '../img/sprite.png?t1685543367348', 'pen-red', );
$pen-name: 'pen';
$pen-x: 254px;
$pen-y: 224px;
$pen-offset-x: -254px;
$pen-offset-y: -224px;
$pen-width: 48px;
$pen-height: 48px;
$pen-total-width: 363px;
$pen-total-height: 338px;
$pen-image: '../img/sprite.png?t1685543367348';
$pen: (254px, 224px, -254px, -224px, 48px, 48px, 363px, 338px, '../img/sprite.png?t1685543367348', 'pen', );
$plus-name: 'plus';
$plus-x: 164px;
$plus-y: 142px;
$plus-offset-x: -164px;
$plus-offset-y: -142px;
$plus-width: 80px;
$plus-height: 80px;
$plus-total-width: 363px;
$plus-total-height: 338px;
$plus-image: '../img/sprite.png?t1685543367348';
$plus: (164px, 142px, -164px, -142px, 80px, 80px, 363px, 338px, '../img/sprite.png?t1685543367348', 'plus', );
$poll-name: 'poll';
$poll-x: 201px;
$poll-y: 82px;
$poll-offset-x: -201px;
$poll-offset-y: -82px;
$poll-width: 52px;
$poll-height: 52px;
$poll-total-width: 363px;
$poll-total-height: 338px;
$poll-image: '../img/sprite.png?t1685543367348';
$poll: (201px, 82px, -201px, -82px, 52px, 52px, 363px, 338px, '../img/sprite.png?t1685543367348', 'poll', );
$price-header-name: 'price-header';
$price-header-x: 24px;
$price-header-y: 316px;
$price-header-offset-x: -24px;
$price-header-offset-y: -316px;
$price-header-width: 22px;
$price-header-height: 22px;
$price-header-total-width: 363px;
$price-header-total-height: 338px;
$price-header-image: '../img/sprite.png?t1685543367348';
$price-header: (24px, 316px, -24px, -316px, 22px, 22px, 363px, 338px, '../img/sprite.png?t1685543367348', 'price-header', );
$quote-name: 'quote';
$quote-x: 304px;
$quote-y: 224px;
$quote-offset-x: -304px;
$quote-offset-y: -224px;
$quote-width: 50px;
$quote-height: 40px;
$quote-total-width: 363px;
$quote-total-height: 338px;
$quote-image: '../img/sprite.png?t1685543367348';
$quote: (304px, 224px, -304px, -224px, 50px, 40px, 363px, 338px, '../img/sprite.png?t1685543367348', 'quote', );
$star-yellow-name: 'star-yellow';
$star-yellow-x: 48px;
$star-yellow-y: 316px;
$star-yellow-offset-x: -48px;
$star-yellow-offset-y: -316px;
$star-yellow-width: 22px;
$star-yellow-height: 22px;
$star-yellow-total-width: 363px;
$star-yellow-total-height: 338px;
$star-yellow-image: '../img/sprite.png?t1685543367348';
$star-yellow: (48px, 316px, -48px, -316px, 22px, 22px, 363px, 338px, '../img/sprite.png?t1685543367348', 'star-yellow', );
$star-name: 'star';
$star-x: 72px;
$star-y: 316px;
$star-offset-x: -72px;
$star-offset-y: -316px;
$star-width: 22px;
$star-height: 22px;
$star-total-width: 363px;
$star-total-height: 338px;
$star-image: '../img/sprite.png?t1685543367348';
$star: (72px, 316px, -72px, -316px, 22px, 22px, 363px, 338px, '../img/sprite.png?t1685543367348', 'star', );
$tableware-name: 'tableware';
$tableware-x: 99px;
$tableware-y: 66px;
$tableware-offset-x: -99px;
$tableware-offset-y: -66px;
$tableware-width: 52px;
$tableware-height: 52px;
$tableware-total-width: 363px;
$tableware-total-height: 338px;
$tableware-image: '../img/sprite.png?t1685543367348';
$tableware: (99px, 66px, -99px, -66px, 52px, 52px, 363px, 338px, '../img/sprite.png?t1685543367348', 'tableware', );
$tick-name: 'tick';
$tick-x: 138px;
$tick-y: 120px;
$tick-offset-x: -138px;
$tick-offset-y: -120px;
$tick-width: 16px;
$tick-height: 11px;
$tick-total-width: 363px;
$tick-total-height: 338px;
$tick-image: '../img/sprite.png?t1685543367348';
$tick: (138px, 120px, -138px, -120px, 16px, 11px, 363px, 338px, '../img/sprite.png?t1685543367348', 'tick', );
$vk-name: 'vk';
$vk-x: 114px;
$vk-y: 278px;
$vk-offset-x: -114px;
$vk-offset-y: -278px;
$vk-width: 36px;
$vk-height: 36px;
$vk-total-width: 363px;
$vk-total-height: 338px;
$vk-image: '../img/sprite.png?t1685543367348';
$vk: (114px, 278px, -114px, -278px, 36px, 36px, 363px, 338px, '../img/sprite.png?t1685543367348', 'vk', );
$whatsapp-name: 'whatsapp';
$whatsapp-x: 118px;
$whatsapp-y: 120px;
$whatsapp-offset-x: -118px;
$whatsapp-offset-y: -120px;
$whatsapp-width: 18px;
$whatsapp-height: 18px;
$whatsapp-total-width: 363px;
$whatsapp-total-height: 338px;
$whatsapp-image: '../img/sprite.png?t1685543367348';
$whatsapp: (118px, 120px, -118px, -120px, 18px, 18px, 363px, 338px, '../img/sprite.png?t1685543367348', 'whatsapp', );
$white-star-name: 'white-star';
$white-star-x: 246px;
$white-star-y: 176px;
$white-star-offset-x: -246px;
$white-star-offset-y: -176px;
$white-star-width: 29px;
$white-star-height: 28px;
$white-star-total-width: 363px;
$white-star-total-height: 338px;
$white-star-image: '../img/sprite.png?t1685543367348';
$white-star: (246px, 176px, -246px, -176px, 29px, 28px, 363px, 338px, '../img/sprite.png?t1685543367348', 'white-star', );
$white-price-name: 'whitePrice';
$white-price-x: 272px;
$white-price-y: 278px;
$white-price-offset-x: -272px;
$white-price-offset-y: -278px;
$white-price-width: 26px;
$white-price-height: 26px;
$white-price-total-width: 363px;
$white-price-total-height: 338px;
$white-price-image: '../img/sprite.png?t1685543367348';
$white-price: (272px, 278px, -272px, -278px, 26px, 26px, 363px, 338px, '../img/sprite.png?t1685543367348', 'whitePrice', );
$wifi-name: 'wifi';
$wifi-x: 232px;
$wifi-y: 278px;
$wifi-offset-x: -232px;
$wifi-offset-y: -278px;
$wifi-width: 38px;
$wifi-height: 28px;
$wifi-total-width: 363px;
$wifi-total-height: 338px;
$wifi-image: '../img/sprite.png?t1685543367348';
$wifi: (232px, 278px, -232px, -278px, 38px, 28px, 363px, 338px, '../img/sprite.png?t1685543367348', 'wifi', );
$word-name: 'word';
$word-x: 283px;
$word-y: 0px;
$word-offset-x: -283px;
$word-offset-y: 0px;
$word-width: 80px;
$word-height: 80px;
$word-total-width: 363px;
$word-total-height: 338px;
$word-image: '../img/sprite.png?t1685543367348';
$word: (283px, 0px, -283px, 0px, 80px, 80px, 363px, 338px, '../img/sprite.png?t1685543367348', 'word', );
$xls-name: 'xls';
$xls-x: 283px;
$xls-y: 82px;
$xls-offset-x: -283px;
$xls-offset-y: -82px;
$xls-width: 80px;
$xls-height: 80px;
$xls-total-width: 363px;
$xls-total-height: 338px;
$xls-image: '../img/sprite.png?t1685543367348';
$xls: (283px, 82px, -283px, -82px, 80px, 80px, 363px, 338px, '../img/sprite.png?t1685543367348', 'xls', );
$you-name: 'you';
$you-x: 152px;
$you-y: 278px;
$you-offset-x: -152px;
$you-offset-y: -278px;
$you-width: 36px;
$you-height: 36px;
$you-total-width: 363px;
$you-total-height: 338px;
$you-image: '../img/sprite.png?t1685543367348';
$you: (152px, 278px, -152px, -278px, 36px, 36px, 363px, 338px, '../img/sprite.png?t1685543367348', 'you', );
$zen-name: 'zen';
$zen-x: 0px;
$zen-y: 224px;
$zen-offset-x: 0px;
$zen-offset-y: -224px;
$zen-width: 52px;
$zen-height: 52px;
$zen-total-width: 363px;
$zen-total-height: 338px;
$zen-image: '../img/sprite.png?t1685543367348';
$zen: (0px, 224px, 0px, -224px, 52px, 52px, 363px, 338px, '../img/sprite.png?t1685543367348', 'zen', );
$arrow-down-2x-name: 'arrow-down@2x';
$arrow-down-2x-x: 378px;
$arrow-down-2x-y: 208px;
$arrow-down-2x-offset-x: -378px;
$arrow-down-2x-offset-y: -208px;
$arrow-down-2x-width: 20px;
$arrow-down-2x-height: 10px;
$arrow-down-2x-total-width: 726px;
$arrow-down-2x-total-height: 676px;
$arrow-down-2x-image: '../img/sprite@2x.png?t1685543367348';
$arrow-down-2x: (378px, 208px, -378px, -208px, 20px, 10px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'arrow-down@2x', );
$arrow-gray-2x-name: 'arrow-gray@2x';
$arrow-gray-2x-x: 674px;
$arrow-gray-2x-y: 328px;
$arrow-gray-2x-offset-x: -674px;
$arrow-gray-2x-offset-y: -328px;
$arrow-gray-2x-width: 44px;
$arrow-gray-2x-height: 96px;
$arrow-gray-2x-total-width: 726px;
$arrow-gray-2x-total-height: 676px;
$arrow-gray-2x-image: '../img/sprite@2x.png?t1685543367348';
$arrow-gray-2x: (674px, 328px, -674px, -328px, 44px, 96px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'arrow-gray@2x', );
$arrow-small-2x-name: 'arrow-small@2x';
$arrow-small-2x-x: 700px;
$arrow-small-2x-y: 556px;
$arrow-small-2x-offset-x: -700px;
$arrow-small-2x-offset-y: -556px;
$arrow-small-2x-width: 22px;
$arrow-small-2x-height: 44px;
$arrow-small-2x-total-width: 726px;
$arrow-small-2x-total-height: 676px;
$arrow-small-2x-image: '../img/sprite@2x.png?t1685543367348';
$arrow-small-2x: (700px, 556px, -700px, -556px, 22px, 44px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'arrow-small@2x', );
$arrow-white-2x-name: 'arrow-white@2x';
$arrow-white-2x-x: 510px;
$arrow-white-2x-y: 164px;
$arrow-white-2x-offset-x: -510px;
$arrow-white-2x-offset-y: -164px;
$arrow-white-2x-width: 44px;
$arrow-white-2x-height: 96px;
$arrow-white-2x-total-width: 726px;
$arrow-white-2x-total-height: 676px;
$arrow-white-2x-image: '../img/sprite@2x.png?t1685543367348';
$arrow-white-2x: (510px, 164px, -510px, -164px, 44px, 96px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'arrow-white@2x', );
$back-arrow-2x-name: 'back-arrow@2x';
$back-arrow-2x-x: 306px;
$back-arrow-2x-y: 208px;
$back-arrow-2x-offset-x: -306px;
$back-arrow-2x-offset-y: -208px;
$back-arrow-2x-width: 44px;
$back-arrow-2x-height: 16px;
$back-arrow-2x-total-width: 726px;
$back-arrow-2x-total-height: 676px;
$back-arrow-2x-image: '../img/sprite@2x.png?t1685543367348';
$back-arrow-2x: (306px, 208px, -306px, -208px, 44px, 16px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'back-arrow@2x', );
$bed-2x-name: 'bed@2x';
$bed-2x-x: 566px;
$bed-2x-y: 328px;
$bed-2x-offset-x: -566px;
$bed-2x-offset-y: -328px;
$bed-2x-width: 104px;
$bed-2x-height: 104px;
$bed-2x-total-width: 726px;
$bed-2x-total-height: 676px;
$bed-2x-image: '../img/sprite@2x.png?t1685543367348';
$bed-2x: (566px, 328px, -566px, -328px, 104px, 104px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'bed@2x', );
$bell-2x-name: 'bell@2x';
$bell-2x-x: 198px;
$bell-2x-y: 240px;
$bell-2x-offset-x: -198px;
$bell-2x-offset-y: -240px;
$bell-2x-width: 34px;
$bell-2x-height: 40px;
$bell-2x-total-width: 726px;
$bell-2x-total-height: 676px;
$bell-2x-image: '../img/sprite@2x.png?t1685543367348';
$bell-2x: (198px, 240px, -198px, -240px, 34px, 40px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'bell@2x', );
$close-2x-name: 'close@2x';
$close-2x-x: 492px;
$close-2x-y: 284px;
$close-2x-offset-x: -492px;
$close-2x-offset-y: -284px;
$close-2x-width: 64px;
$close-2x-height: 64px;
$close-2x-total-width: 726px;
$close-2x-total-height: 676px;
$close-2x-image: '../img/sprite@2x.png?t1685543367348';
$close-2x: (492px, 284px, -492px, -284px, 64px, 64px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'close@2x', );
$drop-arrow-2x-name: 'drop-arrow@2x';
$drop-arrow-2x-x: 354px;
$drop-arrow-2x-y: 208px;
$drop-arrow-2x-offset-x: -354px;
$drop-arrow-2x-offset-y: -208px;
$drop-arrow-2x-width: 20px;
$drop-arrow-2x-height: 12px;
$drop-arrow-2x-total-width: 726px;
$drop-arrow-2x-total-height: 676px;
$drop-arrow-2x-image: '../img/sprite@2x.png?t1685543367348';
$drop-arrow-2x: (354px, 208px, -354px, -208px, 20px, 12px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'drop-arrow@2x', );
$fb-2x-name: 'fb@2x';
$fb-2x-x: 0px;
$fb-2x-y: 556px;
$fb-2x-offset-x: 0px;
$fb-2x-offset-y: -556px;
$fb-2x-width: 72px;
$fb-2x-height: 72px;
$fb-2x-total-width: 726px;
$fb-2x-total-height: 676px;
$fb-2x-image: '../img/sprite@2x.png?t1685543367348';
$fb-2x: (0px, 556px, 0px, -556px, 72px, 72px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'fb@2x', );
$fee-2x-name: 'fee@2x';
$fee-2x-x: 76px;
$fee-2x-y: 556px;
$fee-2x-offset-x: -76px;
$fee-2x-offset-y: -556px;
$fee-2x-width: 72px;
$fee-2x-height: 72px;
$fee-2x-total-width: 726px;
$fee-2x-total-height: 676px;
$fee-2x-image: '../img/sprite@2x.png?t1685543367348';
$fee-2x: (76px, 556px, -76px, -556px, 72px, 72px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'fee@2x', );
$flamp-blue-2x-name: 'flamp-blue@2x';
$flamp-blue-2x-x: 208px;
$flamp-blue-2x-y: 448px;
$flamp-blue-2x-offset-x: -208px;
$flamp-blue-2x-offset-y: -448px;
$flamp-blue-2x-width: 96px;
$flamp-blue-2x-height: 96px;
$flamp-blue-2x-total-width: 726px;
$flamp-blue-2x-total-height: 676px;
$flamp-blue-2x-image: '../img/sprite@2x.png?t1685543367348';
$flamp-blue-2x: (208px, 448px, -208px, -448px, 96px, 96px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'flamp-blue@2x', );
$flamp-2x-name: 'flamp@2x';
$flamp-2x-x: 308px;
$flamp-2x-y: 448px;
$flamp-2x-offset-x: -308px;
$flamp-2x-offset-y: -448px;
$flamp-2x-width: 96px;
$flamp-2x-height: 96px;
$flamp-2x-total-width: 726px;
$flamp-2x-total-height: 676px;
$flamp-2x-image: '../img/sprite@2x.png?t1685543367348';
$flamp-2x: (308px, 448px, -308px, -448px, 96px, 96px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'flamp@2x', );
$fox-brown-2x-name: 'fox-brown@2x';
$fox-brown-2x-x: 198px;
$fox-brown-2x-y: 0px;
$fox-brown-2x-offset-x: -198px;
$fox-brown-2x-offset-y: 0px;
$fox-brown-2x-width: 200px;
$fox-brown-2x-height: 128px;
$fox-brown-2x-total-width: 726px;
$fox-brown-2x-total-height: 676px;
$fox-brown-2x-image: '../img/sprite@2x.png?t1685543367348';
$fox-brown-2x: (198px, 0px, -198px, 0px, 200px, 128px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'fox-brown@2x', );
$girl-2x-name: 'girl@2x';
$girl-2x-x: 0px;
$girl-2x-y: 0px;
$girl-2x-offset-x: 0px;
$girl-2x-offset-y: 0px;
$girl-2x-width: 194px;
$girl-2x-height: 280px;
$girl-2x-total-width: 726px;
$girl-2x-total-height: 676px;
$girl-2x-image: '../img/sprite@2x.png?t1685543367348';
$girl-2x: (0px, 0px, 0px, 0px, 194px, 280px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'girl@2x', );
$grill-2x-name: 'grill@2x';
$grill-2x-x: 600px;
$grill-2x-y: 556px;
$grill-2x-offset-x: -600px;
$grill-2x-offset-y: -556px;
$grill-2x-width: 48px;
$grill-2x-height: 52px;
$grill-2x-total-width: 726px;
$grill-2x-total-height: 676px;
$grill-2x-image: '../img/sprite@2x.png?t1685543367348';
$grill-2x: (600px, 556px, -600px, -556px, 48px, 52px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'grill@2x', );
$gym-2x-name: 'gym@2x';
$gym-2x-x: 306px;
$gym-2x-y: 132px;
$gym-2x-offset-x: -306px;
$gym-2x-offset-y: -132px;
$gym-2x-width: 74px;
$gym-2x-height: 72px;
$gym-2x-total-width: 726px;
$gym-2x-total-height: 676px;
$gym-2x-image: '../img/sprite@2x.png?t1685543367348';
$gym-2x: (306px, 132px, -306px, -132px, 74px, 72px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'gym@2x', );
$i-icon-2x-name: 'i-icon@2x';
$i-icon-2x-x: 402px;
$i-icon-2x-y: 0px;
$i-icon-2x-offset-x: -402px;
$i-icon-2x-offset-y: 0px;
$i-icon-2x-width: 160px;
$i-icon-2x-height: 160px;
$i-icon-2x-total-width: 726px;
$i-icon-2x-total-height: 676px;
$i-icon-2x-image: '../img/sprite@2x.png?t1685543367348';
$i-icon-2x: (402px, 0px, -402px, 0px, 160px, 160px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'i-icon@2x', );
$inst-2x-name: 'inst@2x';
$inst-2x-x: 152px;
$inst-2x-y: 556px;
$inst-2x-offset-x: -152px;
$inst-2x-offset-y: -556px;
$inst-2x-width: 72px;
$inst-2x-height: 72px;
$inst-2x-total-width: 726px;
$inst-2x-total-height: 676px;
$inst-2x-image: '../img/sprite@2x.png?t1685543367348';
$inst-2x: (152px, 556px, -152px, -556px, 72px, 72px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'inst@2x', );
$leaf-fill-green-2x-name: 'leaf-fill-green@2x';
$leaf-fill-green-2x-x: 380px;
$leaf-fill-green-2x-y: 556px;
$leaf-fill-green-2x-offset-x: -380px;
$leaf-fill-green-2x-offset-y: -556px;
$leaf-fill-green-2x-width: 80px;
$leaf-fill-green-2x-height: 58px;
$leaf-fill-green-2x-total-width: 726px;
$leaf-fill-green-2x-total-height: 676px;
$leaf-fill-green-2x-image: '../img/sprite@2x.png?t1685543367348';
$leaf-fill-green-2x: (380px, 556px, -380px, -556px, 80px, 58px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'leaf-fill-green@2x', );
$leaf-green-2x-name: 'leaf-green@2x';
$leaf-green-2x-x: 652px;
$leaf-green-2x-y: 556px;
$leaf-green-2x-offset-x: -652px;
$leaf-green-2x-offset-y: -556px;
$leaf-green-2x-width: 44px;
$leaf-green-2x-height: 44px;
$leaf-green-2x-total-width: 726px;
$leaf-green-2x-total-height: 676px;
$leaf-green-2x-image: '../img/sprite@2x.png?t1685543367348';
$leaf-green-2x: (652px, 556px, -652px, -556px, 44px, 44px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'leaf-green@2x', );
$leaf-2x-name: 'leaf@2x';
$leaf-2x-x: 0px;
$leaf-2x-y: 632px;
$leaf-2x-offset-x: 0px;
$leaf-2x-offset-y: -632px;
$leaf-2x-width: 44px;
$leaf-2x-height: 44px;
$leaf-2x-total-width: 726px;
$leaf-2x-total-height: 676px;
$leaf-2x-image: '../img/sprite@2x.png?t1685543367348';
$leaf-2x: (0px, 632px, 0px, -632px, 44px, 44px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'leaf@2x', );
$medal-2x-name: 'medal@2x';
$medal-2x-x: 108px;
$medal-2x-y: 448px;
$medal-2x-offset-x: -108px;
$medal-2x-offset-y: -448px;
$medal-2x-width: 96px;
$medal-2x-height: 104px;
$medal-2x-total-width: 726px;
$medal-2x-total-height: 676px;
$medal-2x-image: '../img/sprite@2x.png?t1685543367348';
$medal-2x: (108px, 448px, -108px, -448px, 96px, 104px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'medal@2x', );
$minus-2x-name: 'minus@2x';
$minus-2x-x: 0px;
$minus-2x-y: 284px;
$minus-2x-offset-x: 0px;
$minus-2x-offset-y: -284px;
$minus-2x-width: 160px;
$minus-2x-height: 160px;
$minus-2x-total-width: 726px;
$minus-2x-total-height: 676px;
$minus-2x-image: '../img/sprite@2x.png?t1685543367348';
$minus-2x: (0px, 284px, 0px, -284px, 160px, 160px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'minus@2x', );
$pdf-2x-name: 'pdf@2x';
$pdf-2x-x: 164px;
$pdf-2x-y: 284px;
$pdf-2x-offset-x: -164px;
$pdf-2x-offset-y: -284px;
$pdf-2x-width: 160px;
$pdf-2x-height: 160px;
$pdf-2x-total-width: 726px;
$pdf-2x-total-height: 676px;
$pdf-2x-image: '../img/sprite@2x.png?t1685543367348';
$pdf-2x: (164px, 284px, -164px, -284px, 160px, 160px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'pdf@2x', );
$pen-red-2x-name: 'pen-red@2x';
$pen-red-2x-x: 408px;
$pen-red-2x-y: 448px;
$pen-red-2x-offset-x: -408px;
$pen-red-2x-offset-y: -448px;
$pen-red-2x-width: 96px;
$pen-red-2x-height: 96px;
$pen-red-2x-total-width: 726px;
$pen-red-2x-total-height: 676px;
$pen-red-2x-image: '../img/sprite@2x.png?t1685543367348';
$pen-red-2x: (408px, 448px, -408px, -448px, 96px, 96px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'pen-red@2x', );
$pen-2x-name: 'pen@2x';
$pen-2x-x: 508px;
$pen-2x-y: 448px;
$pen-2x-offset-x: -508px;
$pen-2x-offset-y: -448px;
$pen-2x-width: 96px;
$pen-2x-height: 96px;
$pen-2x-total-width: 726px;
$pen-2x-total-height: 676px;
$pen-2x-image: '../img/sprite@2x.png?t1685543367348';
$pen-2x: (508px, 448px, -508px, -448px, 96px, 96px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'pen@2x', );
$plus-2x-name: 'plus@2x';
$plus-2x-x: 328px;
$plus-2x-y: 284px;
$plus-2x-offset-x: -328px;
$plus-2x-offset-y: -284px;
$plus-2x-width: 160px;
$plus-2x-height: 160px;
$plus-2x-total-width: 726px;
$plus-2x-total-height: 676px;
$plus-2x-image: '../img/sprite@2x.png?t1685543367348';
$plus-2x: (328px, 284px, -328px, -284px, 160px, 160px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'plus@2x', );
$poll-2x-name: 'poll@2x';
$poll-2x-x: 402px;
$poll-2x-y: 164px;
$poll-2x-offset-x: -402px;
$poll-2x-offset-y: -164px;
$poll-2x-width: 104px;
$poll-2x-height: 104px;
$poll-2x-total-width: 726px;
$poll-2x-total-height: 676px;
$poll-2x-image: '../img/sprite@2x.png?t1685543367348';
$poll-2x: (402px, 164px, -402px, -164px, 104px, 104px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'poll@2x', );
$price-header-2x-name: 'price-header@2x';
$price-header-2x-x: 48px;
$price-header-2x-y: 632px;
$price-header-2x-offset-x: -48px;
$price-header-2x-offset-y: -632px;
$price-header-2x-width: 44px;
$price-header-2x-height: 44px;
$price-header-2x-total-width: 726px;
$price-header-2x-total-height: 676px;
$price-header-2x-image: '../img/sprite@2x.png?t1685543367348';
$price-header-2x: (48px, 632px, -48px, -632px, 44px, 44px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'price-header@2x', );
$quote-2x-name: 'quote@2x';
$quote-2x-x: 608px;
$quote-2x-y: 448px;
$quote-2x-offset-x: -608px;
$quote-2x-offset-y: -448px;
$quote-2x-width: 100px;
$quote-2x-height: 80px;
$quote-2x-total-width: 726px;
$quote-2x-total-height: 676px;
$quote-2x-image: '../img/sprite@2x.png?t1685543367348';
$quote-2x: (608px, 448px, -608px, -448px, 100px, 80px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'quote@2x', );
$star-yellow-2x-name: 'star-yellow@2x';
$star-yellow-2x-x: 96px;
$star-yellow-2x-y: 632px;
$star-yellow-2x-offset-x: -96px;
$star-yellow-2x-offset-y: -632px;
$star-yellow-2x-width: 44px;
$star-yellow-2x-height: 44px;
$star-yellow-2x-total-width: 726px;
$star-yellow-2x-total-height: 676px;
$star-yellow-2x-image: '../img/sprite@2x.png?t1685543367348';
$star-yellow-2x: (96px, 632px, -96px, -632px, 44px, 44px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'star-yellow@2x', );
$star-2x-name: 'star@2x';
$star-2x-x: 144px;
$star-2x-y: 632px;
$star-2x-offset-x: -144px;
$star-2x-offset-y: -632px;
$star-2x-width: 44px;
$star-2x-height: 44px;
$star-2x-total-width: 726px;
$star-2x-total-height: 676px;
$star-2x-image: '../img/sprite@2x.png?t1685543367348';
$star-2x: (144px, 632px, -144px, -632px, 44px, 44px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'star@2x', );
$tableware-2x-name: 'tableware@2x';
$tableware-2x-x: 198px;
$tableware-2x-y: 132px;
$tableware-2x-offset-x: -198px;
$tableware-2x-offset-y: -132px;
$tableware-2x-width: 104px;
$tableware-2x-height: 104px;
$tableware-2x-total-width: 726px;
$tableware-2x-total-height: 676px;
$tableware-2x-image: '../img/sprite@2x.png?t1685543367348';
$tableware-2x: (198px, 132px, -198px, -132px, 104px, 104px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'tableware@2x', );
$tick-2x-name: 'tick@2x';
$tick-2x-x: 276px;
$tick-2x-y: 240px;
$tick-2x-offset-x: -276px;
$tick-2x-offset-y: -240px;
$tick-2x-width: 32px;
$tick-2x-height: 22px;
$tick-2x-total-width: 726px;
$tick-2x-total-height: 676px;
$tick-2x-image: '../img/sprite@2x.png?t1685543367348';
$tick-2x: (276px, 240px, -276px, -240px, 32px, 22px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'tick@2x', );
$vk-2x-name: 'vk@2x';
$vk-2x-x: 228px;
$vk-2x-y: 556px;
$vk-2x-offset-x: -228px;
$vk-2x-offset-y: -556px;
$vk-2x-width: 72px;
$vk-2x-height: 72px;
$vk-2x-total-width: 726px;
$vk-2x-total-height: 676px;
$vk-2x-image: '../img/sprite@2x.png?t1685543367348';
$vk-2x: (228px, 556px, -228px, -556px, 72px, 72px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'vk@2x', );
$whatsapp-2x-name: 'whatsapp@2x';
$whatsapp-2x-x: 236px;
$whatsapp-2x-y: 240px;
$whatsapp-2x-offset-x: -236px;
$whatsapp-2x-offset-y: -240px;
$whatsapp-2x-width: 36px;
$whatsapp-2x-height: 36px;
$whatsapp-2x-total-width: 726px;
$whatsapp-2x-total-height: 676px;
$whatsapp-2x-image: '../img/sprite@2x.png?t1685543367348';
$whatsapp-2x: (236px, 240px, -236px, -240px, 36px, 36px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'whatsapp@2x', );
$white-star-2x-name: 'white-star@2x';
$white-star-2x-x: 492px;
$white-star-2x-y: 352px;
$white-star-2x-offset-x: -492px;
$white-star-2x-offset-y: -352px;
$white-star-2x-width: 58px;
$white-star-2x-height: 56px;
$white-star-2x-total-width: 726px;
$white-star-2x-total-height: 676px;
$white-star-2x-image: '../img/sprite@2x.png?t1685543367348';
$white-star-2x: (492px, 352px, -492px, -352px, 58px, 56px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'white-star@2x', );
$white-price-2x-name: 'whitePrice@2x';
$white-price-2x-x: 544px;
$white-price-2x-y: 556px;
$white-price-2x-offset-x: -544px;
$white-price-2x-offset-y: -556px;
$white-price-2x-width: 52px;
$white-price-2x-height: 52px;
$white-price-2x-total-width: 726px;
$white-price-2x-total-height: 676px;
$white-price-2x-image: '../img/sprite@2x.png?t1685543367348';
$white-price-2x: (544px, 556px, -544px, -556px, 52px, 52px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'whitePrice@2x', );
$wifi-2x-name: 'wifi@2x';
$wifi-2x-x: 464px;
$wifi-2x-y: 556px;
$wifi-2x-offset-x: -464px;
$wifi-2x-offset-y: -556px;
$wifi-2x-width: 76px;
$wifi-2x-height: 56px;
$wifi-2x-total-width: 726px;
$wifi-2x-total-height: 676px;
$wifi-2x-image: '../img/sprite@2x.png?t1685543367348';
$wifi-2x: (464px, 556px, -464px, -556px, 76px, 56px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'wifi@2x', );
$word-2x-name: 'word@2x';
$word-2x-x: 566px;
$word-2x-y: 0px;
$word-2x-offset-x: -566px;
$word-2x-offset-y: 0px;
$word-2x-width: 160px;
$word-2x-height: 160px;
$word-2x-total-width: 726px;
$word-2x-total-height: 676px;
$word-2x-image: '../img/sprite@2x.png?t1685543367348';
$word-2x: (566px, 0px, -566px, 0px, 160px, 160px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'word@2x', );
$xls-2x-name: 'xls@2x';
$xls-2x-x: 566px;
$xls-2x-y: 164px;
$xls-2x-offset-x: -566px;
$xls-2x-offset-y: -164px;
$xls-2x-width: 160px;
$xls-2x-height: 160px;
$xls-2x-total-width: 726px;
$xls-2x-total-height: 676px;
$xls-2x-image: '../img/sprite@2x.png?t1685543367348';
$xls-2x: (566px, 164px, -566px, -164px, 160px, 160px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'xls@2x', );
$you-2x-name: 'you@2x';
$you-2x-x: 304px;
$you-2x-y: 556px;
$you-2x-offset-x: -304px;
$you-2x-offset-y: -556px;
$you-2x-width: 72px;
$you-2x-height: 72px;
$you-2x-total-width: 726px;
$you-2x-total-height: 676px;
$you-2x-image: '../img/sprite@2x.png?t1685543367348';
$you-2x: (304px, 556px, -304px, -556px, 72px, 72px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'you@2x', );
$zen-2x-name: 'zen@2x';
$zen-2x-x: 0px;
$zen-2x-y: 448px;
$zen-2x-offset-x: 0px;
$zen-2x-offset-y: -448px;
$zen-2x-width: 104px;
$zen-2x-height: 104px;
$zen-2x-total-width: 726px;
$zen-2x-total-height: 676px;
$zen-2x-image: '../img/sprite@2x.png?t1685543367348';
$zen-2x: (0px, 448px, 0px, -448px, 104px, 104px, 726px, 676px, '../img/sprite@2x.png?t1685543367348', 'zen@2x', );
$spritesheet-width: 363px;
$spritesheet-height: 338px;
$spritesheet-image: '../img/sprite.png?t1685543367348';
$spritesheet-sprites: ($arrow-down, $arrow-gray, $arrow-small, $arrow-white, $back-arrow, $bed, $bell, $close, $drop-arrow, $fb, $fee, $flamp-blue, $flamp, $fox-brown, $girl, $grill, $gym, $i-icon, $inst, $leaf-fill-green, $leaf-green, $leaf, $medal, $minus, $pdf, $pen-red, $pen, $plus, $poll, $price-header, $quote, $star-yellow, $star, $tableware, $tick, $vk, $whatsapp, $white-star, $white-price, $wifi, $word, $xls, $you, $zen, );
$spritesheet: (363px, 338px, '../img/sprite.png?t1685543367348', $spritesheet-sprites, );
$retina-spritesheet-width: 726px;
$retina-spritesheet-height: 676px;
$retina-spritesheet-image: '../img/sprite@2x.png?t1685543367348';
$retina-spritesheet-sprites: ($arrow-down-2x, $arrow-gray-2x, $arrow-small-2x, $arrow-white-2x, $back-arrow-2x, $bed-2x, $bell-2x, $close-2x, $drop-arrow-2x, $fb-2x, $fee-2x, $flamp-blue-2x, $flamp-2x, $fox-brown-2x, $girl-2x, $grill-2x, $gym-2x, $i-icon-2x, $inst-2x, $leaf-fill-green-2x, $leaf-green-2x, $leaf-2x, $medal-2x, $minus-2x, $pdf-2x, $pen-red-2x, $pen-2x, $plus-2x, $poll-2x, $price-header-2x, $quote-2x, $star-yellow-2x, $star-2x, $tableware-2x, $tick-2x, $vk-2x, $whatsapp-2x, $white-star-2x, $white-price-2x, $wifi-2x, $word-2x, $xls-2x, $you-2x, $zen-2x, );
$retina-spritesheet: (726px, 676px, '../img/sprite@2x.png?t1685543367348', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$arrow-down-group-name: 'arrow-down';
$arrow-down-group: ('arrow-down', $arrow-down, $arrow-down-2x, );
$arrow-gray-group-name: 'arrow-gray';
$arrow-gray-group: ('arrow-gray', $arrow-gray, $arrow-gray-2x, );
$arrow-small-group-name: 'arrow-small';
$arrow-small-group: ('arrow-small', $arrow-small, $arrow-small-2x, );
$arrow-white-group-name: 'arrow-white';
$arrow-white-group: ('arrow-white', $arrow-white, $arrow-white-2x, );
$back-arrow-group-name: 'back-arrow';
$back-arrow-group: ('back-arrow', $back-arrow, $back-arrow-2x, );
$bed-group-name: 'bed';
$bed-group: ('bed', $bed, $bed-2x, );
$bell-group-name: 'bell';
$bell-group: ('bell', $bell, $bell-2x, );
$close-group-name: 'close';
$close-group: ('close', $close, $close-2x, );
$drop-arrow-group-name: 'drop-arrow';
$drop-arrow-group: ('drop-arrow', $drop-arrow, $drop-arrow-2x, );
$fb-group-name: 'fb';
$fb-group: ('fb', $fb, $fb-2x, );
$fee-group-name: 'fee';
$fee-group: ('fee', $fee, $fee-2x, );
$flamp-blue-group-name: 'flamp-blue';
$flamp-blue-group: ('flamp-blue', $flamp-blue, $flamp-blue-2x, );
$flamp-group-name: 'flamp';
$flamp-group: ('flamp', $flamp, $flamp-2x, );
$fox-brown-group-name: 'fox-brown';
$fox-brown-group: ('fox-brown', $fox-brown, $fox-brown-2x, );
$girl-group-name: 'girl';
$girl-group: ('girl', $girl, $girl-2x, );
$grill-group-name: 'grill';
$grill-group: ('grill', $grill, $grill-2x, );
$gym-group-name: 'gym';
$gym-group: ('gym', $gym, $gym-2x, );
$i-icon-group-name: 'i-icon';
$i-icon-group: ('i-icon', $i-icon, $i-icon-2x, );
$inst-group-name: 'inst';
$inst-group: ('inst', $inst, $inst-2x, );
$leaf-fill-green-group-name: 'leaf-fill-green';
$leaf-fill-green-group: ('leaf-fill-green', $leaf-fill-green, $leaf-fill-green-2x, );
$leaf-green-group-name: 'leaf-green';
$leaf-green-group: ('leaf-green', $leaf-green, $leaf-green-2x, );
$leaf-group-name: 'leaf';
$leaf-group: ('leaf', $leaf, $leaf-2x, );
$medal-group-name: 'medal';
$medal-group: ('medal', $medal, $medal-2x, );
$minus-group-name: 'minus';
$minus-group: ('minus', $minus, $minus-2x, );
$pdf-group-name: 'pdf';
$pdf-group: ('pdf', $pdf, $pdf-2x, );
$pen-red-group-name: 'pen-red';
$pen-red-group: ('pen-red', $pen-red, $pen-red-2x, );
$pen-group-name: 'pen';
$pen-group: ('pen', $pen, $pen-2x, );
$plus-group-name: 'plus';
$plus-group: ('plus', $plus, $plus-2x, );
$poll-group-name: 'poll';
$poll-group: ('poll', $poll, $poll-2x, );
$price-header-group-name: 'price-header';
$price-header-group: ('price-header', $price-header, $price-header-2x, );
$quote-group-name: 'quote';
$quote-group: ('quote', $quote, $quote-2x, );
$star-yellow-group-name: 'star-yellow';
$star-yellow-group: ('star-yellow', $star-yellow, $star-yellow-2x, );
$star-group-name: 'star';
$star-group: ('star', $star, $star-2x, );
$tableware-group-name: 'tableware';
$tableware-group: ('tableware', $tableware, $tableware-2x, );
$tick-group-name: 'tick';
$tick-group: ('tick', $tick, $tick-2x, );
$vk-group-name: 'vk';
$vk-group: ('vk', $vk, $vk-2x, );
$whatsapp-group-name: 'whatsapp';
$whatsapp-group: ('whatsapp', $whatsapp, $whatsapp-2x, );
$white-star-group-name: 'white-star';
$white-star-group: ('white-star', $white-star, $white-star-2x, );
$white-price-group-name: 'whitePrice';
$white-price-group: ('whitePrice', $white-price, $white-price-2x, );
$wifi-group-name: 'wifi';
$wifi-group: ('wifi', $wifi, $wifi-2x, );
$word-group-name: 'word';
$word-group: ('word', $word, $word-2x, );
$xls-group-name: 'xls';
$xls-group: ('xls', $xls, $xls-2x, );
$you-group-name: 'you';
$you-group: ('you', $you, $you-2x, );
$zen-group-name: 'zen';
$zen-group: ('zen', $zen, $zen-2x, );
$retina-groups: ($arrow-down-group, $arrow-gray-group, $arrow-small-group, $arrow-white-group, $back-arrow-group, $bed-group, $bell-group, $close-group, $drop-arrow-group, $fb-group, $fee-group, $flamp-blue-group, $flamp-group, $fox-brown-group, $girl-group, $grill-group, $gym-group, $i-icon-group, $inst-group, $leaf-fill-green-group, $leaf-green-group, $leaf-group, $medal-group, $minus-group, $pdf-group, $pen-red-group, $pen-group, $plus-group, $poll-group, $price-header-group, $quote-group, $star-yellow-group, $star-group, $tableware-group, $tick-group, $vk-group, $whatsapp-group, $white-star-group, $white-price-group, $wifi-group, $word-group, $xls-group, $you-group, $zen-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
