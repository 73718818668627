.pagination {
	margin-top: 50px;
	text-align: center;
	clear: both;
}

.pagination-item {
	display: inline-block;
	text-decoration: none;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 20px / 16px * 1rem;
	line-height: 23px / 16px * 1rem;
	text-align: center;
	color: #BFBFBF;
	padding: 16px;
}

.pagination-item_current {
	font-weight: bold;
	color: #5e6280;
}