.mirCard {
	text-align: right;
	@media (max-width: 960px) {
		text-align: left;
	}
}

.mirCard {
	.mirCard-img {
		margin-top: -27px;
		transform: translateY(-30px) translateX(20px) rotate(-21.4deg);

		@media (max-width: 1200px) {
			max-width: 90% !important;
		}

		@media (max-width: 960px) {
			transform: none;

			margin-top: 40px;
			margin-bottom: -40px;
			margin-left: -4.6948356807512%;
		}

		@media (max-width: 500px) {
			margin-top: 20px;
			margin-bottom: -10px;
			max-width: none !important;
			width: 112.3630672926448% !important;
		}
	}
}
