@import "../misc/bootstrap";
@import "../sprite";

.popup {
	background: rgba(#BFBFBF, .9);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20002;

	visibility: hidden;
	opacity: 0;
	transition: visibility 0s .2s, opacity .2s linear;
}

.popup_visible {
	visibility: visible;
	opacity: 1;
	transition: opacity .2s linear;
}

.popup-box {
	width: 584px;
	max-width: 96%;
	background: #fff;
	position: relative;
	max-height: 100%;
	border-radius: 20px;
	
	@include media-max(md) {
		width: 100%;
		max-width: none;
		min-height: 100%;
		border-radius: 0;
	}
}

.popup-close {
	position: absolute;
	right:20px;
	top: 20px;
	z-index: 20;
	@include retina-sprite($close-group);

	@include media-max(md) {
		right: 10px;
	}
}
