@import "../misc/bootstrap";

@import "../sprite";
@import "../misc/bootstrap";


.beam{

}

.beam-content{
	margin-top: 55px;
	position: relative;
	margin-bottom: 100px;

	@include media-max(md){
		margin-top: 100px;
	}
}



.beam-scroll{
	overflow-x: scroll;
	overflow-y:hidden;
	display: none;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		height: 8px;
		background-color: #DDDDDD;
		border-radius: 9em;
		border: 1px solid #DDDDDD;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #BFBFBF;
		border-radius: 9em;
	}

	@media screen and (max-width: 1015px){
		display: block;
		margin-bottom: 10px;
	}
}

.beam-visible{
	width: 942px;
	height: 8px;



}


.beam-table{
	overflow: auto;
	padding-bottom: 10px;
	width: 100%;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		width: 0 !important;
	}

	table{
		@media screen and (max-width: 1015px){
			white-space: nowrap;
		}
	}

	td{
		padding: 10px 20px;
		text-align: left;
		font-size: 16px;

		span{
			display: block;
			margin-bottom: 5px;

			&:last-child{
				margin-bottom: 0;
			}
		}

		p{
				font-size: 16px;
		}
	}

	h2{
		margin-bottom: 25px;
	}
}






