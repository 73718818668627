.reviews {
}

.reviews-item {
	background: #ffffff;
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-bottom: 30px;
	padding: 40px 41px 20px;
}

.reviews-date {
	font-family: 'Open Sans', sans-serif;
	font-size: 13px / 16px * 1rem;
	line-height: 20px / 16px * 1rem;
	color: #7db260;
	text-transform: uppercase;
	margin-bottom: 9px;
}

.reviews-name {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 26px / 16px * 1rem;
	line-height: 30px / 16px * 1rem;
	color: #808080;
	margin-bottom: 30px;
}

.reviews-text {
	color: #2B2B2B;
	p {
		color: #2B2B2B !important;
	}

	.autoSpoiler-ctrl {
		&:after {
			display: none;
		}
		span {
			color: #e26360;
		}
	}
}
