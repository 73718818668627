@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
$spacers: (
		0: 0,
		1: ($spacer * .25),
		2: ($spacer * .5),
		3: $spacer,
		4: ($spacer * 1.5),
		5: ($spacer * 3),
		6: ($spacer * 4),
		7: ($spacer * 5),
		8: ($spacer * 6),
		9: ($spacer * 7.5),
		10: ($spacer * 8),
		11: ($spacer * 9),
		12: ($spacer * 10)
);

@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/grid";


.row.gutter-1 {
	margin-left: -5px;
	margin-right: -5px;
}

.row.gutter-1 > .col,
.row.gutter-1 > [class*="col-"] {
	padding-right: 5px;
	padding-left: 5px;
}

.row.gutter-2 {
	margin-left: -30px;
	margin-right: -30px;
}

.row.gutter-2 > .col,
.row.gutter-2 > [class*="col-"] {
	padding-right: 30px;
	padding-left: 30px;
}

.rounded-2 {
	border-radius: 20px;
}