.frame {
	position: relative;
	z-index: 1;
	margin: 4em auto;
	padding: 3.7em 0;

	&:before {
		content: '';
		position: absolute;
		left: -100%;
		width: 300%;
		top: 0;
		bottom: 0;
		z-index: -1;
	}

	@media (max-width: 600px) {
		padding: 2em 0;	
	}
}

body.coreEditorMode {
	.frame {
		&:before {
			width: 100%;
			left: 0;
		}
	}
}

.frame_green {
	color: #fff;
	&:before {
		background: #7db260 url('/img/leaves-green.jpg') -122px -317px;
	}
	* {
		color: #fff;
	}
}
.frame_plainGreen {
	color: #fff;
	&:before {
		background: linear-gradient(138.47deg, #7db260 14.89%, #568e37 76.51%);
	}
	* {
		color: #fff;
	}
}

.frame_blue {
	color: #fff;
	&:before {
		background: #6584A4 url('/img/leaves-blue.jpg') -122px -317px;
	}
	* {
		color: #fff;
	}
}

.frame_violet {
	color: #fff;
	&:before {
		background: #787C9B url('/img/leaves-violet.jpg') -122px -317px;
	}
	* {
		color: #fff;
	}
}

.frame_brown {
	color: #fff;
	&:before {
		background: #79695C url('/img/leaves-brown.jpg') -122px -317px;
	}
	* {
		color: #fff;
	}
}