
.price-line {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: 20px;
		left: 20px;
		right: 20px;
		height: 1px;
		background: #fff;
		opacity: .5;
	}
}

.price2-button{
	position: relative;
	margin-top: 150px;
	margin-bottom: 120px;
	text-align: center;

	a{
		text-decoration: none;
	}

	@include media-max(sm){
		margin: 80px 0;
	}
}

.priceBtn{
	background: #C3D6B1;
	border-radius: 96px;
	height: 80px;
	padding: 30px 50px;
	text-align: center;
	color: #2B2B2B;
	font-size: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

.tabs > .price_content{
	box-shadow: none !important;
	border-radius: 0 !important;
	border: none !important;
	margin-top: 15px !important;
}

.price_tabs .tabs-overflow label span{
	font-size: 18px;
	color: #272727 !important;
	font-weight: 400 !important;
	flex-wrap: wrap;
	background: #FFFFFF;
	border: 1px solid #C3D6B1;
	border-radius: 30px;
	height: auto !important;
	padding: 15px 25px !important;
	white-space: break-spaces !important;

	@include media-max(sm){
		font-size: 14px !important;
		padding: 10px 15px !important;
	}
}

.price_tabs .tabs-overflow > label {
	width: auto !important;
	padding-left: 0 !important;
	margin-right: 15px;
	height: auto !important;
	margin-bottom: 20px;

	@include media-max(xl){
		opacity: 1 !important;
		width: auto !important;
		padding: 0 !important;
	}

	@include media-max(sm){
		margin-bottom: 10px !important;
	}
}

.price_tabs .tabs-overflow {
	display: flex !important;
	align-items: center;
	flex-wrap: wrap;
}

.price_tabs .tabs-overflow > input[type="radio"]:checked + label span{
	background: #C3D6B1;
	border: 1px solid #C3D6B1;
	color: white !important;
}

.price_tabs .tabs-overflow > input[type="radio"]:checked + label {
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
}

.price_content .priceTable td, .priceTable th {
	padding: 10px 30px !important;
}

.price_tabs .tabs-overflow > input[type="radio"]:checked + label::before {
	display: block !important;
	background: transparent !important;
	height: auto !important;
	position: absolute !important;
	bottom: 0 !important;
	width: auto !important;
}

.price_content {
	width: 100%;
	overflow: auto;
}

.price2{
	margin-top: 150px;

	@include media-max(md){
		margin-top: 70px;
	}
}

.priceNew-button{
	margin-top: 60px;
}

