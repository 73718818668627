@import "../misc/bootstrap";

.priceTable {
	border-collapse: collapse;
	width: 100%;
	
	td, th {
		font-family: 'Open Sans', sans-serif;
		font-size: 16px / 16px * 1rem;
		line-height: 26px / 16px * 1rem;
		color: #6A6A6A;
		padding: 17px 30px;

		@include media-max(md) {
			padding: 8px 16px;
		}
		
		@include media-max(sm) {
			padding: 1px 2px;	
		}
	}
	
	td {
		background: #F2F2F2;
	}
	
	tr {
		&:nth-of-type(2n + 2) {
			td {
				background: #fff;
			}
		}
	}
	
	th {
		color: #fff;
		background: #4C6885;
	}
	
}