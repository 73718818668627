@import "../sprite";
@import "../mixins/reset";
@import "../misc/bootstrap";

.leavesList {
	@include reset;
	margin: 3.9em 0;
	
	li {
		@include reset;
		font-size: 19px / 16px * 1rem;
		font-family: 'Open Sans', sans-serif;
		line-height: 28px / 16px * 1rem;
		position: relative;
		margin-top: 1.45em;

		&:before {
			content: '';
			position: absolute;
			@include retina-sprite($leaf-fill-green-group);
			left: -53px;
			top: -1px;
		}
	}
	
	h1, h2, h3, h4, h5 {
		margin-top: 0;
	}
}

.leavesList_2cols {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	li {
		padding-left: 52px;
		width: 48%;

		&:before {
			left: 0;
		}

		@include media-max(sm) {
			width: 100%;
		}
	}
}

.leavesList_3cols {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&:after {
		content: '';
		height: 0;
		width: 31%;
		display: block;
		@include media-max(sm) {
			width: 100%;
		}
	}
	li {
		padding-left: 52px;
		width: 31%;
		@include media-max(sm) {
			width: 100%;
		}

		&:before {
			left: 0;
		}
	}
}

.leavesList_4cols {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&:after {
		content: '';
		height: 0;
		width: 23%;
		display: block;
		@include media-max(md) {
			width: 48%;
		}
		@include media-max(sm) {
			width: 100%;
		}
	}
	li {
		width: 23%;
		@include media-max(md) {
			width: 48%;
		}
		@include media-max(sm) {
			width: 100%;
		}

		&:before {
			position: static;
			display: block;
		}
	}
}

.leavesList_smallText {
	font-size: 16px / 16px * 1rem;
}