@import "../sprite";
@import "../misc/bootstrap";

.form1 {
	width: 92%;
	max-width: 1180px;
	margin: 0 auto;
	padding: 67px 100px 40px;
	background: #fff;
	border-radius: 20px;
	
	@include media-max(sm) {
		padding: 30px 20px 20px;	
	}
}

.input, .textarea, .select {
	font-family: 'Open Sans', sans-serif;
	background: #eeeded;
	height: 40px;
	border: none;
	border-radius: 6px;
	padding: 0 14px;
	font-size: 14px;
	color: #808080;
	margin-bottom: 29px;
	width: 100%;
	@include media-max(sm) {
		font-size: 16px;
	}
}

.textarea {
	padding: 12px 14px;
	height: 140px;
}

.select {
	appearance: none;
	option {
		color: #808080;
	}
}

.select-wrap {
	position: relative;
	margin-bottom: 29px;
	.select {
		margin-bottom: 0;
	}
	
	&:after {
		content: '';
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
		
		@include retina-sprite($arrow-down-group);
	}
}

.checkbox {
	position: relative;
	display: block;
	margin-bottom: 29px;
}

.checkbox-content {
	font-family: 'Open Sans', sans-serif;
	color: #2b2b2b;
	padding-left: 34px;
	display: block;
	user-select: none;
	
	a {
		color: #5e6280;
	}
	
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: -2px;
		width: 24px;
		height: 24px;
		background: #EEEDED;
		border-radius: 6px;
	}
}

.checkbox-input {
	display: none;
	&:checked + .checkbox-content {
		&:after {
			content: '';
			position: absolute;
			left: 4px;
			top: 5px;
			@include retina-sprite($tick-group);
		}
	}
}

.button {
	margin-bottom: 29px;
}
