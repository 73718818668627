@import "../sprite";
@import "../mixins/reset";
@import "../misc/bootstrap";

.list1 {
	@include reset;
	margin: 3.9em 0;

	li {
		@include reset;
		position: relative;
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 19px / 16px * 1rem;
		line-height: 28px / 16px * 1rem;
		margin-bottom: 28px;
		
		&:before {
			content: '';
			position: absolute;
			width: 26px;
			height: 2px;
			left: 0;
			top: 13px;
			background: #C3D6B1;
		}
	}

	h1, h2, h3, h4, h5 {
		margin-top: 0;
	}
}

.list1_2cols {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	li {
		padding-left: 41px;
		width: 48%;
		
		@include media-max(sm) {
			width: 100%;
		}
	}
}