@import "../sprite";

.kbox {
	background: rgba(#BFBFBF, .9);
	@media (max-width: 500px) {
		padding: 2%;
	}
}

.kbox-contents {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	img {
		opacity: 1 !important;
	}
}

.kbox-leftCtrl, .kbox-rightCtrl {
	&:before {
		content: '';
		width: 48px;
		height: 48px;
		border-radius: 100%;
		background: rgba(#fff, .9);
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}

	&:after {
		content: '';
		background: transparent;
		@include retina-sprite($arrow-small-group);
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
}

.kbox-leftCtrl {
	left: 0;

	&:after {
		transform: translateY(-50%) translateX(-50%) rotate(180deg);
	}
}

.kbox-rightCtrl {
	right: 0;
}


.kbox-closeCtrl {
	right: 20px;
	top: 20px;
	background: transparent;
	@include retina-sprite($close-group);

	&:before, &:after {
		display: none;
	}
}