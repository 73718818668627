@import "../misc/bootstrap";

.shadowFrame2 {
	position: relative;
	max-width: 1103px;
	background: #FFFFFF;
	box-shadow: 0 0 20px rgba(73, 73, 73, 0.2);
	border-radius: 20px;
	padding: 66px 220px;
	margin: 0 auto;
	* {
		color: #808080 !important;
	}
	
	:first-child {
		margin-top: 0;
	}
	:last-child {
		margin-bottom: 0;
	}

	@include media-max(md) {
		padding: 20px 40px;	
	}
}

.shadowFrame2-img {
	position: absolute;
	left: -76px;
	top: -20px;
	border-radius: 50%;
	overflow: hidden;
	
	img {
		display: block;
		width: 100% !important;
	}
	
	@include media-max(md) {
		border-radius: 4px;
		position: static;
		width: 230px;
		height: 230px;
		margin-top: -100px;
	}
}


body.coreEditorMode {
	.shadowFrame2-img {
		border-radius: 0;
	}
}