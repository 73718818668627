@import "../misc/bootstrap";

.footerMenu {
	display: block;
	list-style: none;
	padding: 0;
	margin: 0;
	justify-content: space-between;
	
	@include media-max(sm) {
		margin: 11px 0 0;
	}
	
	li {
		display: inline-block;
		list-style: none;
		padding: 0;
		margin: 0 0 16px;
		@include media-max(sm) {
			margin: 0 0 6px;
		}
		
		width: 47%;
		a {
			font-size: 18px / 16px * 1rem;
			font-family: 'Roboto Condensed', sans-serif;
			color: #2B2B2B;
			text-decoration: none;
		}
	}
}