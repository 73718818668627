body.coreSiteMode {
	.videoFrame {
		position: relative;
		padding-top: 56.25%;
		max-width: 980px;
		margin: {
			left: auto;
			right: auto;
		}

		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
}