@import "../misc/bootstrap";
@import "../sprite";

.proceduresList {
	
}

.proceduresList-item {
	overflow: hidden;
	a {
		text-decoration: none;
	}
	margin-top: 56px;
}

.proceduresList-img {
	float: left;
	margin-right: 20px;
	
	@include media-max(md) {
		float: none;
		margin-right: 0;
	}
}

.proceduresList-title {
	display: block;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 26px / 16px * 1rem;
	line-height: 30px / 16px * 1rem;
	color: #808080;
	padding-top: .5em;
}

.proceduresList-annotation {
	display: block;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px / 16px * 1rem;
	line-height: 26px / 16px * 1rem;
	color: #2B2B2B;
	* {
		font-size: 16px / 16px * 1rem;
	}
}

.proceduresList-title_fee {
	&:after {
		content: '';
		display: inline-block;
		@include retina-sprite($fee-group);
		vertical-align: middle;
	}
}
