@import "../sprite";
@import "../misc/bootstrap";

.tabs {
  font-size: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.tabs-overflow{
  @include media-max(xl){
    white-space: nowrap;
    overflow: auto;
    width: 100%;
  }

  &::-webkit-scrollbar{
    width: 0;
  }
}


.tabs .tabs-overflow >input[type="radio"] {
  display: none;
}

.tabs > .tabs-overflow ~ div {
  /* скрыть контент по умолчанию */
  display: none;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, .1);
  font-size: 16px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 10px;

}

.tab-width{
  max-width: 1050px;
  margin: 0 auto;
  padding: 70px 0;

  @include media-max(lg){
    padding: 30px 0;
    max-width: 100%;
  }

  @media screen and (max-width: 767px){
    padding: 0px 0 30px 0;
  }

}

.tabs .tabs-overflow {
  display: block;
  border-radius:0;
  box-shadow: none;
  padding: 0;
}


.tab-content[show=no] {
  display: none;
}
.tab-content[show=yes] {
  display: block !important;
}


.tabs .tabs-overflow >label {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  width: 25%;
  padding-left: 20px;
  font-size: 19px;
  line-height: 1.2;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  top: 1px;
  height: 80px;
  font-weight: bold;
  font-family: "Open Sans";
  color: #6A6A6A;


  @include media-max(xl){
    opacity: .5;
    width: auto;
    padding: 0 57px 0 20px;
  }

  @include media-max(lg){
    font-size: 15px;
  }

  @media screen and (max-width: 767px){
    padding: 0 20px 0 20px;
  }

  span{
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.tabs .tabs-overflow >label:not(:first-of-type) {
  border-left: none;
  border-right: none;
  border-top: none;
}

.tabs .tabs-overflow >input[type="radio"]:checked+label {
  background-color: #fff;
  box-shadow: 0 -5px 9px 0 rgba(0, 0, 0, .1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 1;

  @include media-max(xl){
    box-shadow: none;
  }

  &::before{
    content: "";

    @include media-max(xl){
      display: block;
      background: #5c6280;
      height: 5px;
      position: absolute;
      bottom: 0;
      width: 75%;
    }
  }
}

.tabs .tabs-overflow>input[type="radio"]:checked+label:nth-of-type(4) ~ div{
  border-top-right-radius: 0;
}

.tabs .tabs-overflow>input[type="radio"]:checked+label:nth-of-type(4) ~ div,
.tabs .tabs-overflow>input[type="radio"]:checked+label:nth-of-type(3) ~ div,
.tabs .tabs-overflow>input[type="radio"]:checked+label:nth-of-type(2) ~ div{
  border-top-left-radius: 10px;
}

.tab-item{
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media screen and (max-width: 767px){
    flex-direction: column-reverse;
  }
}

.tab-right{
  margin-left: 40px;
  min-width: 285px;

  @media screen and (max-width: 767px){
    margin-left: 0;
    max-width: 100%;
    min-width: auto;
  }

  img{
    @media screen and (max-width: 767px){
      width: 100% !important;
      object-fit: cover;
    }
  }
}

.tab-left{
  max-width: 450px;

  @media screen and (max-width: 767px){
    margin-top: 30px;
  }

  @include media-max(sm){
    max-width: 100%;
  }

  h4{
    font-size: 19px;
    font-family: "Open Sans";
    color: #6A6A6A;
    font-weight: bold;
    line-height: 28px;
    margin-top: 0;

    @include media-max(sm){
      font-size: 16px;
    }

    span{
      display: block;
    }
  }

  ul{
    padding-left: 0;
    list-style: none;

    li{
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #6A6A6A;
      margin-bottom: 0;
    }
  }

  p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #6A6A6A;
    margin-bottom: -10px;
  }
}

.tab-link{
  margin-top: 30px;

  a{
    color: #4C6885;
    font-size: 17px;
    display: block;
  }

  a + a{
    margin-top: 5px;
  }
}

.tab-button{
  margin-top: 40px;
  display: block;
  text-decoration: none;
}

.tab_button{
  padding: 0 45px;
}

.tab-link-flex{
  display: flex;
  justify-content: space-between;

  @include media-max(sm){
    flex-direction: column;
  }
}

.tab-link-flex-right{

  @include media-max(sm){
    margin-top: 5px;
  }
}

