@import "../misc/bootstrap";
@import "../sprite";

.quote {
	position: relative;

	&:before, &:after {
		content: '';
		position: absolute;
		@include retina-sprite($quote-group);
		
		@include media-max(md) {
			transform: scale(.5);
			transform-origin: right top;
		}
	}

	&:before {
		left: -58px;
		top: 6px;
	}

	&:after {
		right: -58px;
		bottom: 6px;
		transform: rotate(180deg) scale(.5);
		transform-origin: left bottom;
	}

}