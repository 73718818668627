@import "../sprite";
@import "bootstrap";

h1, .h1 {
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 42px / 16px * 1rem;
	line-height: 54px / 16px * 1rem;
	color: #6a6a6a;
	letter-spacing: .0135em;
	
	@media (max-width: 600px) {
		font-size: 42px / 16px * 1rem * .75;
		line-height: 54px / 16px * 1rem * .75;
	}
	
	a {
		color: inherit;
		text-decoration: none;
	}
}

.h1_regular {
	@extend .h1;
	font-weight: normal;
}

h2, .h2 {
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 34px / 16px * 1rem;
	line-height: 40px / 16px * 1rem;
	color: #6a6a6a;
	margin: 1em 0 .6em;
	letter-spacing: .01em;

	@media (max-width: 600px) {
		font-size: 34px / 16px * 1rem * .8;
		line-height: 40px / 16px * 1rem * .8;
	}

	a {
		color: inherit;
		text-decoration: none;
	}
}

h3, .h3 {
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 26px / 16px * 1rem;
	line-height: 30px / 16px * 1rem;
	letter-spacing: .01em;
	margin: 0.8em 0;

	@media (max-width: 600px) {
		font-size: 26px / 16px * 1rem * .8;
		line-height: 30px / 16px * 1rem * .8;
	}

	a {
		color: inherit;
		text-decoration: none;
	}
}

.h3_circle {
	position: relative;
	height: 90px;
	display: flex;
	align-items: center;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		z-index: -1;
		transform: translateY(-50%) translateX(-33%);
		width: 90px;
		height: 90px;
		border-radius: 50%;
		background: #d5e8c4;
	}
	
	@include media-max(sm) {
		height: 90px * .75;
		&:before {
			width: 90px * .75;
			height: 90px * .75;
		}
	}
	
}

.h3_redCircle {
	@extend .h3_circle;

	&:before {
		background: #f8c6c5;
	}
}

.h3_bigRedCircle {
	@extend .h3_circle;
	display: block;
	padding-top: 8px;

	&:before {
		background: #f8c6c5;
		width: 110px;
		height: 110px;
		left: -5px;
	}
}

.h3_bigRedCircle-sub {
	@extend .h3;
	display: block;
	padding: 0;
	margin: -2px 0 0 0;
	line-height: 1.1em;
}

.h3_blueCircle {
	@extend .h3_circle;

	&:before {
		background: #a7c0da;
	}
}


.greenCircle {
	@extend .h3_circle;
}

.blueCircle {
	@extend .h3_circle;

	&:before {
		background: #a7c0da;
	}
}

.yellowCircle {
	@extend .h3_circle;

	&:before {
		background: #f9e8bd;
	}
}

.redCircle {
	@extend .h3_circle;

	&:before {
		background: #f8c6c5;
	}
}

p {
	margin: .8em 0 .8em;
}

.t0 {
	font-size: 14px / 16px * 1rem;
}

p, .t1, li {
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 19px / 16px * 1rem;
	line-height: 28px / 16px * 1rem;
	
	@media (max-width: 600px) {
		font-size: 18px / 16px * 1rem;	
	}
}

li {
	margin-bottom: .4em;
}

hr {
	height: 1px;
	border: none;
	padding: 0;
	background: #bfbfbf;

	margin-top: 4rem;
	margin-bottom: 3rem;
}

.t2 {
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px / 16px * 1rem;
	line-height: 26px / 16px * 1rem;
}

.link {
	font-family: 'Roboto Condensed', sans-serif !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 17px / 16px * 1rem !important;
	line-height: 23px / 16px * 1rem !important;
	text-decoration-line: underline !important;
	color: #4c6885 !important;
}

.presentationText, .presentation-text {
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 26px / 16px * 1rem;
	line-height: 36px / 16px * 1rem;
	color: #2b2b2b;
	
	@include media-max(md) {
		font-size: 26px / 16px * 1rem * .9;
		line-height: 36px / 16px * 1rem * .9;	
	}
}

.miniText {
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px / 16px * 1rem;
	line-height: 20px / 16px * 1rem;
	color: #808080;
}

.lineHeight-0 {
	line-height: 0 !important;
}

.lightgray {
	color: #bfbfbf !important;
}

.gray {
	color: #808080 !important;
}

.darkgray {
	color: #6a6a6a !important;
}

.red {
	color: #e26360 !important;
}

.blue {
	color: #4c6885 !important;
}

.black {
	color: #2b2b2b !important;
}


.flampLink {
	display: inline-flex;
	align-items: center;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 17px / 16px * 1rem;
	line-height: 23px / 16px * 1rem;
	color: #4C6885;
	&:before {
		content:'';
		@include retina-sprite($flamp-blue-group);
		margin-right: 10px;
		transform: scale(.8);
		transform-origin: right center;
	}
}
.redPenLink {
	display: inline-flex;
	align-items: center;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 17px / 16px * 1rem;
	line-height: 23px / 16px * 1rem;
	color: #4C6885;
	&:before {
		content:'';
		@include retina-sprite($pen-red-group);
		margin-right: 10px;
		transform: scale(.8);
		transform-origin: right center;
	}
}
.flampLink_white {
	color: #fff;
	&:before {
		@include retina-sprite($flamp-group);
	}
}
.redPenLink_white {
	color: #fff;
	&:before {
		@include retina-sprite($pen-group);
	}
}

.robotoCondensed {
	font-family: 'Roboto Condensed', sans-serif;
}

.openSans {
	font-family: 'Open Sans', sans-serif;
}
