@import "../sprite";

.xlsLink {
	display: flex;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 19px / 16px * 1rem;
	line-height: 28px / 16px * 1rem;
	text-decoration-line: underline;
	color: #4C6885;
	
	&:before {
		content: '';
		display: block;
		margin-right: 20px;
		@include retina-sprite($xls-group);
		min-width: $xls-width;
		max-width: $xls-width;
	}
}