@import "../misc/bootstrap";

.numRound {
	width: 90px;
	height: 90px;
	background: #F8C6C5;
	border-radius: 50px;
	color: #fff;
	display: flex;
	margin-top: 1em;
	justify-content: center;
	align-items: center;

	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 42px / 16px * 1rem;

	@include media-max(md) {
		width: 90px * .9;
		height: 90px * .9;
	}

	@include media-max(sm) {
		width: 90px * .75;
		height: 90px * .75;
	}
}

.numRound_blue {
	background: #A7C0DA;
}