@import "../misc/bootstrap";

.imageToRight {
	position: relative;
	img {
		display: block;
		max-width: none;
		max-height: 80vh;
		@include media-max(md) {
			width: 100% !important;
			height: 400px !important;
			object-fit: cover;
		}
		@include media-max(sm) {
			height: 200px !important;
		}
	}
}