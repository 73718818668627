@import "../sprite";

body.coreSiteMode {
	.autoSpoiler {
		max-height: 80px;
		overflow: hidden;
		transition: .2s;
		
		p:last-child {
			margin-bottom: 0;
		}
	}

	.autoSpoiler_open {
		max-height: 1000px;
	}

	.autoSpoiler-ctrl {
		position: relative;
		display: inline-block;
		font-family: 'Open Sans', sans-serif;
		font-size: 16px;
		line-height: 26px;
		color: #6584a4;
		cursor: pointer;

		&:after {
			content: '';
			position: absolute;
			right: -15px;
			top: 50%;
			@include retina-sprite($drop-arrow-group);
		}

		span {

			&:nth-of-type(1) {

			}

			&:nth-of-type(2) {
				display: none;
			}
		}
	}

	.autoSpoiler-ctrl_open {
		&:after {
			transform: rotate(180deg);
		}
		span {
			&:nth-of-type(1) {
				display: none;
			}
			&:nth-of-type(2) {
				display: block;
			}
		}
	}

	.autoSpoiler-ctrl_hidden {
		display: none;	
	}
}


body.coreEditorMode {
	.autoSpoiler {
		border: 1px dashed red;
	}
}