@import "../misc/bootstrap";

.eventsList {
	margin-top: 80px;
}

.eventsList-item {
	display: flex;
	
	+.eventsList-item {
		margin-top: 56px;
	}

	@include media-breakpoint-down(sm) {
		display: block;
	}
}

.eventsList-img {
	img {
		max-width: none;
	}
	@include media-breakpoint-down(sm) {
		img {
			width: 100% !important;
		}
	}
}

.eventsList-content {
	flex-grow: 1;	
	padding-left: 20px;
	
	p {
		font-size: 16px / 16px * 1rem;
		line-height: 26px / 16px * 1rem;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 0;
	}
}

.eventsList-date {
	margin-bottom: 30px;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px / 16px * 1rem;
	line-height: 20px / 16px * 1rem;
	color: #7DB260;
	text-transform: uppercase;
	@include media-breakpoint-down(sm) {
		margin-top: 5px;
		margin-bottom: 10px;
	}
}

.eventsList-title {
	margin-bottom: -5px;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 26px / 16px * 1rem;
	line-height: 30px / 16px * 1rem;
	a {
		color: #808080;
		text-decoration: none;
	}
}

.eventsList-annotation {
	
}
