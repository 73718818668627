@import "../sprite";
@import "../misc/bootstrap";

.btn2 {
	position: relative;
	display: inline-block;
	color: #4C6885;	
	text-decoration: none;
	letter-spacing: .01em;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 1px;
		background: rgba(#4C6885, .2);
		transition: .12s;
	}
	
	&:hover {
		&:after {
			background: rgba(#4C6885, 1);
		}
	}
	

	@include media-max(md) {
		font-size: 16px; // Should be in pixels
	}
}

.btn2_leaf {
	margin-left: 31px;



	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		left: -31px;
		top: -1px;
		@include retina-sprite($leaf-group);
	}
}
.btn2_leaf-green {
	@extend .btn2_leaf;
	&:before {
		@include retina-sprite($leaf-green-group);
	}
}


.btn2_star {
	margin-left: 31px;
	@media (max-width: 988px){
		margin-left: 39px;
		margin-top: 5px;
	}

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		left: -31px;
		top: -1px;
		@include retina-sprite($star-group);
	}
}
.btn2_star-yellow {
	@extend .btn2_star;
	&:before {
		@include retina-sprite($star-yellow-group);
	}
}

.btn2_price {
	margin-left: 31px;

	@media (max-width: 988px){
		margin-left: 39px;
		margin-top: 5px;
	}
	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		left: -31px;
		top: -1px;
		@include retina-sprite($price-header-group);
	}
}
.btn2_price-red {
	@extend .btn2_price;
	&:before {
		@include retina-sprite($price-header-group);
	}

}