$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 960px,
		lg: 992px,
		xl: 1200px
);

@mixin media-max ($name) {
	@media (max-width: map-get($grid-breakpoints, $name)) {
		@content;
	}
}